import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

const Advanced = ({ store, isGroup = false }) => {
  const match = store.currentMatch;
  return (
    <div className="modal-match-height">
      <div className="option-group">
        <p className="match-title">
          Maximum Per {isGroup ? 'Member' : 'Employee'}
        </p>
        <p
          className="match-title"
          style={{ marginLeft: '1rem', marginTop: '1rem' }}
        >
          For Every $1 Donated Offer
        </p>
        <div className="flex-expand flex-column match-selection">
          <label className="radio limit-input" htmlFor="multiplier1">
            <input
              type="radio"
              value="1"
              id="multiplier1"
              name="multiplier1"
              checked={match.multiplier === 1}
              onChange={(_e) =>
                store.updateMatch({
                  multiplier: 1,
                  matchTotal: match.matchLimit * 5 * 1,
                })
              }
            />
            1x Match &nbsp;
            <p style={{ fontSize: '0.7rem' }}>
              ($1 for each $1 donated by your {isGroup ? 'Member' : 'Employee'}){' '}
            </p>
          </label>
          <label className="radio limit-input" htmlFor="multiplier2">
            <input
              type="radio"
              value="2"
              id="multiplier2"
              name="multiplier2"
              checked={match.multiplier === 2}
              onChange={(_e) =>
                store.updateMatch({
                  multiplier: 2,
                  matchTotal: match.matchLimit * 5 * 2,
                })
              }
            />
            2x Match &nbsp;
            <p style={{ fontSize: '0.7rem' }}>
              ($2 for each $1 donated by your {isGroup ? 'Member' : 'Employee'}){' '}
            </p>
          </label>
          <label className="radio limit-input" htmlFor="multiplier3">
            <input
              type="radio"
              value="3"
              id="multiplier3"
              name="multiplier3"
              checked={match.multiplier === 3}
              onChange={(_e) =>
                store.updateMatch({
                  multiplier: 3,
                  matchTotal: match.matchLimit * 5 * 3,
                })
              }
            />
            3x Match &nbsp;
            <p style={{ fontSize: '0.7rem' }}>
              ($3 for each $1 donated by your {isGroup ? 'Member' : 'Employee'}){' '}
            </p>
          </label>
        </div>
        {(match.matchCharities?.length === 0 || !match.matchCharities) && (
          <Fragment>
            <p className="match-title" style={{ marginTop: '1.5rem' }}>
              {isGroup ? 'Member' : 'Employee'} Gifting + Match
            </p>
            <div
              className="flex-expand flex-column match-selection"
              style={{ marginLeft: '1rem', marginTop: '0.5rem' }}
            >
              <label className="radio" htmlFor="afterGift">
                <input
                  type="radio"
                  value="afterGift"
                  id="afterGift"
                  name="afterGift"
                  checked={!match.restrictMatchAfterBalance}
                  onChange={(_e) =>
                    store.updateMatch({ restrictMatchAfterBalance: false })
                  }
                />
                Match {isGroup ? 'member' : 'employee'} donations that are from
                any source
              </label>
              <label className="radio" htmlFor="withGift">
                <input
                  type="radio"
                  value="withGift"
                  id="withGift"
                  name="withGift"
                  checked={match.restrictMatchAfterBalance}
                  onChange={(_e) =>
                    store.updateMatch({ restrictMatchAfterBalance: true })
                  }
                />
                Only match {isGroup ? 'member' : 'employee'} donations made from
                their personal funds
              </label>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default observer(Advanced);
