import { create } from 'zustand';

const initialState = {
  props: {},
};

const useDashboardStore = create((set) => ({
  ...initialState,
  setProps: (props) =>
    set({
      props: {
        ...props,
      },
    }),
}));

export default useDashboardStore;
