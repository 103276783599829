import gql from 'graphql-tag';

const getCauzesQuery = gql`
  query eventsForContext($user_context: UserContext) {
    eventsForContext(UserContext: $user_context) {
      id
      name
      purchaseId
      insertedAt
      description
      current
      startDate
      endDate
      eventType
      eventState
      previewUrl
      externalImageUrl
      goal
      image {
        sm
        md
        lg
        xl
        full
      }
      shareLink
      charities {
        ein
        type
        id
        name
        description
        street
        state
        city
        avatar {
          sm
          md
          lg
        }
      }
      sponsors {
        id
        name
        avatar {
          sm
          md
        }
        entityType
      }
      purchases {
        insertedAt
        id
        donationAmount
        user {
          avatar {
            sm
            md
          }
          username
          firstName
          lastName
          id
        }
      }
      matchSummaries {
        active
        matchAdmin {
          isSelf
        }
        matchLimit
        matchTotal
        multiplier
        matchType
        id
      }
    }
  }
`;

const createPlannedEventMutation = gql`
  mutation createPlannedEvent(
    $name: String!
    $description: String
    $charity_ids: [ID]
    $host_contexts: [UserContext]
    $image_id: ID
    $start_date: Date
    $end_date: Date
    $event_state: EventState!
    $match_total: Int
    $user_match_limit: Int
    $match_sponsor_contexts: [UserContext]
    $match_type: MatchType
    $preview_url: String
    $multiplier: Float
    $external_image_url: String
  ) {
    createPlannedEvent(
      name: $name
      description: $description
      charityIds: $charity_ids
      hostContexts: $host_contexts
      imageId: $image_id
      startDate: $start_date
      endDate: $end_date
      eventState: $event_state
      matchTotal: $match_total
      userMatchLimit: $user_match_limit
      matchSponsorContexts: $match_sponsor_contexts
      matchType: $match_type
      previewUrl: $preview_url
      multiplier: $multiplier
      externalImageUrl: $external_image_url
    ) {
      id
      externalImageUrl
      image {
        sm
        md
        lg
      }
    }
  }
`;

const updatePlannedEventMutation = gql`
  mutation updatePlannedEventMutation(
    $event_id: ID!
    $name: String
    $description: String
    $charity_ids: [ID]
    $image_id: ID
    $start_date: Date
    $end_date: Date
    $event_state: EventState!
    $preview_url: String
    $external_image_url: String
  ) {
    updatePlannedEvent(
      eventId: $event_id
      name: $name
      description: $description
      charityIds: $charity_ids
      imageId: $image_id
      startDate: $start_date
      endDate: $end_date
      eventState: $event_state
      previewUrl: $preview_url
      externalImageUrl: $external_image_url
    ) {
      id
    }
  }
`;

const getUserCauzesQuery = gql`
  query getUserCauzesQuery {
    userCauzes {
      id
      name
      description
      current
      startDate
      endDate
      eventType
      goal
      purchaserCount
      currentEntityIsHost
      currentEntityIsFollowing
      eventState
      currentEntityHasPinned
      externalImageUrl
      previewUrl
      joinedPurchaser {
        user {
          id
          isActive
          unclaimed
        }
      }
      image {
        sm
        md
        lg
        xl
      }
      shareLink
      charities {
        zip
        ein
        id
        name
        state
        city
        street
        description
        avatarUrls {
          sm
          md
          lg
        }
      }
      matches {
        id
        active
        matchAdmin {
          id
          isCurrentEntity
          currentEntityIsFollowing
          type
          name
          avatarUrls {
            sm
            md
          }
        }
        company {
          name
          subtitle
          avatarUrls {
            sm
            md
          }
        }
        description
        startDate
        endDate
        matchLimit
        matchTotal
        totalRaised
        totalRemaining
        currentEntityRemaining
        multiplier
        matchType
        matchSponsors {
          matchTotal
          remaining
          name
          id
          currentEntityIsFollowing
          isCurrentEntity
          subtitle
          type
          avatarUrls {
            sm
            md
          }
        }
      }
      hosts {
        id
        name
        avatarUrls {
          sm
          md
        }
        type
      }
    }
  }
`;

export {
  getCauzesQuery,
  getUserCauzesQuery,
  createPlannedEventMutation,
  updatePlannedEventMutation,
};
