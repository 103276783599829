import React, { useEffect } from 'react';

import CauzesView from './CauzesView';
import withUserContext from 'behaviors/withUserContext';
import withAuthRequired from 'behaviors/withAuthRequired';
import useCauzes from 'hooks/useCauzes';
import useDashboardStore from 'stores/DashboardStore';

const CompanyCauzesView = ({ companyId }) => {
  const { setProps } = useDashboardStore();
  useCauzes({ userContext: { companyId } });

  useEffect(() => {
    setProps({
      size: 'screen',
      bodyClassName: 'bg-lightgray-e0e',
    });
  }, []);

  return <CauzesView userContext={{ companyId }} />;
};

export default withAuthRequired(withUserContext(CompanyCauzesView), {
  entityType: 'COMPANY',
});
