import React from 'react';
import { TableCurrency } from '../UserTable/components';
import { Actor, ActorSort } from '../Actor/Actor';

import ColumnHeader from 'components/ColumnHeader/ColumnHeader';

import matchSorter from 'match-sorter';

const usersColumns = ({ balanceTotal, followingCount, followersCount }) => [
  {
    id: 'user',
    Header: 'User Name',
    accessor: (row) => ({
      name: row.name,
      entity: row,
    }),
    Cell: Actor,
    className: 'col-left col-wrap',
    headerClassName: 'col-left',
    minWidth: 120,
    sortMethod: ActorSort,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [
          '_original.firstName',
          '_original.lastName',
          '_original.username',
        ],
      }),
    filterAll: true,
  },
  {
    id: 'id',
    Header: 'Id',
    accessor: 'id',
    className: 'is-hidden-mobile',
    headerClassName: 'is-hidden-mobile',
    minWidth: 35,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'username',
    Header: 'Username',
    accessor: 'username',
    className: 'is-hidden-mobile col-left col-wrap',
    headerClassName: 'is-hidden-mobile col-left',
    minWidth: 70,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'balance',
    Header: () => <ColumnHeader title="Balance" amount={balanceTotal} />,
    accessor: (row) => row?.balance,
    minWidth: 50,
    Cell: TableCurrency,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'email',
    Header: 'Email Address',
    accessor: 'email',
    minWidth: 120,
    className: 'col-left col-wrap is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'col-left is-hidden-mobile is-hidden-tablet-only',
  },
  {
    id: 'zip',
    Header: 'Zip Code',
    accessor: 'zip',
    minWidth: 60,
    className: 'col-left is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'col-left is-hidden-mobile is-hidden-tablet-only',
  },
  {
    id: 'followers',
    Header: () => <ColumnHeader title="Followers" subtitle={followersCount} />,
    accessor: 'followerCount',
    minWidth: 60,
    className: 'col-left is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'col-left is-hidden-mobile is-hidden-tablet-only',
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'following',
    Header: () => <ColumnHeader title="Following" subtitle={followingCount} />,
    accessor: 'followingCount',
    minWidth: 60,
    className: 'col-left is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'col-left is-hidden-mobile is-hidden-tablet-only',
    style: {
      textAlign: 'center',
    },
  },
];

export default usersColumns;
