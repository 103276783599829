import React from 'react';
import { observer, inject } from 'mobx-react';
import dompurify from 'dompurify';

import Avatar from '../Avatar/Avatar';
import { shorten } from '../../util/stringUtils';
import CauzeButton from '../CauzeButton/CauzeButton';
import FollowButton from '../FollowButton/FollowButton';
import { normalizeContext } from '../../util/contextUtils';

const SearchItem = ({
  charity,
  onDonate,
  onLearnMore,
  onFollowCharity,
  userContext,
  searchStore,
  uiStore,
  isAuthenticated,
}) => {
  const followerCount = charity?.followerCount;
  const featuredFollowerCount = charity?.featuredFollowers?.length || 0;
  const nonFeaturedFollowerCount = followerCount - 4;
  const sanitizer = dompurify.sanitize;

  const followerAvatars = (charity) => {
    let featuredFollowerAvatars = [];
    if (followerCount >= 4) {
      charity?.featuredFollowers?.forEach((follower, index) => {
        featuredFollowerAvatars.push(
          <Avatar
            className="avatar-follower has-avatar"
            md
            src={follower.avatarUrl || follower.avatarUrls?.sm}
            key={index}
            index={index}
          />,
        );
      });
      for (let i = 0; i < 4 - featuredFollowerCount; i++) {
        featuredFollowerAvatars.push(
          <Avatar
            className="avatar-follower"
            md
            type="user"
            avatar=""
            key={`non-avatar-complete ${i}`}
            index={i}
          />,
        );
      }
    } else if (followerCount < 4 && followerCount > 0) {
      charity?.featuredFollowers?.forEach((follower, index) => {
        featuredFollowerAvatars.push(
          <Avatar
            className="avatar-follower has-avatar"
            md
            src={follower.avatarUrl || follower.avatarUrls?.sm}
            key={index}
            index={index}
          />,
        );
      });
      for (let i = 0; i < followerCount - featuredFollowerCount; i++) {
        featuredFollowerAvatars.push(
          <Avatar
            className="avatar-follower"
            md
            type="user"
            key={`non-avatar-incomplete ${i}`}
            index={i}
          />,
        );
      }
    } else {
      featuredFollowerAvatars.push('Be The First To Give');
    }

    featuredFollowerAvatars = featuredFollowerAvatars.slice(0, 4);

    return featuredFollowerAvatars;
  };

  const showFollowersModal = () => {
    searchStore.getFollowersForResult({
      userContext: normalizeContext(userContext),
      targetContext: { charityId: charity.id },
    });

    uiStore.openModal('ENTITY_LIST', {
      title: `${followerCount} Follower${followerCount === 1 ? '' : 's'}`,
      showFollow: true,
      buttonLabel: 'Follow',
      isSelf: false,
      store: searchStore,
      userContext,
      loadingKey: 'loadingFollowers',
      entitiesKey: 'charityFollowers',
      isAuthenticated: isAuthenticated,
    });
  };

  return (
    <div className="search-item flex-column">
      <div className="search-item-link">
        <div className="flex-row charity-details">
          <Avatar
            className="avatar-column"
            md
            avatar={charity.avatar}
            onClick={onLearnMore}
          />
          <div className="flex-column details-column">
            <div className="details-header">
              <a onClick={onLearnMore} className="text-bold charity-name">
                {charity.name}
              </a>
              <p className="location">
                NONPROFIT | {charity.city}, {charity.state}
              </p>
            </div>
            {charity.description && (
              <div className="description">
                <p
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(shorten(charity.description, 250), {
                      ADD_ATTR: ['target'],
                    }),
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex-column search-item-followers">
          {followerCount > 0 && (
            <div className={`follower-info`}>
              <div className="follower-count-wrapper">
                {`${followerCount} Followers`}
              </div>
              {followerCount > 0 && (
                <div
                  onClick={showFollowersModal}
                  className="follower-avatar-wrapper flex-row flex-align-center"
                >
                  {followerAvatars(charity).map((avatar) => avatar)}
                  <span className="non-featured-follower-count">
                    {nonFeaturedFollowerCount > 0 &&
                      `+ ${nonFeaturedFollowerCount} more`}
                  </span>
                </div>
              )}
            </div>
          )}
          <div
            className={`search-item-button flex-row ${
              followerCount === 0 && 'no-followers'
            }`}
          >
            <FollowButton
              onFollow={() =>
                onFollowCharity({
                  follow: !charity.isSelfFollowing,
                  actorContext: normalizeContext(userContext),
                  charityId: charity.id,
                })
              }
              following={charity.isSelfFollowing}
              style={{ marginTop: '0px', marginRight: '2rem' }}
            />
            <CauzeButton large children="Give" onClick={onDonate} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject('searchStore', 'uiStore')(observer(SearchItem));
