import React from 'react';

import Button from '../../Button/Button';
import { observer, Observer } from 'mobx-react';

import classnames from 'classnames';

class MultiStepModal extends React.Component {
  constructor() {
    super();

    this.state = {
      page: 0,
      error: false,
      errorText: '',
      confirmButtonOverride: '',
      isAdvanced: false,
    };

    this.validator = () => '';
    this.skip = false;
    this.backSkip = false;
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.escapeHandler);
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.escapeHandler);
  }

  escapeHandler = (event) => {
    if (event.key === 'Escape') {
      this.props.setIsOpen(false);
    }
  };

  setConfirmButton = (text) => {
    this.setState({ confirmButtonOverride: text });
  };

  onClose = () => {
    this.props.setIsOpen(false);
    this.props.onCancel && this.props.onCancel();
  };

  onBack = () => {
    let page = this.state.page - 1;
    if (this.backSkip) {
      page -= 1;
      this.backSkip = false;
      this.skip = true;
    }

    this.setState({
      page,
      error: false,
      errorText: '',
    });
  };

  onNext = () => {
    const error = this.validator();
    if (error === '') {
      let page = this.state.page + 1;
      if (this.skip) {
        page += 1;
        this.backSkip = true;
        this.skip = false;
      }
      this.setState({
        page,
        error: false,
        errorText: '',
      });
    } else {
      this.setState({ error: true, errorText: error });
    }
  };

  onConfirm = () => {
    this.props.onConfirm && this.props.onConfirm();
    this.setState({ open: false });
  };

  setValidator = (validator) => {
    this.validator = validator;
  };

  setSkip = (skip) => {
    this.skip = skip;
  };

  render() {
    const childWithValidator = React.cloneElement(
      this.state.isAdvanced
        ? this.props.advancedView
        : this.props.children[this.state.page],
      {
        setValidator: this.setValidator,
        setSkip: this.setSkip,
        setConfirmButton: this.setConfirmButton,
      },
    );

    const isLastPage = this.state.page === this.props.children.length - 1;
    const title = this.state.isAdvanced
      ? 'Advanced'
      : this.props.titles && this.props.titles[this.state.page];

    return (
      <div
        className={classnames('modal', {
          'is-active': this.props.isOpen,
        })}
      >
        <div className="modal-background" />
        <div className="modal-card create-match-modal has-carousel ">
          <header className="modal-card-head flex-column">
            <div className="flex-row flex-center"></div>
            <div className="flex-row flex-align-center">
              <p className="modal-card-title modal-carousel-subheader">
                {title}
              </p>
              <button
                className="delete"
                aria-label="close"
                onClick={this.onClose}
              ></button>
            </div>
          </header>
          <section className="modal-card-body">
            {this.state.error && (
              <div className="notification">{this.state.errorText}</div>
            )}
            <Observer>{() => childWithValidator}</Observer>
          </section>
          {this.state.isAdvanced ? (
            <footer className="modal-card-foot">
              {!isLastPage && (
                <button
                  className="button"
                  onClick={() => {
                    this.setState({ isAdvanced: false });
                  }}
                >
                  Continue
                </button>
              )}
            </footer>
          ) : (
            <footer
              className={classnames('modal-card-foot', {
                'flex-space-between': !isLastPage,
                'flex-justify-end': isLastPage,
              })}
            >
              {!isLastPage && (
                <button
                  className="button"
                  onClick={() => {
                    this.setState({ isAdvanced: true });
                  }}
                >
                  Advanced
                </button>
              )}
              <div>
                {this.state.page === 0 ? (
                  <button className="button" onClick={this.onClose}>
                    Cancel
                  </button>
                ) : (
                  <button className="button" onClick={this.onBack}>
                    Back
                  </button>
                )}
                {this.state.page < this.props.titles.length - 1 ? (
                  <Button className="button is-primary" onClick={this.onNext}>
                    Next
                  </Button>
                ) : (
                  <Button
                    isSubmitting={this.props.isConfirming}
                    className="button is-primary"
                    onClick={this.onConfirm}
                  >
                    {this.state.confirmButtonOverride ||
                      this.props.confirmButtonText}
                  </Button>
                )}
              </div>
            </footer>
          )}
        </div>
      </div>
    );
  }
}

MultiStepModal.defaultProps = {
  confirmButtonText: 'Confirm',
  isConfirming: false,
};

export default observer(MultiStepModal);
