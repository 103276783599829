import { useMemo, useState } from 'react';
import { Link, useLocation } from '@reach/router';
import cx from 'classnames';

import { ReactComponent as DownChevronIcon } from 'assets/images/icons/chevron/down-chevron-2.svg';
import { capitalize } from 'lodash';

const SidebarItem = ({
  icon,
  label,
  href,
  nested,
  items,
  onClick,
  setExpanded,
  ...props
}) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);

  const Component = useMemo(() => {
    if (href?.charAt(0) === '/') {
      return Link;
    } else if (href) {
      return 'a';
    }

    return 'div';
  }, [href]);

  const active = useMemo(() => {
    if (items) {
      return items.find(
        (item) => `${location.pathname}${location.search}` === item.href,
      );
    }

    return `${location.pathname}${location.search}` === href;
  }, [items, location]);

  return (
    <>
      <Component
        {...props}
        to={href}
        href={href}
        onClick={(e) => {
          if (!items) {
            setExpanded?.(false);
          }

          if (onClick) {
            onClick?.(e);
            return;
          }

          if (items) {
            setCollapsed(!collapsed);
          }
        }}
        className={cx(
          '!text-gray-1b2 pl-6 lg:pl-16 pr-6 flex gap-x-4 items-center hover:bg-lightgray-e8e active:bg-lightgray-b2b cursor-pointer',
          {
            'h-10': nested,
            'h-14 py-3 ': !nested,
          },
        )}
      >
        {icon && (
          <div className="h-8 w-8 flex justify-center items-center">{icon}</div>
        )}
        <div
          title={capitalize(label)}
          className={cx(
            'capitalize leading-none whitespace-nowrap overflow-hidden text-ellipsis',
            {
              'font-agenda-bold': active,
              'font-agenda': !active,
              'pl-12 text-[17px] my-auto pt-1': nested,
              'text-xl mt-2': !nested,
            },
          )}
        >
          {label}
        </div>
        {items && (
          <div className="flex-1 flex justify-end">
            <div
              className={cx('h-8 flex items-center', {
                'rotate-180': collapsed,
              })}
            >
              <DownChevronIcon />
            </div>
          </div>
        )}
      </Component>
      {items &&
        items.map((item) => (
          <div
            key={item.href}
            className={cx('h-auto overflow-hidden', {
              '!h-0': collapsed,
            })}
          >
            <SidebarItem nested {...item} />
          </div>
        ))}
    </>
  );
};

export default SidebarItem;
