import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';

import withUserContext from 'behaviors/withUserContext';
import withAuthRequired from 'behaviors/withAuthRequired';

import CharitySearch from 'components/CharitySearch/CharitySearch';
import useDashboardStore from 'stores/DashboardStore';

const CharitySearchView = () => {
  const { setProps } = useDashboardStore();

  useEffect(() => {
    setProps({
      title: 'Charity Search',
      size: 'full',
    });
  }, []);

  return (
    <div className="company-admin employees-view flex-column">
      <section>
        <div className="mt-8">
          <CharitySearch />
        </div>
      </section>
    </div>
  );
};

export default withAuthRequired(
  withUserContext(inject('profileStore')(observer(CharitySearchView))),
  { entityType: 'CHARITY' },
);
