import { TableCurrency } from '../UserTable/components';
import { Actor, ActorSort } from '../Actor/Actor';

const charitiesColumns = (filterFunction) => [
  {
    id: 'charity',
    Header: 'Nonprofit',
    accessor: (row) => ({ name: row.name, entity: row }),
    Cell: Actor,
    className: 'col-left col-wrap',
    headerClassName: 'col-left',
    minWidth: 150,
    sortMethod: ActorSort,
    filterAll: true,
    filterMethod: filterFunction,
  },
  {
    id: 'id',
    Header: 'Id',
    accessor: 'id',
    className: 'is-hidden-mobile',
    headerClassName: 'is-hidden-mobile',
    minWidth: 35,
    style: {
      textAlign: 'right',
    },
  },
  {
    id: 'balance',
    Header: 'Balance',
    accessor: 'balance',
    minWidth: 50,
    Cell: TableCurrency,
    style: {
      textAlign: 'right',
    },
  },
  // {
  //   id: 'shareLink',
  //   Header: 'Share Link',
  //   accessor: 'shareLink',
  //   minWidth: 100,
  //   className: 'col-left col-wrap',
  //   headerClassName: 'col-left',
  // },
  {
    id: 'address',
    Header: 'Address',
    accessor: 'address',
    minWidth: 150,
    className: 'col-left col-wrap is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'col-left is-hidden-mobile is-hidden-tablet-only',
  },
  {
    id: 'ein',
    Header: 'EIN',
    accessor: 'ein',
    minWidth: 80,
    className: 'col-left is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'col-left is-hidden-mobile is-hidden-tablet-only',
  },
];

export default charitiesColumns;
