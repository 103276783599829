import gql from 'graphql-tag';

const notificationsQuery = gql`
  query notifications(
    $page: Int!
    $page_size: Int!
    $user_context: UserContext
  ) {
    notificationFeed(
      page: $page
      pageSize: $page_size
      userContext: $user_context
    ) {
      items {
        actors {
          avatar {
            sm
          }
          id
          name
          entityType
        }
        id
        date
        read
        subjects {
          id
          name
          subjectType
        }
        verbMeta {
          amount
          purchaseId
          recipients
        }
        type
      }
      pageNumber
      pageSize
      total
      totalPages
      unreadCount
    }
  }
`;

const markNotificationReadMutation = gql`
  mutation markNotificationRead($id: ID!) {
    markNotificationRead(id: $id)
  }
`;

const markAllNotificationsReadMutation = gql`
  mutation markAllNotificationsRead($user_context: UserContext) {
    markAllNotificationsRead(userContext: $user_context)
  }
`;

export {
  notificationsQuery,
  markNotificationReadMutation,
  markAllNotificationsReadMutation,
};
