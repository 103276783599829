import React, { useState, useMemo, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';

import withUserContext from 'behaviors/withUserContext';
import Navbar from 'components/page/Navbar/Navbar';
import { getThnksCompanyId } from 'util/cobrandUtils';
import Sidebar from 'components/Sidebar/Sidebar';
import FooterNew from '../Footer/FooterNew';
import useMobile from 'hooks/useMobile';
import useDashboardStore from 'stores/DashboardStore';

const DashboardLayout = ({
  children,
  uiStore,
  activeEntity,
  authStore,
  profileStore,
  eventStore,
  bodyRef,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [paddingTop, setPaddingTop] = useState(0);
  const { isTablet } = useMobile();
  const dashboardStore = useDashboardStore();
  const {
    asideChildren,
    title,
    noSidebar,
    bodyClassName,
    navbarProps,
    size,
    noPadding,
    noPaddingLedger,
    scroll,
  } = dashboardStore.props;

  let cobrandCompany = null;
  // Only thnks is a cobrand company for now
  if (uiStore.savedUiState.userToken?.company?.id === getThnksCompanyId()) {
    cobrandCompany = uiStore.savedUiState.userToken.company;
  }

  const calculatedSize = useMemo(() => {
    if (asideChildren) {
      return 'full';
    }

    return size ?? 'screen';
  }, [size]);

  useEffect(() => {
    bodyRef.current.scrollTo({ top: scroll });
  }, [scroll]);

  return (
    <div className="w-screen h-screen overflow-hidden flex flex-col">
      <Navbar
        redirectAfterContextSelect={false}
        cobrand={cobrandCompany}
        expanded={expanded}
        setExpanded={setExpanded}
        {...navbarProps}
      />
      <div
        id="dashboard-layout-body"
        ref={bodyRef}
        className="dashboard-layout-body overflow-y-auto overflow-x-hidden"
        onScroll={(e) => {
          if (isTablet) return;

          setPaddingTop(
            Math.min(
              e.target.scrollTop,
              e.target.scrollHeight - e.target.clientHeight - 392, // footer height
            ),
          );
        }}
      >
        <div
          className={cx('relative overflow-y-hidden bg-white', {
            'dashboard-grid': !noSidebar,
            full: calculatedSize === 'full',
            screen: calculatedSize === 'screen',
            half: calculatedSize === 'half',
          })}
        >
          {!noSidebar && (
            <div
              style={{ paddingTop: isTablet ? 0 : paddingTop }}
              className={cx(
                'h-full lg:min-w-[320px] lg:max-w-[320px] overflow-hidden',
              )}
            >
              <Sidebar
                activeEntity={activeEntity}
                expanded={expanded}
                setExpanded={setExpanded}
                authStore={authStore}
                profileStore={profileStore}
                eventStore={eventStore}
              />
            </div>
          )}
          <div
            className={cx(
              'dashboard-content min-h-[100vh] h-full grid w-full gap-x-8 ',
              {
                'grid-cols-5 xl:grid-cols-8': asideChildren,
                'grid-cols-5': !asideChildren,
                'px-4 lg:px-12 pb-4 lg:pb-12': !noPadding,
                '!px-0': noPaddingLedger,
                [bodyClassName]: bodyClassName,
              },
            )}
          >
            <div className="col-span-5 w-full content-container">
              <div
                className={cx('w-full flex-column flex-align-center', {
                  'py-4 lg:py-12': !noPadding,
                })}
              >
                {title && (
                  <h1
                    className={cx(
                      'text-left w-full text-2xl font-agenda-bold leading-none mb-5',
                      {
                        'px-4 pt-4 lg:px-0 lg:pt-0': noPadding,
                      },
                    )}
                  >
                    {title}
                  </h1>
                )}

                <div className="w-full">
                  <div>{children}</div>
                </div>
              </div>
            </div>
            <div className="col-span-5 xl:col-span-3">{asideChildren}</div>
          </div>
        </div>
        <div className="overflow-hidden">
          <FooterNew />
        </div>
      </div>
    </div>
  );
};

export default withUserContext(
  inject(
    'donationLandingStore',
    'searchStore',
    'uiStore',
    'authStore',
    'profileStore',
    'eventStore',
  )(observer(DashboardLayout)),
);
