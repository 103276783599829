import React from 'react';
import Button from 'components/Button/Button';
import useKeyboardEvent from '../../../hooks/useKeyboardEvent.js';

import classnames from 'classnames';

const ErrorModal = ({
  onToggleClose,
  isOpen,
  errorText,
  buttonText = 'Dismiss',
}) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  return (
    <div
      className={classnames('modal', {
        'is-active': isOpen,
      })}
    >
      <div className="modal-background"></div>
      <section className="modal-card error-modal">
        <section className="modal-card-body">
          <p className="error-message">{errorText}</p>
          <div className="flex-row flex-justify-end">
            <Button
              white
              className="error-confirm-button"
              onClick={onToggleClose}
            >
              {buttonText}
            </Button>
          </div>
        </section>
      </section>
    </div>
  );
};

export default ErrorModal;
