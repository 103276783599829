import { create } from 'zustand';
import { client as apolloClient } from 'util/apolloClient';
import { isEqual } from 'lodash';

import {
  notificationsQuery,
  markNotificationReadMutation,
  markAllNotificationsReadMutation,
} from 'graphql/feedNotifications';
import authStore from './AuthStore';

const initialState = {
  notifications: {},
  unreadCount: {},
  loading: {},
  lastActiveEntity: {},
};

const useFeedNotificationStore = create((set, get) => ({
  ...initialState,
  getUnreadCount: () => {
    const state = get();
    const unreadCountSum = Object.values(state.unreadCount).reduce(
      (a, b) => a + b,
      0,
    );
    return unreadCountSum;
  },
  getNotifications: async (activeEntity, skipCache = false) => {
    const state = get();
    const notifKey = `${activeEntity.entityType}-${activeEntity.id}`;

    if (!isEqual(state.lastActiveEntity, activeEntity)) {
      set({
        unreadCount: {
          ...state.unreadCount,
        },
        lastActiveEntity: activeEntity,
      });
    }

    if (state.notifications[notifKey] && !skipCache) {
      return state.notifications[notifKey];
    }

    let userContext = { ...activeEntity?.userContext };

    if (!activeEntity?.userContext) {
      let key = 'userId';

      if (activeEntity?.entityType === 'COMPANY') {
        key = 'companyId';
      } else if (activeEntity?.entityType === 'CHARITY') {
        key = 'charityId';
      }

      userContext = {
        [key]: activeEntity.id,
      };
    }

    if (userContext.influencerId) {
      userContext.userId = userContext.influencerId;
      delete userContext.influencerId;
    }

    if (!authStore.isAuthenticated) {
      return;
    }

    set({
      loading: {
        ...state.loading,
        [notifKey]: true,
      },
    });

    const variables = {
      page: 1,
      pageSize: 50,
      userContext,
    };

    const options = {
      query: notificationsQuery,
      variables,
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    const queryData = await apolloClient.query(options);

    const notifications = {
      ...state.notifications,
      [notifKey]: queryData.data.notificationFeed?.items || [],
    };

    const unreadCount = {
      ...state.unreadCount,
      [notifKey]: queryData.data.notificationFeed?.unreadCount || 0,
    };

    set({
      notifications,
      unreadCount,
      loading: {
        ...state.loading,
        [notifKey]: false,
      },
    });

    return notifications[notifKey];
  },
  getAllNotifications: async (entities) => {
    const state = get();

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      await state.getNotifications(entity);
    }

    return get().notifications;
  },
  markAllRead: (activeEntity) => {
    const state = get();
    const userContext = activeEntity.userContext;
    const notifKey = `${activeEntity.entityType}-${activeEntity.id}`;

    const readNotifications = state.notifications[notifKey].map(
      (notification) => ({
        ...notification,
        read: true,
      }),
    );

    set({
      notifications: {
        ...state.notifications,
        [notifKey]: readNotifications,
      },
      unreadCount: {
        ...state.unreadCount,
        [notifKey]: 0,
      },
    });

    const options = {
      mutation: markAllNotificationsReadMutation,
      variables: { userContext },
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options);
  },
  markNotificationRead: (id, index) => {
    const state = get();
    const activeEntity = state.lastActiveEntity;
    const notifKey = `${activeEntity.entityType}-${activeEntity.id}`;
    const notifications = state.notifications[notifKey];
    let unreadCount = state.unreadCount[notifKey];

    if (notifications[index].read) {
      return;
    }
    notifications[index].read = true;
    if (unreadCount > 0) unreadCount--;

    const options = {
      mutation: markNotificationReadMutation,
      variables: { id },
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options);

    set({
      notifications: {
        ...state.notifications,
        [notifKey]: notifications,
      },
      unreadCount: {
        ...state.unreadCount,
        [notifKey]: unreadCount,
      },
    });
  },
}));

export default useFeedNotificationStore;
