import useDashboardStore from 'stores/DashboardStore';
import AuthViewSignup from './AuthView/AuthViewSignup';
import { useEffect } from 'react';

const SignupView = () => {
  const { setProps } = useDashboardStore();

  useEffect(() => {
    setProps({
      noSidebar: true,
      noPadding: true,
      bodyClassName: 'bg-lightgray-e0e',
      navbarProps: {
        hideLoginPrompt: true,
      },
    });
  }, []);

  return (
    <div className="signup-view-container md:py-4">
      <AuthViewSignup />
    </div>
  );
};

export default SignupView;
