import React from 'react';
import { DateWithNullText, User } from '../UserTable/components';
import { ActorSort } from '../Actor/Actor';
import matchSorter from 'match-sorter';
import { ReactTableDefaults } from 'react-table';
import classnames from 'classnames';

const accountCreatedSort = (left, right, desc) =>
  ReactTableDefaults.defaultSortMethod(left.date, right.date, desc);

const influencerDashboardColumns = () => [
  {
    id: 'name',
    Header: 'Name',
    accessor: (row) => ({
      name: `${row.firstName} ${row.lastName}`,
      entity: row,
    }),
    Cell: (props) => User({ ...props }),
    className: 'col-left col-wrap',
    headerClassName: 'col-left',
    minWidth: 120,
    sortMethod: ActorSort,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [
          '_original.firstName',
          '_original.lastName',
          '_original.username',
        ],
        threshold: matchSorter.rankings.CONTAINS,
      }),
    filterAll: true,
  },
  {
    id: 'accountCreated',
    Header: 'Account Created',
    accessor: (row) => ({ date: row.redeemedDate, nullText: 'Waiting' }), // placeholder accessor
    Cell: (props) => (
      <span
        className={classnames({
          'col-waiting-highlight': !props.original.redeemedDate,
        })}
      >
        {props.original.redeemedDate ? 'Accepted' : 'Waiting'}
      </span>
    ),
    className: 'is-hidden-mobile',
    style: {
      textAlign: 'center',
    },
    sortMethod: accountCreatedSort,
  },
  {
    id: 'invitedDate',
    Header: 'Invited Date',
    accessor: (row) => ({ date: row.invitedDate, nullText: '' }), // placeholder accessor
    Cell: DateWithNullText,
    className: 'is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only',
    minWidth: 50,
    sortMethod: accountCreatedSort,
    style: {
      textAlign: 'center',
    },
  },
];

export default influencerDashboardColumns;
