import React from 'react';
import moment from 'moment';
import { observer, inject } from 'mobx-react';

import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import Button from 'components/Button/Button';
import ActionMenu from 'components/ActionMenu/ActionMenu';
import Currency from 'components/Currency/Currency';

import shareIcon from '../../assets/images/icons/share/share-orange.svg';
import eventPlaceholder from '../../assets/images/placeholders/placeholder-event-image.png';

const showEndedText = (cauze) => {
  if (moment(cauze.endDate).isBefore(moment())) {
    return true;
  }

  if (cauze.eventState === 'INACTIVE') {
    return true;
  }
  return false;
};

const getCauzeImage = (cauze) => {
  if (cauze.externalImageUrl) {
    return cauze.externalImageUrl;
  }
  if (cauze.image && cauze.image.lg) {
    return cauze.image.lg;
  }
  return eventPlaceholder;
};

const getDatesText = (startDate, endDate) => {
  if (startDate && endDate) {
    return (
      <div>
        {`${moment(startDate).format('M/D/YY')} - ${moment(endDate).format(
          'M/D/YY',
        )}`}
      </div>
    );
  }
  if (startDate && !endDate) {
    return <div>{`Started ${moment(startDate).format('M/D/YY')}`}</div>;
  }
  if (!startDate && endDate) {
    return <div>{`Ending ${moment(endDate).format('M/D/YY')}`}</div>;
  }
  if (!startDate && !endDate) {
    return null;
  }
};

const CauzesView = ({ cauzeStore, matchStore, userContext }) => {
  const onNavToCreateCauze = () => {
    cauzeStore.navToCreateCauze(userContext);
  };

  return (
    <div
      onClick={() => cauzeStore.onToggleMenu(null)}
      className="cauzes-view flex-column"
    >
      <div>
        <section className="container">
          <div className="page-header flex-row">
            <h1 className="title">Cauzes</h1>
            <Button className="is-small" onClick={onNavToCreateCauze}>
              Start a Cauze
            </Button>
          </div>
          {cauzeStore.cauzesLoading ? (
            <CauzeSpinner />
          ) : (
            <div className="dashboard-container cauzes-container">
              {cauzeStore.cauzes.length > 0 ? (
                <div className="flex-column">
                  <div className="cauze-dash flex-center flex-column">
                    <div className="dash-item">Your Totals:</div>
                    <div className="dash-item">
                      <span className="dash-number">
                        <Currency
                          showCents
                          amount={cauzeStore.cauzesAmountTotal}
                        />
                      </span>{' '}
                      Raised
                    </div>
                    <div className="dash-item">
                      <span className="dash-number">
                        {cauzeStore.cauzesJoinTotal}
                      </span>{' '}
                      Givers{' '}
                    </div>
                    <div className="dash-item">
                      <span className="dash-number">
                        {cauzeStore.cauzes.length}
                      </span>{' '}
                      Cauzes
                    </div>
                  </div>
                  <div className="flex-row cauze-list">
                    {cauzeStore.cauzes.map((cauze, index) => {
                      let match =
                        cauze.matchSummaries &&
                        cauze.matchSummaries.find(
                          (match) => match.matchAdmin.isSelf,
                        );
                      let ended = showEndedText(cauze);

                      return (
                        <div key={index} className="flex-column cauze-item">
                          <div className="flex-row">
                            <img
                              className="cauze-image"
                              src={getCauzeImage(cauze)}
                            />
                          </div>
                          {ended && (
                            <div className="event-ribbon">Cauze Ended</div>
                          )}
                          {cauze.eventState === 'UNPUBLISHED' && (
                            <div className="event-ribbon">Cauze Hidden</div>
                          )}
                          <div className="flex-row cauze-details">
                            <div className="flex-column flex-expand">
                              <div className="cauze-title text-title">
                                {cauze.name}
                              </div>
                              {getDatesText(cauze.startDate, cauze.endDate)}
                              <div className="text-normal">
                                <span className="text-bold">Raised:</span>{' '}
                                <Currency showCents amount={cauze.current} />
                              </div>
                              <div className="text-normal">
                                <span className="text-bold">Givers:</span>{' '}
                                {cauze.purchases.length}
                              </div>
                            </div>
                            <ActionMenu
                              onToggleOpen={(ev) => {
                                ev.stopPropagation();
                                cauzeStore.onToggleMenu(cauze.id);
                              }}
                              onToggleClose={(ev) => {
                                ev.stopPropagation();
                                cauzeStore.onToggleMenu(null);
                              }}
                              isActive={
                                cauze.id === cauzeStore.menuActiveForCharityId
                              }
                              actions={[
                                {
                                  name: 'View',
                                  action: () =>
                                    window.open(cauze.shareLink, '_blank'),
                                },
                                {
                                  name: 'Edit',
                                  action: () =>
                                    cauzeStore.navToEditCauze(
                                      userContext,
                                      cauze.id,
                                    ),
                                },
                                ...(!ended && match
                                  ? [
                                      {
                                        name: match.active
                                          ? 'End Match'
                                          : 'Restart Match',
                                        action: () =>
                                          matchStore.toggleMatch({
                                            matchId: match.id,
                                            active: !match.active,
                                          }),
                                      },
                                    ]
                                  : []),
                                {
                                  name: 'End Cauze',
                                  action: () =>
                                    cauzeStore.updatePlannedEventState({
                                      eventId: cauze.id,
                                      eventState: 'INACTIVE',
                                      index,
                                    }),
                                },
                                {
                                  name:
                                    cauze.eventState === 'UNPUBLISHED'
                                      ? 'Show to Public'
                                      : 'Hide from Public',
                                  action: () =>
                                    cauzeStore.updatePlannedEventState({
                                      eventId: cauze.id,
                                      eventState:
                                        cauze.eventState === 'UNPUBLISHED'
                                          ? 'ACTIVE'
                                          : 'UNPUBLISHED',
                                      index,
                                    }),
                                },
                              ]}
                            />
                          </div>
                          {!moment(cauze.endDate).isBefore(moment()) && (
                            <div className="cauze-share flex-column flex-expand flex-align-center">
                              <div className="flex-row flex-center share-text">
                                <img src={shareIcon} />
                                Share Your Cauze:
                              </div>
                              <input
                                readOnly
                                className="share-link"
                                value={cauze.shareLink}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <div className="hidden-flex" />
                  </div>
                </div>
              ) : (
                <div>No cauzes found.</div>
              )}
            </div>
          )}
          <div className="dashboard flex-row flex-space-around"></div>
        </section>
      </div>
    </div>
  );
};

export default inject('cauzeStore', 'matchStore')(observer(CauzesView));
