import React, { Component } from 'react';
import { debounce } from 'lodash';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { navigate } from '@reach/router';

import colors from 'styles/colors';

import { client as apolloClient } from 'util/apolloClient';
import { searchCharitiesQuery } from 'graphql/search';
import Avatar from 'components/Avatar/Avatar';
import { ReactComponent as SearchIcon } from 'assets/images/icons/search/search.svg';

const fetchResults = async (inputValue, callback) => {
  if (inputValue) {
    try {
      const result = await apolloClient
        .query({
          query: searchCharitiesQuery,
          variables: { query: inputValue },
          fetchPolicy: 'network-only',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        })
        .then((res) => {
          return res.data.searchCharity
            .map((charity) => ({
              ...charity,
              value: charity.id,
              label: charity.name,
            }))
            .concat([
              {
                value: 'see-all-results',
                label: `See all results for "${inputValue}"`,
              },
            ]);
        });
      callback(result);
    } catch (err) {
      console.error(err);
    }
  }
};

const animatedComponents = makeAnimated();

const styles = {
  multiValueLabel: (provided, _state) => ({
    ...provided,
    whiteSpace: 'normal',
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: '8px',
    borderColor: state.isFocused ? colors.baseColor : '#e2e8f0',
    '&:hover': {
      cursor: 'pointer',
      borderColor: colors.baseColor,
    },
  }),
  input: (provided, _state) => ({
    ...provided,
    paddingLeft: '1.5rem',
    color: '#1a1a1a',
  }),
  singleValue: (provided, _state) => ({
    ...provided,
    paddingLeft: '25px',
    color: '#1a1a1a',
  }),

  placeholder: (provided, _state) => ({
    ...provided,
    paddingLeft: '1.5rem',
    color: '#94a3b8',
  }),
  menu: (provided, _state) => ({
    ...provided,
    zIndex: 2,
    boxShadow:
      '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.baseColor : 'white',
    color: state.isSelected ? 'white' : '#1a1a1a',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: colors.baseColor,
      color: 'white',
    },
    '&:hover': {
      backgroundColor: colors.baseColor,
      color: 'white',
    },
  }),
};

const MultiValueContainer = (props) => (
  <components.MultiValueContainer {...props}>
    <div className="flex-row">
      {props.data.avatar && (
        <div
          style={{
            justifySelf: 'stretch',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            xsm
            style={{ marginLeft: '0.25rem' }}
            avatar={props.data.avatar}
          />
          {props.data}
        </div>
      )}
      {props.children}
    </div>
  </components.MultiValueContainer>
);

const Menu = (props) => {
  console.log('props', props);
  const inputValue = props.selectProps.inputValue;

  return (
    <>
      <components.Menu {...props}>
        {props.children}
        {Boolean(props.options.length) && (
          <div className="flex justify-center w-full bg-lightgray-f1f">
            <div
              className="w-full font-agenda text-gray-1b2 text-center py-4 !h-12 hover:cursor-pointer"
              onClick={(event) => {
                event.preventDefault();

                props.selectProps.onMenuClose();
                navigate('/donate/search', { state: { query: inputValue } });
              }}
            >
              See all results for{' '}
              <span className="font-agenda-bold">"{inputValue}"</span>
            </div>
          </div>
        )}
      </components.Menu>
    </>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex-row flex-align-center">
        <Avatar
          sm
          style={{ marginRight: '0.5rem' }}
          avatar={props.data.avatar}
          type="charity"
        />
        <div className="flex-column">
          {props.children}, &nbsp;
          {props.data.location} &nbsp;
          <div style={{ fontSize: '0.8rem', color: 'lightgrey' }}>
            EIN: {props.data.ein}
          </div>
        </div>
      </div>
    </components.Option>
  );
};

const debouncedFetchResults = debounce(fetchResults, 300);

const loadOptions = (inputValue, callback) => {
  debouncedFetchResults(inputValue, callback);
};

const ClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <div
        style={{
          padding: '0px 5px',
          cursor: 'pointer',
          color: colors.brand,
          fontWeight: 'bold',
          fontSize: '1rem',
        }}
      >
        clear all
      </div>
    </div>
  );
};

export default class CharitySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue,
      inputValue: '',
      dropdownOpen: false,
    };
  }

  removeValue = (index) => {
    let newValue = this.state.value.slice();
    newValue.splice(index, 1);
    this.setState({ value: newValue });
  };

  handleInputChange = (newValue) => {
    console.log('onInput', newValue, this.state);

    this.setState({ inputValue: newValue, dropdownOpen: true });
    return newValue;
  };
  handleKeyDown = (event) => {
    console.log('onKeyDown', event, event.key, event.target.value);
    if (event.key === 'Enter') {
      event.preventDefault();
      this.setState({ dropdownOpen: false });
      navigate('/donate/search', { state: { query: this.state.inputValue } });
    }
  };
  handleChange = (value) => {
    navigate(`/charity/${value?.id}`);
    this.setState({ value: value });
  };

  render() {
    return (
      <div className="search-container w-full">
        <div className="absolute top-2 left-3 w-6 h-6 z-10">
          <SearchIcon />
        </div>
        <AsyncSelect
          value={this.state.value}
          placeholder="Search Cauze"
          loadOptions={loadOptions}
          defaultOptions={false}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: colors.baseColor,
              primary50: colors.lightGrey,
              primary25: colors.white,
              danger: colors.baseColor,
              // dangerLight: colors.baseColorHalfTransparent,
            },
          })}
          menuIsOpen={this.state.dropdownOpen}
          styles={styles}
          onInputChange={this.handleInputChange}
          components={{
            ...animatedComponents,
            Option,
            Menu,
            MultiValueContainer,
            ClearIndicator,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          autoLoad={false}
          onMenuClose={() => this.setState({ dropdownOpen: false })}
          onMenuOpen={() => this.setState({ dropdownOpen: true })}
        />
        {this.props.showSelectionList && this.state.value && (
          <div className="search-result-container">
            {this.state.value?.map((charity) => (
              <div
                key={`${charity?.id}`}
                className="flex-row flex-align-center search-result-row font-agenda"
              >
                <Avatar avatar={charity.avatar} entityType={'CHARITY'} />
                <div className="flex-expand flex-column">
                  <div className="flex-row font-agenda-bold">
                    {charity.name}
                  </div>
                  <div className="flex-row result-detail">
                    {charity.location} | EIN: {charity.ein}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
