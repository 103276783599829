import React from 'react';
import { ReactTableDefaults } from 'react-table';
import Avatar from '../Avatar/Avatar';

const Actor = ({ value }) =>
  value ? (
    <div className="flex-row col-user-container">
      <div className="flex-column flex-expand min-width-reset">
        <div className="flex-row">
          <Avatar avatar={value.entity.avatar} />
          <div className="flex-column flex-expand col-user-details">
            <div>
              <div className="col-user-name">{value.name}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  );

const ActorSort = (left, right, desc) => {
  var a = left && left.name;
  var b = right && right.name;
  return ReactTableDefaults.defaultSortMethod(a, b, desc);
};

export { Actor, ActorSort };
