import React from 'react';
import cx from 'classnames';

const TopicPill = ({ topic, index, onClick, isSelected, loading }) => {
  const className =
    'px-3 py-1.5 capitalize tracking-wide max-h-[27px] flex items-center text-text-light rounded-lg font-agenda-black text-[13px] !font-medium justify-center text-center bg-lightgray-f1f ';

  if (loading) {
    return (
      <div className={`${className} animate-pulse bg-lightgray-b2b w-48`} />
    );
  }

  return (
    <a
      onClick={onClick}
      key={`${topic}-${index}`}
      className={cx(className, {
        'hover:bg-lightgray-f1f active:bg-lightgray-e8e': !isSelected,
        '!bg-black text-white hover:text-white': isSelected,
      })}
    >
      <div className="my-auto leading-none pt-1">{topic}</div>
    </a>
  );
};

export default TopicPill;
