import React, { useEffect, useMemo, useState } from 'react';
import { observer, inject } from 'mobx-react';
import * as events from 'util/events/checkout';
import withUserContext from 'behaviors/withUserContext';

import Button from 'components/Button/Button';
import Currency from 'components/Currency/Currency';
import LoginModal from 'components/modals/LoginModal/LoginModal';

import mobileAppGraphic from 'assets/images/illustrations/iphone-hand-cauzeapp.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import useDashboardStore from 'stores/DashboardStore';

const DonationWithGiftSuccessView = ({
  donationCheckoutStore,
  donationUnauthedCheckoutStore,
}) => {
  const { setProps } = useDashboardStore();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [checkoutStore, setCheckoutStore] = useState();

  const onCreateAccountClick = () => {
    events.downloadFromCheckoutConfirm();
    setShowLoginModal(true);
  };

  useEffect(() => {
    if (!donationCheckoutStore.checkoutLoading) {
      setCheckoutStore(donationCheckoutStore);
      return;
    }

    if (!donationUnauthedCheckoutStore.checkoutLoading) {
      setCheckoutStore(donationUnauthedCheckoutStore);
    }
  }, [donationCheckoutStore, donationUnauthedCheckoutStore]);

  const checkoutSuccess = useMemo(
    () => checkoutStore?.checkoutSuccess,
    [checkoutStore],
  );

  useEffect(() => {
    setProps({
      size: 'half',
      scroll: 0,
    });
  }, []);

  if (!checkoutStore || !checkoutSuccess) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div className="checkout-success gift-success flex-column">
      <LoginModal
        isOpen={showLoginModal}
        onToggleClose={() => setShowLoginModal(false)}
        defaultTab={0}
      />
      <div>
        <div className="success-message-container">
          <div className="success-check-icon">
            <FontAwesomeIcon icon={faCheck} aria-hidden="true" />
          </div>
          <h1 className="title">Donation Successful</h1>
          <div className="thanks-message">
            You made today count. Despite all the noise and news that can drown
            out good intentions, you took the meaningful step toward building
            the communities we each yearn for. Well done!
          </div>
          <div className="thanks-detail">
            We've sent a confirmation to your email.
          </div>
          {checkoutSuccess?.rewardAmount && (
            <div className="columns">
              <div className="column donate-again">
                <div className="flex-row">
                  <div className="flex-column">
                    <div className="donate-again-title">
                      Earn <Currency amount={checkoutSuccess.rewardAmount} /> to
                      Give Again
                    </div>
                    <div>
                      Create an Account to track your good and receive a $10 to
                      continue your giving.
                    </div>
                  </div>
                  <div className="flex-column">
                    <img src={mobileAppGraphic} />
                    <Button
                      className="is-medium"
                      onClick={onCreateAccountClick}
                    >
                      Create Account
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserContext(
  inject(
    'donationCheckoutStore',
    'donationUnauthedCheckoutStore',
    'profileStore',
  )(observer(DonationWithGiftSuccessView)),
);
