import React from 'react';

import { getEntityUrl } from 'util/navHelpers';

const FeedCardMatch = ({ matches }) => {
  return (
    <div className="feed-card-match">
      {matches.map((match, index) => (
        <div key={index} className="match flex-row flex-align-center">
          <span>
            <a
              href={getEntityUrl({
                id: match.id,
                entityType: match.entityType,
              })}
            >
              {match.name}
            </a>{' '}
            matched {match.multiplier > 1 ? `${match.multiplier}X` : ''}
          </span>
        </div>
      ))}
    </div>
  );
};

export default FeedCardMatch;
