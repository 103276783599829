import React, { useState } from 'react';
import Button from 'components/Button/Button';

import classnames from 'classnames';

const AddToBalanceModal = ({ isOpen, onToggleClose, companiesStore }) => {
  const [amount, setAmount] = useState();
  const [descriptionText, setDescriptionText] = useState();

  return (
    <div
      className={classnames('modal', {
        'is-active': isOpen,
      })}
    >
      <div className="modal-background"></div>
      <div className="modal-card send-gift-modal">
        <header className="modal-card-head">
          <p className="modal-card-title">Add To Company Balance</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onToggleClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="option-group">
            <p className="option-group-subhead is-size-5">
              Amount to add to company balance ($):
            </p>
            <div className="control">
              <input
                className="input comment-field"
                onChange={(ev) => {
                  setAmount(
                    isNaN(parseInt(ev.target.value))
                      ? 0
                      : parseInt(ev.target.value) * 100,
                  );
                }}
              />
            </div>
          </div>
          <div className="option-group">
            <p className="option-group-subhead is-size-5">Add description:</p>
            <div className="control">
              <textarea
                placeholder="Enter a description of this deposit"
                className="textarea comment-field"
                onChange={(ev) => {
                  setDescriptionText(ev.target.value);
                }}
                value={descriptionText}
              />
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-small" onClick={onToggleClose}>
            cancel
          </button>
          <Button
            disabled={typeof amount !== 'number'}
            className="button is-success is-small"
            onClick={() => {
              companiesStore.addToBalance({
                amount,
                description: descriptionText || '',
              });
              onToggleClose(false);
            }}
          >
            save
          </Button>
        </footer>
      </div>
    </div>
  );
};

export default AddToBalanceModal;
