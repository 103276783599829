import React, { useEffect, useMemo } from 'react';
import { observer, inject } from 'mobx-react';

import withUserContext from 'behaviors/withUserContext';
import EntityCauzesView from './EntityCauzesView';
import useProfileById from 'hooks/useProfileById';

const UserCauzesView = ({
  authStore,
  uiStore,
  userProfileStore,
  profileStore,
  profileId,
}) => {
  useProfileById({ id: profileId });

  const cauzes = profileStore.cauzes || [];
  const joinedEvents = userProfileStore.joinedEvents.get(profileId);
  const activeCauzes = useMemo(() => {
    return cauzes.filter((cauze) => cauze.eventState === 'ACTIVE');
  }, [cauzes]);

  const endedCauzes = useMemo(() => {
    return cauzes.filter((cauze) => cauze.eventState === 'INACTIVE');
  }, [cauzes]);

  const draftCauzes = useMemo(() => {
    return cauzes.filter((cauze) => cauze.eventState === 'UNPUBLISHED');
  }, []);
  console.log('cauzes', cauzes);

  useEffect(() => {
    if (profileId) {
      userProfileStore.getUser({
        id: profileId,
        userContext: { userId: profileId },
      });
      profileStore.getCauzes();
    }
  }, [profileId]);

  return (
    <EntityCauzesView
      loading={profileStore.loading || userProfileStore.joinedEventsLoading}
      isAuthenticated={authStore.isAuthenticated}
      activeCauzes={activeCauzes}
      endedCauzes={endedCauzes}
      draftCauzes={draftCauzes}
      joinedCauzes={joinedEvents}
      uiStore={uiStore}
    />
  );
};

export default withUserContext(
  inject(
    'authStore',
    'userProfileStore',
    'uiStore',
    'profileStore',
  )(observer(UserCauzesView)),
);
