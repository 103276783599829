import React from 'react';
import classnames from 'classnames';

import Currency from 'components/Currency/Currency';
import CauzeButton from 'components/CauzeButton/CauzeButton';

import useKeyboardEvent from 'hooks/useKeyboardEvent.js';
import { getProcessingChargeMessage } from 'util/checkoutUtils';

const DottedLine = () => (
  <div className="dotted-line">
    .............................................................................................................................
  </div>
);

const CheckoutConfirmationModal = ({
  onToggleClose,
  onConfirm,
  isOpen,
  currentCheckout,
  isSubmitting,
  isAuthenticated,
}) => {
  const nonBalancePaymentMethods = ['stripe_cc', 'stripe_ach', 'ach', 'card'];
  const amount = isAuthenticated
    ? currentCheckout.splitAmount.remaining
    : currentCheckout.splitAmount.forCauze;
  const paymentType = currentCheckout.paymentType.toLowerCase();

  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  return (
    <div
      className={classnames('modal checkout-confirm-modal', {
        'is-active': isOpen,
      })}
    >
      <div
        className="modal-background "
        onClick={() => {
          onToggleClose(false);
        }}
      ></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Checkout Summary</p>
        </header>
        <section className="modal-card-body">
          <div className="modal-card-flex">
            {currentCheckout.eventId && (
              <div>
                <span className="target-name">{currentCheckout.eventName}</span>
              </div>
            )}
            {nonBalancePaymentMethods.includes(paymentType) && (
              <div className="flex-column">
                <div className="line-item flex-row">
                  <div>Your Donation</div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={amount || currentCheckout.donationAmount}
                    />
                  </div>
                </div>
                <div className="line-item flex-row">
                  <div>Processing Fee</div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={currentCheckout.splitAmount.fee}
                    />
                  </div>
                </div>
                <div className="is-size-7">
                  {/* Our credit card processor charges a mandatory processing fee of
                2.2% + .30. 100% of your intended donation goes to the
                nonprofit. */}
                  {getProcessingChargeMessage(currentCheckout)}
                </div>
                <div className="line-item total flex-row">
                  <div>Total:</div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={currentCheckout.splitAmount.total}
                    />
                  </div>
                </div>
              </div>
            )}
            {paymentType === 'balance' && (
              <div className="flex-column">
                <div className="line-item flex-row">
                  <div>Current Balance:</div>
                  <DottedLine />
                  <div>
                    <Currency showCents amount={currentCheckout.balance} />
                  </div>
                </div>
                <div className="line-item flex-row">
                  <div>Your Donation: </div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={currentCheckout.splitAmount.total}
                    />
                  </div>
                </div>
                <div className="line-item flex-row">
                  <div>Remaining Balance: </div>
                  <DottedLine />
                  <div>
                    <Currency
                      showCents
                      amount={
                        currentCheckout.balance -
                        currentCheckout.splitAmount.total
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <CauzeButton
              black
              isSubmitting={isSubmitting}
              tall={true}
              className="bg-black font-black is-medium is-fullwidth full-width border-none uppercase text-20 cursor-pointer"
              baseClass="cauze-button-donate"
              onClick={() => {
                onConfirm();
              }}
              btnId="checkout-summary-give"
            >
              Give
            </CauzeButton>
          </div>
        </section>
      </div>
    </div>
  );
};

export { DottedLine };

export default CheckoutConfirmationModal;
