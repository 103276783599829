import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import useKeyboardEvent from 'hooks/useKeyboardEvent';
import { observer } from 'mobx-react';

const ImageExpandModal = ({ previewUrl, onToggleClose, isOpen }) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose();
  });

  return (
    <div
      className={cx('modal image-expand-modal', {
        'is-active': isOpen,
      })}
    >
      <div className="modal-background" onClick={onToggleClose} />
      <div className="modal-card " onClick={onToggleClose}>
        <section className="modal-body" onClick={onToggleClose}>
          <div className="full-image">
            <header className="modal-header">
              <div
                className="close-button flex-row flex-justify-end"
                onClick={onToggleClose}
              >
                <FontAwesomeIcon icon={faTimes} size="2x" />
              </div>
            </header>
            <img src={previewUrl} alt="Expanded preview" />
          </div>
        </section>
      </div>
    </div>
  );
};

export default observer(ImageExpandModal);
