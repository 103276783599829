import { useEffect, useState } from 'react';
import cx from 'classnames';

import Avatar from 'components/Avatar/Avatar';
import LargeNumber from 'components/LargeNumber/LargeNumber';
import EventList from 'components/EventList/DiscoverEventList';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

import { navigate, Link } from '@reach/router';

export const DashboardCard = ({
  className,
  children,
  title,
  titleSize,
  left,
}) => {
  return (
    <div
      className={cx(
        className,
        'p-5 flex flex-col gap-y-5 border border-solid border-lightgray-f1f rounded-[10px]',
      )}
    >
      <div className="flex">
        {left}
        <h3
          className={cx('font-agenda-bold text-2xl leading-none my-auto', {
            'text-2xl': !titleSize,
            [titleSize]: titleSize,
          })}
        >
          {title}
        </h3>
      </div>
      {children}
    </div>
  );
};

export const YourAccountsCard = ({
  avatar,
  name,
  entityType,
  id,
  userProfileStore,
  amounts = [],
  userEntity = false,
}) => {
  const [balance, setBalance] = useState(0);
  const [amountGiven, setAmountGiven] = useState(0);
  const [followerCount, setFollowerCount] = useState(0);

  const isUser =
    userEntity || entityType === 'COMPANY' || entityType === 'INFLUENCER';
  let data;

  if (isUser) {
    data = userProfileStore.users.get(id) || {};
  }

  useEffect(() => {
    userProfileStore.getUser({ id });
  }, []);

  useEffect(() => {
    if (!balance) {
      setBalance(amounts[0]);
    }

    if (!amountGiven) {
      setAmountGiven(data?.profileData?.userGood?.alltimeGoodSum || 0);
    }

    if (!followerCount) {
      setFollowerCount(data?.profileData?.followerCount);
    }
  }, [data]);

  let href = '';
  if (entityType === 'CHARITY') {
    href = `/admin/charity/${id}`;
  } else if (entityType === 'COMPANY') {
    href = `/admin/company/${id}`;
  } else {
    href = `/admin/user/${id}/activity`;
  }

  return (
    <Link
      to={href}
      className="text-inherit"
      onMouseDown={() => {
        if (entityType === 'CHARITY') {
          return navigate(`/admin/charity/${id}`);
        } else if (entityType === 'COMPANY') {
          return navigate(`/admin/company/${id}`);
        } else {
          return navigate(`/admin/user/${id}/activity`);
        }
      }}
    >
      <DashboardCard
        title={name}
        className="cursor-pointer hover:bg-lightgray-f7f active:bg-lightgray-f1f"
        titleSize="text-[16px]"
        left={<Avatar noBorder className="mr-[10px]" avatar={avatar} />}
      >
        <div className="flex justify-between">
          <div>
            <div className="font-agenda-bold text-[22px] h-[22px] leading-none">
              <LargeNumber isCurrency amount={(balance ?? 0) / 100} />
            </div>
            <div className="font-agenda text-sm text-text-light">
              {isUser ? 'Balance' : 'Raised'}
            </div>
          </div>
          <div>
            <div className="font-agenda-bold text-[22px] h-[22px] leading-none">
              <LargeNumber isCurrency amount={(amountGiven ?? 0) / 100} />
            </div>
            <div className="font-agenda text-sm text-text-light">
              {isUser ? 'Amount Given' : 'Cauzes Started'}
            </div>
          </div>
          <div>
            <div className="font-agenda-bold text-[22px] h-[22px] leading-none">
              {followerCount ?? 0}
            </div>
            <div className="font-agenda text-sm text-text-light">Followers</div>
          </div>
        </div>
      </DashboardCard>
    </Link>
  );
};

export const CauzesSection = ({
  events,
  title,
  loading = false,
  viewAllHref,
}) => {
  if (loading) {
    return (
      <div>
        <CauzeSpinner />
      </div>
    );
  }

  if (!events?.length) return <></>;

  return (
    <div>
      <div className="flex justify-between h-[22px] font-agenda-bold mb-5">
        <h2 className="text-[22px] leading-none">{title}</h2>
        <Link
          className="text-black text-[17px] my-auto underline"
          to={viewAllHref}
        >
          View All
        </Link>
      </div>
      <EventList events={events} />
    </div>
  );
};

export const MobileTabs = ({ tabIndex = 0, setTabIndex }) => {
  const buttonClassName =
    'uppercase font-agenda-black text-[17px] h-[33px] bg-transparent leading-none border-0 p-0 border-b-4 border-solid';

  return (
    <div className="flex justify-center gap-x-6">
      <button
        onClick={() => {
          setTabIndex(0);
        }}
        className={cx(buttonClassName, {
          'text-cauze-primary border-cauze-primary': tabIndex === 0,
          'text-gray-3d3 border-transparent': tabIndex !== 0,
        })}
      >
        DASHBOARD
      </button>
      <button
        onClick={() => {
          setTabIndex(1);
        }}
        className={cx(buttonClassName, {
          'text-cauze-primary border-cauze-primary border-b-solid':
            tabIndex === 1,
          'text-gray-3d3 border-transparent': tabIndex !== 1,
        })}
      >
        FEED
      </button>
    </div>
  );
};
