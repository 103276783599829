import React from 'react';
import matchSorter from 'match-sorter';
import { TableCurrency, User, DateWithNullText } from './components';
import Currency from 'components/Currency/Currency';
import ColumnHeader from 'components/ColumnHeader/ColumnHeader';
import classnames from 'classnames';
import { ReactTableDefaults } from 'react-table';

const dateSort = (left, right, desc) =>
  ReactTableDefaults.defaultSortMethod(left.date, right.date, desc);

const employeeColumns = ({
  totalUsersBalance = 0,
  companyEligibleMatch = 0,
  companyRemainingMatch = 0,
  companyUserMatchLimit = 0,
  totalDonationCount = 0,
  totalGiftBalance = 0,
}) => [
  {
    id: 'email',
    Header: 'Name',
    accessor: 'email',
    Cell: (props) =>
      User({ ...props, totalUsersBalance, companyEligibleMatch }),
    className: 'col-left',
    headerClassName: 'col-left',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [
          'email',
          '_original.lastName',
          '_original.firstName',
          '_original.fullName',
        ],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    id: 'expand',
    expander: true,
    className: 'is-hidden-desktop',
    headerClassName: 'is-hidden-desktop',
  },
  {
    Header: 'id',
    accessor: 'id',
    minWidth: 30,
    className: 'is-hidden',
    headerClassName: 'is-hidden',
  },
  {
    id: 'gift',
    accessor: 'giftAmount',
    Header: 'Gifts',
    Cell: (props) =>
      props.value !== 0 && (
        <div
          className={classnames('gift', {
            'gift-unredeemed': !props.original.companyGiftTotal,
          })}
        >
          <Currency amount={Math.abs(props.value)} />
          {props.original.companyGiftTotal ? null : (
            <span className="gift-unredeemed gift-unredeemed-text">
              <br />
              (unredeemed)
            </span>
          )}
        </div>
      ),
    className: 'is-hidden-mobile',
    headerClassName: 'is-hidden-mobile',
    minWidth: 25,
    style: {
      textAlign: 'center',
    },
    filterMethod: (filter, row) => {
      if (filter.value.indexOf('all') > -1) {
        return true;
      }
      if (filter.value.indexOf('unredeemed') > -1) {
        return row[filter.id] < 0;
      }
      return row[filter.id] > 0;
    },
  },
  {
    id: 'employerGiftRemaining',
    accessor: 'employerGiftRemaining',
    Header: () => (
      <ColumnHeader title="Gift Balance" amount={totalGiftBalance} />
    ),
    Cell: (props) =>
      props.value ? (
        <TableCurrency value={props.original.employerGiftRemaining} />
      ) : null,
    className: 'is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only',
    minWidth: 30,
    style: {
      textAlign: 'center',
    },
  },
  {
    Header: () => (
      <ColumnHeader title="Donations" subtitle={totalDonationCount} />
    ),
    className: 'is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only',
    accessor: 'donationCount',
    minWidth: 20,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'eligibleMatch',
    accessor: 'eligibleMatch',
    Header: () => (
      <ColumnHeader title="Eligible Match" amount={companyEligibleMatch} />
    ),
    Cell: (props) => (
      <TableCurrency
        value={props.original.redeemed ? props.value : companyUserMatchLimit}
      />
    ),
    className: 'is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only',
    minWidth: 30,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'remainingMatch',
    Header: () => (
      <ColumnHeader title="Remaining Match" amount={companyRemainingMatch} />
    ),
    accessor: 'remainingMatch',
    Cell: (props) => (
      <TableCurrency
        // If the user is unredeemed, their remaining match is the companies user max
        value={props.original.redeemed ? props.value : companyUserMatchLimit}
      />
    ),
    className: 'is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only',
    minWidth: 30,
    style: {
      textAlign: 'center',
    },
    filterMethod: (filter, row) => {
      if (filter.value.indexOf('all') > -1) {
        return true;
      }
      if (filter.value.indexOf('no match') > -1) {
        return row[filter.id] === 0;
      }
      return row[filter.id] > 0;
    },
  },
  {
    id: 'accountCreated',
    Header: 'Account Created',
    accessor: (row) => ({ date: row.insertedAt, nullText: 'Waiting' }),
    Cell: DateWithNullText,
    className: 'is-hidden-mobile',
    headerClassName: 'is-hidden-mobile',
    minWidth: 30,
    style: {
      textAlign: 'center',
    },
    sortMethod: dateSort,
    filterMethod: (filter, row) => {
      if (filter.value.indexOf('all') > -1) {
        return true;
      }
      if (filter.value.indexOf('Account Created') > -1) {
        return row[filter.id].date !== undefined; // Only populated when there's a created date
      }
      if (filter.value.indexOf('Waiting') > -1) {
        return row[filter.id].date === undefined; // Null otherwise when waiting
      }
    },
  },
  {
    id: 'invitedDate',
    Header: 'Invited Date',
    accessor: (row) => ({ date: row.invitedDate, nullText: '' }),
    Cell: DateWithNullText,
    className: 'is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only',
    minWidth: 30,
    sortMethod: dateSort,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'lastDonationDate',
    Header: 'Last Donation',
    accessor: (row) => ({ date: row.lastDonationDate, nullText: '' }),
    Cell: DateWithNullText,
    className: 'is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only',
    minWidth: 30,
    sortMethod: dateSort,
    style: {
      textAlign: 'center',
    },
  },
];

const pendingColumns = ({ actions }) => {
  let inviteAction = actions.find((action) => action.name === 'Send Invite');

  return [
    {
      id: 'email',
      Header: 'Name',
      accessor: 'email',
      Cell: User,
      className: 'col-left',
      headerClassName: 'col-left',
      filterAll: true,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ['email', '_original.lastName', '_original.firstName'],
          threshold: matchSorter.rankings.CONTAINS,
        }),
    },
    {
      Header: 'id',
      accessor: 'id',
      minWidth: 30,
      className: 'is-hidden',
      headerClassName: 'is-hidden',
    },
    {
      Header: 'Gift',
      accessor: 'giftAmount',
      minWidth: 10,
      Cell: (props) => <Currency amount={Math.abs(props.value)} />,
      style: {
        textAlign: 'center',
      },
    },
    {
      id: 'tokenId',
      Header: '',
      accessor: 'tokenId',
      Cell: ({ original }) => (
        <button
          className="button is-primary is-small"
          onClick={() => inviteAction.action([original.id])}
        >
          invite
        </button>
      ),
      minWidth: 30,
      style: {
        textAlign: 'center',
      },
    },
    {
      id: 'invitedDate',
      Header: 'Date Added',
      accessor: (row) => ({ date: row.invitedDate, nullText: '' }),
      Cell: DateWithNullText,
      className: 'is-hidden-mobile is-hidden-tablet-only',
      headerClassName: 'is-hidden-mobile is-hidden-tablet-only',
      minWidth: 30,
      style: {
        textAlign: 'center',
      },
    },
  ];
};

const groupMemberColumns = ({
  totalGroupDonationCount = 0,
  totalGroupDonationAmount = 0,
}) => [
  {
    id: 'email',
    Header: 'Name',
    accessor: 'email',
    Cell: (props) => User({ ...props }),
    className: 'col-left',
    headerClassName: 'col-left',
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [
          'email',
          '_original.lastName',
          '_original.firstName',
          '_original.fullName',
        ],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    id: 'expand',
    expander: true,
    className: 'is-hidden-desktop',
    headerClassName: 'is-hidden-desktop',
  },
  {
    Header: 'id',
    accessor: 'id',
    minWidth: 30,
    className: 'is-hidden',
    headerClassName: 'is-hidden',
  },
  {
    id: 'gift',
    accessor: 'groupDonationTotal',
    Header: () => (
      <ColumnHeader title="Group Donations" amount={totalGroupDonationAmount} />
    ),
    Cell: (props) => (
      <div className="gift">
        {props.original.redeemed && <Currency amount={props.value} />}
      </div>
    ),
    className: 'is-hidden-mobile',
    headerClassName: 'is-hidden-mobile',
    minWidth: 20,
    style: {
      textAlign: 'center',
    },
  },
  {
    Header: () => (
      <ColumnHeader title="Donation Count" subtitle={totalGroupDonationCount} />
    ),
    className: 'is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only',
    accessor: 'groupDonationCount',
    minWidth: 20,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'accountCreated',
    Header: 'Account Created',
    accessor: (row) => ({ date: row.insertedAt, nullText: 'Waiting' }),
    Cell: DateWithNullText,
    className: 'is-hidden-mobile',
    headerClassName: 'is-hidden-mobile',
    minWidth: 30,
    style: {
      textAlign: 'center',
    },
    sortMethod: dateSort,
    filterMethod: (filter, row) => {
      if (filter.value.indexOf('all') > -1) {
        return true;
      }
      if (filter.value.indexOf('Account Created') > -1) {
        return row[filter.id].date !== undefined; // Only populated when there's a created date
      }
      if (filter.value.indexOf('Waiting') > -1) {
        return row[filter.id].date === undefined; // Null otherwise when waiting
      }
    },
  },
  {
    id: 'invitedDate',
    Header: 'Invited Date',
    accessor: (row) => ({ date: row.invitedDate, nullText: '' }),
    Cell: DateWithNullText,
    className: 'is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only',
    minWidth: 30,
    sortMethod: dateSort,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'lastDonationDate',
    Header: 'Last Group Donation',
    accessor: (row) => ({ date: row.lastGroupDonationDate, nullText: '' }),
    Cell: DateWithNullText,
    className: 'is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only',
    minWidth: 30,
    sortMethod: dateSort,
    style: {
      textAlign: 'center',
    },
  },
];

const companyColumns = (props) => {
  if (props.tableType === 'pending') {
    return pendingColumns(props);
  }
  return employeeColumns(props);
};

const groupColumns = (props) => groupMemberColumns(props);

export { companyColumns, groupColumns };
