import React from 'react';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { navigate } from '@reach/router';
import 'swiper/css';
import 'swiper/css/navigation';

import TopicPill from 'components/TopicPill/TopicPill';

const TopicListItem = ({ topic, loading, isSelected }) => {
  return (
    <TopicPill
      isSelected={isSelected}
      topic={topic}
      loading={loading}
      onClick={() => {
        if (loading) return;

        let _topic = topic;

        if (isSelected) {
          _topic = '';
        }

        navigate('/donate/search', { state: { topic: _topic } });
      }}
    />
  );
};

const TopicList = ({ topics, activeTopics = [], loading }) => {
  if (loading) {
    return (
      <div className="flex gap-x-2">
        {['', '', '', ''].map((topic) => (
          <TopicListItem key={topic} loading />
        ))}
      </div>
    );
  }

  return (
    <Swiper
      className={cx('topics-list', {
        'show-arrows': topics && topics.length > 2,
      })}
      navigation
      modules={[Navigation]}
      spaceBetween={8}
      slidesPerView="auto"
    >
      {topics.map((topic) => (
        <SwiperSlide className="my-auto" key={topic} style={{ width: 'auto' }}>
          <TopicListItem
            topic={topic}
            isSelected={activeTopics.includes(topic)}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TopicList;
