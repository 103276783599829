import React from 'react';
import { observer, inject } from 'mobx-react';
import classnames from 'classnames';
import { navigate } from '@reach/router';

import withUserContext from '../../../behaviors/withUserContext';
import Avatar from '../../Avatar/Avatar';
import Button from 'components/Button/Button';
import { getAdminUrl } from '../../../util/navHelpers';

const roleDisplayName = ({ entityType, type, role }) => {
  if (!type && !role) {
    return;
  }

  if (entityType === 'USER') {
    if (role === 'influencer' || role === 'influencer_admin') {
      return 'Influencer Account';
    }
  }

  if (entityType === 'CHARITY') {
    return 'Charity Account';
  }

  if (entityType === 'COMPANY') {
    return 'Company Account';
  }
};

const ContextSelectorModal = ({
  title = 'Select a User',
  buttonText = 'ok',
  isOpen,
  onToggleClose,
  profileStore,
  onConfirm,
}) => {
  const onClose = () => {
    onToggleClose();
  };

  return (
    <div
      className={classnames('user-search-modal modal', {
        'is-active': isOpen,
      })}
    >
      <div onClick={onClose} className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <section className="modal-card-body flex-column">
          <div className="flex-column context-selector">
            {profileStore.availableUserEntities.map((entity, index) => (
              <div
                className="flex-row context-row"
                onClick={() => {
                  onClose();
                  profileStore.setActiveEntity(index);
                  navigate(
                    getAdminUrl(profileStore.availableUserEntities[index]),
                  );
                }}
              >
                <Avatar
                  style={{ marginRight: '15px', marginLeft: '5px' }}
                  sm
                  avatar={entity.avatar}
                  entityType={entity.entityType}
                />
                <div className="flex-column">
                  <div className="context-name">{entity.name}</div>
                  <div className="context-type">{roleDisplayName(entity)}</div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <footer className="modal-card-foot">
          <Button white className="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="button"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {buttonText}
          </Button>
        </footer>
      </div>
    </div>
  );
};

export default withUserContext(
  inject(
    'profileStore',
    'authStore',
    'uiStore',
  )(observer(ContextSelectorModal)),
);
