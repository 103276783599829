import { observable, action } from 'mobx';
import { client as api2Client } from 'util/api2Client';
import {
  createContentReportMutation,
  blockEntityMutation,
  unblockEntityMutation,
} from 'graphql/reporting';

class UserReportsStore {
  @observable isReporting = false;

  @action getReportVars = (reportProps) => {
    const vars = {};
    vars.comment = reportProps.comment;
    vars.reasons = this.getSelectedReports(reportProps);
    // API2 ids are Ints but they're strings in the old system
    if (reportProps.userId) vars.userId = +reportProps.userId;
    if (reportProps.companyId) {
      vars.companyId = +reportProps.companyId;
    }
    if (reportProps.projectId) {
      vars.projectId = +reportProps.projectId;
    }
    if (reportProps.eventId) vars.eventId = +reportProps.eventId;
    if (reportProps.purchaseId) {
      vars.purchaseId = +reportProps.purchaseId;
    }
    if (reportProps.purchaseCommentId) {
      vars.purchaseCommentId = +reportProps.purchaseCommentId;
    }
    return vars;
  };

  @action report = async (reportProps) => {
    this.isReporting = true;
    const options = {
      mutation: createContentReportMutation,
      variables: this.getReportVars(reportProps),
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      await api2Client.mutate(options);
      await this.block(reportProps);
    } catch (err) {
      console.log(err);
    }
    this.isReporting = false;
  };

  @action block = async (reportProps) => {
    console.log('block', reportProps);
    this.isReporting = true;
    const options = {
      mutation: blockEntityMutation,
      variables: this.getReportVars(reportProps),
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      await api2Client.mutate(options);
    } catch (err) {
      console.log(err);
    }
    this.isReporting = false;
  };

  @action unblock = async (reportProps) => {
    this.isReporting = true;
    const options = {
      mutation: unblockEntityMutation,
      variables: this.getReportVars(reportProps),
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      await api2Client.mutate(options);
    } catch (err) {
      console.log(err);
    }
    this.isReporting = false;
  };

  getSelectedReports = (reportProps) =>
    Object.keys(reportProps.reasons).filter(
      (reason) => reportProps.reasons[reason] === true,
    );

  @action onUnmount = () => {
    this.isReporting = false;
  };
}

const userReportsStore = new UserReportsStore();
export default userReportsStore;
