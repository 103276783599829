import React, { useState, useRef, useEffect } from 'react';
import { navigate } from '@reach/router';
import { observer, inject } from 'mobx-react';
import classnames from 'classnames';
import mixpanel from 'mixpanel-browser';

import { onLogoutClick } from 'util/userUtils';
import useKeyboardEvent from 'hooks/useKeyboardEvent.js';

import Currency from 'components/Currency/Currency';
import Avatar from 'components/Avatar/Avatar';
import { getLedgerUrl, getProfileUrl } from 'util/stringUtils';
import useMobile from 'hooks/useMobile';

const ContextSwitchEntity = ({
  isActive,
  onButtonClick,
  onMouseDown,
  onTouchEnd,
  avatar,
  name,
  entityType,
}) => {
  const getEntityDesc = (entityType) => {
    if (entityType === 'PERSONAL') {
      return 'Your Personal Account';
    }

    if (entityType === 'COMPANY') {
      return 'Your Company Account';
    }

    if (entityType === 'CHARITY') {
      return 'Your Charity';
    }

    return 'Your Influencer Account';
  };

  return (
    <div
      className={classnames(
        'text-black py-[5px] px-[10px] flex gap-x-[10px] cursor-pointer rounded-[10px] hover:bg-lightgray-f1f',
        {
          'bg-lightgray-f1f': isActive,
        },
      )}
      onMouseDown={onMouseDown}
      onTouchEnd={onTouchEnd}
    >
      <div className="flex justify-between items-center w-full gap-x-2.5">
        <div className="flex gap-2.5">
          <Avatar noBorder avatar={avatar} />
          <div className="text-gray-1b2">
            <div className="min-h-[21px] font-agenda-bold text-[17px]">
              {name}
            </div>
            <div className="font-agenda text-[14px]">
              {getEntityDesc(entityType)}
            </div>
          </div>
        </div>
        <button
          onClick={onButtonClick}
          onMouseDown={onButtonClick}
          className="py-2.5 px-4 h-[33px] rounded-lg border-gray-949 bg-inherit border-[1px] text-black text-center font-agenda-bold  cursor-pointer text-[12px] uppercase hover:bg-lightgray-f1f"
        >
          View
        </button>
      </div>
    </div>
  );
};

const UserDropDown = ({
  authStore,
  profileStore,
  eventStore,
  className,
  activeEntity,
  id,
  hideContextSelector = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useMobile();

  useKeyboardEvent('Escape', () => {
    setIsOpen(false);
  });

  const activeUserId = activeEntity.entityType === 'USER' && activeEntity.id;

  let triggerRef = useRef(null);

  useEffect(() => {
    if (!isOpen && triggerRef.current) {
      triggerRef.current.blur();
    }
  }, [isOpen]);

  if (!profileStore.hasFetchedInitial) {
    return null;
  }

  const _setIsOpen = (open) => {
    console.log('in setIsOpen', open);
    mixpanel.track(`User Drop Down ${open ? 'opened' : 'closed'}`);
    setIsOpen(open);
  };
  const handleLogOut = () => {
    onLogoutClick(authStore, profileStore, eventStore);
    navigate('/login');
  };

  const handleNavigateToAccountLedger = () => {
    const accountLedgerUrl = getLedgerUrl(
      activeEntity.id,
      activeEntity.entityType,
    );
    navigate(accountLedgerUrl);
  };

  return (
    <div
      id={id}
      className={classnames(
        'dropdown',
        'user-dropdown',
        'is-right',
        { 'is-active': isOpen },
        className,
      )}
    >
      <button
        className="cz-btn-outline !font-agenda capitalize min-h-10 min-w-[121px] mr-5 hidden lg:flex"
        onClick={handleNavigateToAccountLedger}
      >
        <div className="my-auto !leading-none text-[16px]">
          Balance:{' '}
          <Currency
            className="font-agenda-bold"
            amount={activeEntity.balance.total}
          />
        </div>
      </button>
      <div className="flex min-h-8 w-8 lg:min-h-10 lg:w-10">
        <button
          ref={triggerRef}
          onBlur={() => {
            _setIsOpen(false);
          }}
          onClick={() => {
            _setIsOpen(!isOpen);
          }}
          className="border-none bg-transparent my-auto user-avatar-btn !p-0"
          aria-haspopup="true"
          aria-controls="user-dropdown-menu"
        >
          <Avatar
            noBorder
            avatar={
              activeEntity.avatar
                ? activeEntity.avatar
                : activeEntity?.id === profileStore?.data?.id &&
                  profileStore?.data?.avatar
            }
          />
        </button>
      </div>
      <div
        className={classnames('dropdown-menu user-dropdown-menu', {
          '!left-[11px] !right-[11px] !top-[76px] !fixed !pt-0': isMobile,
        })}
        id="dropdown-menu"
        role="menu"
      >
        <div
          className={classnames('backdrop !mt-[60px] lg:!mt-[76px]', {
            'backdrop-lowered': !!activeUserId,
          })}
          onTouchEnd={() => {
            console.log('in touch end');
            _setIsOpen(false);
          }}
        />
        <div className="dropdown-content rounded-[10px] overflow-hidden !px-0 !pt-4 !pb-0 w-full lg:w-[337px]">
          {!hideContextSelector &&
            (profileStore.availableUserEntities.length >= 1 ||
              profileStore.groupRoles.length >= 1) && (
              <div className="flex flex-col gap-y-[10px] px-4 max-h-96 overflow-y-auto">
                {profileStore.availableUserEntities.length > 1 && (
                  <ContextSwitchEntity
                    isActive={
                      activeEntity.id === activeUserId &&
                      activeEntity.entityType === 'USER'
                    }
                    onButtonClick={(_e) => {
                      _e.stopPropagation();
                      const userId = profileStore.availableUserEntities[0].id;
                      navigate(`/profile/${userId}`);
                      _setIsOpen(false);
                    }}
                    onMouseDown={(_e) => {
                      profileStore.clearActiveEntity();
                      _setIsOpen(false);
                    }}
                    avatar={profileStore.data.avatar}
                    name={profileStore.data.firstName}
                    entityType={'PERSONAL'}
                  />
                )}

                {profileStore.availableUserEntities.map((context, index) =>
                  index === 0 ? null : (
                    <ContextSwitchEntity
                      isActive={
                        activeEntity.id === context.id &&
                        context.entityType === activeEntity.entityType
                      }
                      activeEntity={activeEntity}
                      key={`${index}-${context.id}`}
                      onButtonClick={(_e) => {
                        _e.stopPropagation();
                        const url = getProfileUrl(
                          context.id,
                          context.entityType,
                        );
                        navigate(url);
                        _setIsOpen(false);
                      }}
                      onMouseDown={(_e) => {
                        profileStore.setActiveEntity(index, true);
                        _setIsOpen(false);
                      }}
                      avatar={context.avatar}
                      name={context.name}
                      entityType={context.entityType}
                    />
                  ),
                )}

                {profileStore.groupRoles.map((entity, index) => (
                  <ContextSwitchEntity
                    isActive={
                      activeEntity.id === entity.id &&
                      activeEntity.entityType === entity.entityType
                    }
                    onButtonClick={(_e) => {
                      _e.stopPropagation();
                      navigate(`/company/${entity.id}`);
                      _setIsOpen(false);
                    }}
                    activeEntity={activeEntity}
                    key={`${index}-${entity.id}`}
                    name={entity.name}
                    avatar={entity.avatar}
                    entityType={entity.entityType}
                    onMouseDown={(_e) => {
                      _setIsOpen(false);
                    }}
                  />
                ))}
              </div>
            )}
          {authStore.isAuthenticated && (
            <button
              className="underline font-agenda-bold text-sm leading-loose w-full bg-white border-none cursor-pointer hover:bg-lightgray-f1f h-12"
              type="button"
              onMouseDown={handleLogOut}
              onClick={handleLogOut}
            >
              Log Out
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default inject(
  'authStore',
  'uiStore',
  'profileStore',
  'eventStore',
  'companyReportingStore',
)(observer(UserDropDown));
