import React from 'react';
import UserInfoInputField from 'components/UserInfoInputField/UserInfoInputField';

const UserInfoInput = ({
  donationCheckoutStore,
  setValidation,
  validation,
  setErrorMessage,
}) => (
  <div className="user-info-input-container" id="userInput">
    <div className="user-name-fields-container">
      <UserInfoInputField
        id="donation-first-name"
        title="First Name"
        className={!validation?.firstName && 'error'}
        value={donationCheckoutStore.currentCheckout.firstName}
        onUpdate={(ev) => {
          ev.preventDefault();
          donationCheckoutStore.updateCheckout({
            firstName: ev.target.value,
          });
          setValidation({ ...validation, firstName: true });
          setErrorMessage(false);
        }}
      />
      <UserInfoInputField
        id="donation-last-name"
        title="Last Name"
        className={!validation?.lastName && 'error'}
        value={donationCheckoutStore.currentCheckout.lastName}
        onUpdate={(ev) => {
          ev.preventDefault();
          donationCheckoutStore.updateCheckout({
            lastName: ev.target.value,
          });
          setValidation({ ...validation, lastName: true });
          setErrorMessage(false);
        }}
      />
    </div>
    <UserInfoInputField
      id="donation-email"
      title="Email"
      className={!validation?.email && 'error'}
      value={donationCheckoutStore.currentCheckout.email}
      onUpdate={(ev) => {
        ev.preventDefault();
        donationCheckoutStore.updateCheckout({
          email: ev.target.value,
        });
        setErrorMessage(false);
        setValidation({ ...validation, email: true });
        donationCheckoutStore.resetPendingInvites();
      }}
      onBlur={donationCheckoutStore.checkEmailTokens}
    />
  </div>
);

export default UserInfoInput;
