import React, { useRef, useEffect } from 'react';
import { observer, inject } from 'mobx-react';

import useCompanyAdmin from 'hooks/useCompanyAdmin';
import withUserContext from 'behaviors/withUserContext';
import withAuthRequired from 'behaviors/withAuthRequired';
import Button from 'components/Button/Button';
import PayrollLog from 'components/PayrollLog/PayrollLog';
import useDashboardStore from 'stores/DashboardStore';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const PayrollReportView = ({ companyAdminStore, uiStore, companyId }) => {
  const fileUploadRef = useRef(null);
  const { setProps } = useDashboardStore();
  useCompanyAdmin(companyId);

  useEffect(() => {
    companyAdminStore.getPayrollReport();
  }, [companyAdminStore]);

  useEffect(() => {
    setProps({
      size: 'screen',
      bodyClassName: 'bg-lightgray-e0e',
    });
  }, []);

  if (companyAdminStore.loading) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div className="company-admin payroll-report flex-column">
      <div>
        <section>
          <div className="page-header container flex-row">
            <h1 className="title">Payroll Report</h1>
            <Button
              isSubmitting={companyAdminStore.isUploadingCSV}
              className="is-small"
              onClick={() => fileUploadRef.current.click()}
            >
              Upload Payroll CSV
            </Button>
            <input
              ref={fileUploadRef}
              className="is-hidden"
              type="file"
              accept="text/csv"
              onChange={(e) => {
                e.preventDefault();

                const file = e.target.files[0];

                if (file.type !== 'text/csv') {
                  uiStore.showNotification({
                    type: 'ERROR',
                    body: 'File must be a CSV file',
                  });
                } else {
                  companyAdminStore.uploadPayrollCSV(file);
                }
              }}
            />
          </div>
        </section>
        <section className="container">
          <div className="payroll-log">
            {companyAdminStore.csvUploaded && (
              <div className="file-upload-success">
                {companyAdminStore.csvFilename} uploaded
              </div>
            )}
            <PayrollLog
              entries={companyAdminStore.payrollStatusReport}
              companyAdminStore={companyAdminStore}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default withAuthRequired(
  withUserContext(
    inject(
      'profileStore',
      'uiStore',
      'companyAdminStore',
    )(observer(PayrollReportView)),
  ),
  {
    entityType: 'COMPANY',
  },
);
