import React from 'react';
import matchSorter from 'match-sorter';
import classnames from 'classnames';
import ColumnHeader from 'components/ColumnHeader/ColumnHeader';
import { ReactTableDefaults } from 'react-table';
import { TableCurrency, DateWithNullText, User } from '../UserTable/components';

const recipientSort = (left, right, desc) => {
  const a = left?.email || left?.firstName;
  const b = right?.email || right?.firstName;

  return ReactTableDefaults.defaultSortMethod(a, b, desc);
};

/* a column's cell property passes props.original to the render function, which describes the whole
match for that row. props.value sends the specific key on the match object defined in 'accessor' */

const giftColumns = ({ totalGifted, remainingGifted, isGroup }) => [
  {
    id: 'recipient',
    Header: 'Recipient',
    accessor: 'recipient',
    className: 'col-left col-user-email',
    headerClassName: 'col-left',
    minWidth: 120,
    sortMethod: recipientSort,
    Cell: (props) =>
      props.original.recipient ? (
        User({ original: props.original.recipient })
      ) : (
        <span
          style={{
            fontSize: '0.9rem',
          }}
        >
          {props.original.email || props.original.phoneNumber}
        </span>
      ),
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [
          'email',
          '_original.recipient.firstName',
          '_original.recipient.lastName',
          '_original.email',
          '_original.phoneNumber',
        ],
        threshold: matchSorter.rankings.CONTAINS,
      }),
    filterAll: true,
  },
  {
    id: 'date',
    Header: 'Date',
    accessor: (row) => ({ date: row.insertedAt }),
    className: 'col-left col-wrap col-match-name',
    headerClassName: 'col-wrap',
    Cell: DateWithNullText,
    filterAll: true,
    minWidth: 45,
  },
  {
    id: 'resentDate',
    Header: 'Resent Date',
    accessor: (row) => ({ date: row.resentAt }),
    className: 'is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only',
    Cell: DateWithNullText,
    filterAll: true,
    minWidth: 45,
  },
  {
    id: 'type',
    Header: 'Type',
    accessor: 'type',
    className: 'col-wrap is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only',
    minWidth: 45,
    Cell: (props) => {
      if (props.value === 'Employee Gift' && isGroup) {
        return 'Member Gift';
      } else {
        return props.value;
      }
    },
    style: {
      textAlign: 'center',
    },
    filterMethod: (filter, row) => {
      if (filter.value.indexOf('all') > -1) {
        return true;
      }
      if (filter.value.indexOf('Customer Gift') > -1) {
        return row[filter.id] === 'Customer Gift';
      }
      if (filter.value.indexOf('Employee Gift') > -1) {
        return row[filter.id] === 'Employee Gift';
      }
      return row[filter.id] === 'Invite & Gift';
    },
  },
  {
    id: 'description',
    Header: 'Description',
    accessor: 'comment',
    minWidth: 100,
    className: 'col-wrap is-hidden-mobile is-hidden-tablet-only',
    headerClassName: ' is-hidden-mobile is-hidden-tablet-only',
    style: {
      textAlign: 'left',
    },
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
    Cell: (props) => (
      <span
        className={classnames({
          'col-waiting-highlight': props.original.status === 'Waiting',
          'col-revoked-highlight': props.original.status === 'Revoked',
        })}
      >
        {props.original.status}
      </span>
    ),
    minWidth: 35,
    style: {
      textAlign: 'center',
    },
    filterMethod: (filter, row) => {
      if (filter.value.indexOf('all') > -1) {
        return true;
      }
      if (filter.value.indexOf('Redeemed') > -1) {
        return row[filter.id] === 'Redeemed';
      }
      if (filter.value.indexOf('Revoked') > -1) {
        return row[filter.id] === 'Revoked';
      }
      return row[filter.id] === 'Waiting';
    },
  },
  {
    id: 'amount',
    Header: () => <ColumnHeader title="Amount" amount={totalGifted} />,
    accessor: 'amount',
    minWidth: 35,
    Cell: TableCurrency,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'remaining',
    Header: () => <ColumnHeader title="Remaining" amount={remainingGifted} />,
    accessor: 'remaining',
    minWidth: 35,
    Cell: TableCurrency,
    style: {
      textAlign: 'center',
    },
  },
];

export default giftColumns;
