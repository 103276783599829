import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import mixpanel from 'mixpanel-browser';
import { Redirect } from '@reach/router';

import CauzeButton from 'components/CauzeButton/CauzeButton';
import useDashboardStore from 'stores/DashboardStore';

const ResetPasswordView = ({ authStore, profileStore, token }) => {
  const [firstPasswordInput, setFirstPasswordInput] = useState('');
  const [secondPasswordInput, setSecondPasswordInput] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { setProps } = useDashboardStore();

  useEffect(() => {
    authStore.passwordResetTokenValidate(String(token));
  }, []);

  const enterHandler = (ev) => {
    if (ev.key === 'Enter') {
      onSubmit(firstPasswordInput, secondPasswordInput, String(token));
    }
  };

  const passwordMatchValidate = (firstPasswordInput, secondPasswordInput) => {
    if (firstPasswordInput !== '' && secondPasswordInput !== '') {
      if (firstPasswordInput !== secondPasswordInput) {
        setPasswordError('Passwords do not match');
        setIsButtonDisabled(true);
      }
      if (firstPasswordInput === secondPasswordInput) {
        setIsButtonDisabled(false);
      }
    }
  };

  const onSubmit = async (firstPasswordInput, secondPasswordInput, token) => {
    if (firstPasswordInput === '' || secondPasswordInput === '') {
      setPasswordError('Missing field(s)');
    } else if (firstPasswordInput !== secondPasswordInput) {
      setPasswordError('Passwords do not match');
    } else if (firstPasswordInput === secondPasswordInput) {
      await authStore.resetPassword(firstPasswordInput, token);

      if (!authStore.authError) {
        await profileStore.getProfile(authStore.stashedPathDetails);
        mixpanel.identify(profileStore.data.email.toLowerCase());
        mixpanel.track('Logged In', {
          email: profileStore.data.email.toLowerCase(),
        });
      }
    }
  };

  useEffect(() => {
    setProps({
      noSidebar: true,
      bodyClassName: 'bg-lightgray-f1f',
      navbarProps: {
        hideLoginPrompt: true,
      },
    });
  }, []);

  if (authStore.isAuthenticated) {
    return <Redirect to="/" />;
  }

  let content = <></>;

  if (authStore.passwordResetTokenValidateResponse === 'OK') {
    content = (
      <div className="login-view reset-password-view">
        <div className="reset-password-container">
          <div className="reset-password-card">
            <div className="card-header">Reset Password</div>
            <div className="card-body">
              <input
                className="password-input first"
                placeholder="Enter new password"
                type="password"
                onChange={(ev) => {
                  ev.preventDefault();
                  setFirstPasswordInput(ev.target.value);
                  setPasswordError('');
                }}
                onKeyUp={(ev) => {
                  enterHandler(ev);
                }}
                onBlur={(ev) => {
                  ev.preventDefault();
                  passwordMatchValidate(
                    firstPasswordInput,
                    secondPasswordInput,
                  );
                }}
              />
              <input
                className="password-input second"
                placeholder="Re-enter new password"
                type="password"
                onChange={(ev) => {
                  ev.preventDefault();
                  setSecondPasswordInput(ev.target.value);
                  setPasswordError('');
                }}
                onKeyUp={(ev) => {
                  enterHandler(ev);
                }}
                onBlur={(ev) => {
                  ev.preventDefault();
                  passwordMatchValidate(
                    firstPasswordInput,
                    secondPasswordInput,
                  );
                }}
              />
              <div className="password-error">{passwordError}</div>
            </div>

            <CauzeButton
              className="reset-submit-button"
              large
              disabled={isButtonDisabled}
              grey={isButtonDisabled}
              onClick={() => {
                onSubmit(
                  firstPasswordInput,
                  secondPasswordInput,
                  String(token),
                );
              }}
            >
              Submit
            </CauzeButton>
          </div>
        </div>
      </div>
    );
  }

  if (authStore.passwordResetTokenValidateResponse === 'INVALID') {
    content = (
      <div className="login-view reset-password-view">
        <div className="reset-password-container">
          <div className="reset-password-card">
            <div className="card-body error-message-container">
              <div className="error-message">
                The password reset link provided is invalid. Please try again{' '}
                <a href="/resetpassword">here.</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default inject('authStore', 'profileStore')(observer(ResetPasswordView));
