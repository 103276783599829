import React from 'react';
import { observer, inject } from 'mobx-react';

import useCompanyAdmin from 'hooks/useCompanyAdmin';
import withUserContext from 'behaviors/withUserContext';
import withAuthRequired from 'behaviors/withAuthRequired';

import Button from 'components/Button/Button';

import companyProfileImage from 'assets/images/screens/charity-profile.png';
import placeholderImage from 'assets/images/icons/placeholder/placeholder-charity@3x.png';

const handleCompanyLogoChange = (e, store) => {
  e.preventDefault();

  let reader = new FileReader();
  let file = e.target.files[0];

  reader.onloadend = () => {
    store.setCompanyLogoFile(file);
    store.setCompanyLogoUrl(reader.result);
  };

  reader.readAsDataURL(file);
};

const handleCompanyHeroChange = (e, store) => {
  e.preventDefault();

  let reader = new FileReader();
  let file = e.target.files[0];

  reader.onloadend = () => {
    store.setCompanyHeroFile(file);
    store.setCompanyHeroUrl(reader.result);
  };

  reader.readAsDataURL(file);
};

const CompanyProfileView = ({ companyAdminStore, companyId }) => {
  useCompanyAdmin(companyId);

  let companyLogoPreview = null;
  if (companyAdminStore.companyLogoUrl) {
    companyLogoPreview = (
      <img
        className="profile-preview-image"
        src={companyAdminStore.companyLogoUrl}
      />
    );
  } else {
    companyLogoPreview = (
      <img className="profile-preview-image" src={placeholderImage} />
    );
  }

  let companyHeroPreview = null;
  if (companyAdminStore.companyHeroUrl) {
    companyHeroPreview = (
      <img
        className="hero-preview-image"
        src={companyAdminStore.companyHeroUrl}
      />
    );
  } else {
    companyHeroPreview = (
      <img className="hero-preview-image" src={placeholderImage} />
    );
  }

  return (
    <div className="charity-admin charity-profile flex-column flex-expand">
      <section>
        <div className="page-header container flex-row">
          <h1 className="title">Update Profile</h1>
        </div>
      </section>
      <section className="page-body">
        <div className="container">
          <hr />
          <h2 className="subtitle is-size-3">Your Profile Page</h2>
          <div className="columns">
            <div className="column">
              <h3 className="subtitle image-header">Logo</h3>
              <p className="section-description">
                Ideal image size is 500x500 pixels.
              </p>
              <div className="image-preview-container">
                {companyLogoPreview}
              </div>
              <div
                style={{ flex: 1, display: 'flex' }}
                className="flex-column flex-space-around"
              >
                <form
                  className="image-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    companyAdminStore.submitCompanyLogo();
                  }}
                >
                  <input
                    className="fileInput"
                    type="file"
                    onChange={(e) =>
                      handleCompanyLogoChange(e, companyAdminStore)
                    }
                  />
                  <div>
                    <Button
                      isSubmitting={companyAdminStore.isUploadingCompanyLogo}
                      disabled={!companyAdminStore.companyLogoHasChanged}
                      className="is-small upload-button"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        companyAdminStore.submitCompanyLogo();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
              <h3 className="subtitle image-header">Cover Image</h3>
              <p className="section-description">
                Ideal image size is 1600x900 pixels (16x9 aspect ratio).
              </p>
              <div className="image-preview-container">
                {companyHeroPreview}
              </div>
              <div
                style={{ flex: 1, display: 'flex' }}
                className="flex-column flex-space-around"
              >
                <form
                  className="image-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    companyAdminStore.submitCompanyHero();
                  }}
                >
                  <input
                    className="fileInput"
                    type="file"
                    onChange={(e) =>
                      handleCompanyHeroChange(e, companyAdminStore)
                    }
                  />
                  <div>
                    <Button
                      isSubmitting={companyAdminStore.isUploadingCompanyHero}
                      disabled={!companyAdminStore.companyHeroHasChanged}
                      className="is-small upload-button"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        companyAdminStore.submitCompanyHero();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
              <div className="flex-column flex-expand page-section">
                <h2 className="subtitle">Bio</h2>
                <textarea
                  className="profile-textarea"
                  onChange={(ev) =>
                    companyAdminStore.setBioText(ev.target.value)
                  }
                  placeholder="Describe your companies charitable mission"
                  value={companyAdminStore.bio}
                />
                <div>
                  <Button
                    isSubmitting={companyAdminStore.updatingBioText}
                    disabled={!companyAdminStore.bioTextHasChanged}
                    className="button is-small save-button"
                    onClick={companyAdminStore.updateBio}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
            <div className="column">
              <img src={companyProfileImage} className="app-preview-image" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withAuthRequired(
  withUserContext(inject('companyAdminStore')(observer(CompanyProfileView))),
  { entityType: 'COMPANY' },
);
