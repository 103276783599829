import React, { useState } from 'react';
import cx from 'classnames';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useKeyboardEvent from 'hooks/useKeyboardEvent.js';
import CauzeSwitch from 'components/CauzeSwitch/CauzeSwitch';

const ReportModal = ({
  onToggleClose,
  isOpen,
  userReportsStore,
  reportProps,
  uiStore,
}) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  const [comment, setComment] = useState('');
  const [reasons, setReasons] = useState({
    'Pornography or sexually explicit material': false,
    'Unwanted commercial content or spam': false,
    'Child abuse': false,
    'Hate speech or graphic violence': false,
    'Promotes terrorism': false,
    'Harassment or bullying': false,
    'Suicide or self injury': false,
    Misinformation: false,
  });

  const handleReport = async () => {
    try {
      await userReportsStore.report({
        ...reportProps,
        reasons,
        comment,
      });
      uiStore.showNotification({
        body: 'Report successfully submitted!',
        type: 'SUCCESS',
      });
      onToggleClose();
    } catch (err) {
      uiStore.showNotification({
        body: 'Report failed. At least one reason needs to be selected.',
        type: 'ERROR',
      });
    }
  };

  return (
    <div
      className={cx('modal report-modal', {
        'is-active': isOpen,
      })}
    >
      <div
        className="modal-background"
        onClick={() => {
          onToggleClose(false);
        }}
      />
      <div className="modal-card ">
        <header className="confirm-modal-head">
          <div className="modal-card-title">
            <div
              className="close-button flex-row flex-justify-end"
              onClick={() => {
                onToggleClose(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="modal-card-title-text">
              Report Inappropriate Content
            </div>
          </div>
        </header>
        <section className="confirm-modal-body">
          <div className="message-container">
            <div>
              <p>What is the reason for reporting?</p>
              <textarea
                value={comment}
                onInput={(input) => {
                  setComment(input.target.value);
                }}
                className="message-input w-full p-3"
                placeholder={`I'm reporting because...`}
                rows="7"
                cols="50"
              />
            </div>
          </div>
          <div>
            {Object.entries(reasons).map(([key, val]) => (
              <div key={key}>
                <CauzeSwitch
                  isSelected={val}
                  label={key}
                  onChange={(e) => {
                    setReasons({
                      ...reasons,
                      [key]: e.target.checked,
                    });
                  }}
                />
              </div>
            ))}
          </div>
        </section>
        <footer className="modal-card-foot gap-x-3">
          <button
            className="cz-btn-outline black"
            onClick={() => {
              onToggleClose();
            }}
          >
            cancel
          </button>
          <button className="cz-btn-black" onClick={handleReport}>
            Send Now
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ReportModal;
