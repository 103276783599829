import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { navigate } from '@reach/router';

import useDonationCheckout from 'hooks/useDonationCheckout';
import withUserContext from 'behaviors/withUserContext';
import * as events from 'util/events/checkout';

import Button from 'components/Button/Button';
import CheckoutConfirmationModal from 'components/checkout/CheckoutConfirmationModal';
import ErrorModal from 'components/modals/ErrorModal';
import LoginModal from 'components/modals/LoginModal/LoginModal';
import ChooseAmountSection from 'components/checkout/ChooseAmountSection';
import PaymentSection from 'components/checkout/PaymentSection';
import ElementsWrapper from 'components/ElementsWrapper';
import useDashboardStore from 'stores/DashboardStore';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const DonationCheckoutViewContent = observer(
  ({
    eventId,
    charityId,
    userContext,
    donationCheckoutStore,
    donationUnauthedCheckoutStore,
    profileStore,
    authStore,
    activeEntity,
    uiStore,
    location, // location.state for the event view sending configs for default and/or minimum amounts
  }) => {
    const { setProps } = useDashboardStore();
    const [paymentRequest, setPaymentRequest] = useState(null);
    const urlParams = new URLSearchParams(location.search);
    let charityIds = urlParams.getAll('charity_id');
    let joinedPurchaseId = urlParams.getAll('joined_purchase_id');
    if (!joinedPurchaseId) {
      joinedPurchaseId = null;
    }
    if (charityId) {
      charityIds = [charityId];
    }

    const stripe = useStripe();
    const elements = useElements();

    const authedOrUnauthedDonationStore = authStore.isAuthenticated
      ? donationCheckoutStore
      : donationUnauthedCheckoutStore;

    authedOrUnauthedDonationStore.setStripeObject(stripe);

    const isAuthenticated = authStore.isAuthenticated;
    const checkoutDetails = authedOrUnauthedDonationStore.checkoutDetails;
    const currentCheckout = authedOrUnauthedDonationStore.currentCheckout;
    const minimumDonationAmount = location?.state?.minimumDonation;
    const waitingForPlaidLoad =
      isAuthenticated && !authedOrUnauthedDonationStore.plaidLinkToken;
    const waitingForStripeLoad = !stripe || !elements;

    const [showCannotDonate, setShowCannotDonate] = useState(
      activeEntity.entityType === 'CHARITY' && !activeEntity.canDonate,
    );
    const [showLoginModal, setShowLoginModal] = useState(false);

    useDonationCheckout({
      eventId,
      charityIds,
      joinPurchaseId: joinedPurchaseId[0],
      isAuthed: authStore.isAuthenticated,
      emailIsPrivate: profileStore?.data?.emailIsPrivate,
      userContext,
      activeEntity,
      giftAmount:
        uiStore.savedUiState?.giftAmount > 0
          ? uiStore.savedUiState.giftAmount
          : null,
    });

    useEffect(() => {
      setShowCannotDonate(
        activeEntity.entityType === 'CHARITY' && !activeEntity.canDonate,
      );
    }, [activeEntity]);

    const handleAmountUpdate = async () => {
      await authedOrUnauthedDonationStore.getPaymentRequest();
      setPaymentRequest(authedOrUnauthedDonationStore.paymentRequest);
    };

    let isLoading =
      authedOrUnauthedDonationStore.checkoutLoading ||
      !authedOrUnauthedDonationStore.checkoutDetails ||
      waitingForStripeLoad ||
      waitingForPlaidLoad;

    const checkoutTitleEntity =
      checkoutDetails?.event?.name ||
      checkoutDetails?.charities?.[0]?.name ||
      'Give';

    let content = (
      <div className="donation-checkout flex-column">
        <LoginModal
          isOpen={showLoginModal}
          onToggleClose={() => setShowLoginModal(false)}
          defaultTab={1}
        />
        {showCannotDonate && (
          <ErrorModal
            isOpen
            errorText="Cannot donate as charity, please change your user from dropdown"
            buttonText="ok"
            onToggleClose={() => setShowCannotDonate(false)}
          />
        )}

        {isAuthenticated &&
          authedOrUnauthedDonationStore.showConfirmationModal && (
            <CheckoutConfirmationModal
              currentCheckout={currentCheckout}
              isSubmitting={authedOrUnauthedDonationStore.awaitCompleteCheckout}
              isOpen
              onConfirm={() => {
                events.confirmCardCheckout();
                authedOrUnauthedDonationStore
                  .completeCheckout()
                  .then((checkoutComplete) => {
                    if (checkoutComplete === true) {
                      navigate('/checkout/success');
                    }
                  });
              }}
              onToggleClose={
                authedOrUnauthedDonationStore.cancelCheckoutConfirmation
              }
              isAuthenticated={isAuthenticated}
            />
          )}

        {!isAuthenticated &&
          authedOrUnauthedDonationStore.showConfirmationModal && (
            <CheckoutConfirmationModal
              currentCheckout={currentCheckout}
              isAuthenticated={isAuthenticated}
              isSubmitting={authedOrUnauthedDonationStore.awaitCompleteCheckout}
              isOpen
              onConfirm={() => {
                events.confirmCardCheckout();
                authedOrUnauthedDonationStore
                  .completeCheckout()
                  .then((checkoutComplete) => {
                    if (checkoutComplete === true) {
                      navigate('/checkout/success');
                    }
                  });
              }}
              onToggleClose={
                authedOrUnauthedDonationStore.cancelCheckoutConfirmation
              }
            />
          )}

        {authedOrUnauthedDonationStore.showErrorModal && (
          <ErrorModal
            isOpen
            errorText={authedOrUnauthedDonationStore.errorModalText}
            buttonText={authedOrUnauthedDonationStore.errorModalButtonText}
            onToggleClose={authedOrUnauthedDonationStore.onErrorModalClose}
          />
        )}

        <div id="testId1" className="column-container flex-align-center">
          <div className="form-container">
            <h1>Give to: {checkoutTitleEntity}</h1>

            <ChooseAmountSection
              minimumDonationAmount={minimumDonationAmount}
              currentCheckout={currentCheckout}
              checkoutDetails={checkoutDetails}
              authedOrUnauthedDonationStore={authedOrUnauthedDonationStore}
              isAuthenticated={isAuthenticated}
              checkoutTitleEntity={checkoutTitleEntity}
              location={location}
              onAmountUpdate={handleAmountUpdate}
              paymentRequest={paymentRequest}
            />

            <PaymentSection
              isAuthenticated={isAuthenticated}
              authedOrUnauthedDonationStore={authedOrUnauthedDonationStore}
              currentCheckout={currentCheckout}
              setShowLoginModal={setShowLoginModal}
              minimumDonationAmount={minimumDonationAmount}
              stripe={stripe}
              elements={elements}
              activeEntity={activeEntity}
              uiStore={uiStore}
              paymentRequest={paymentRequest}
            />
          </div>
        </div>
      </div>
    );

    if (showCannotDonate) {
      isLoading = false;
      content = (
        <div className="flex-column flex-center flex-expand">
          <div className="text-bold">
            Cannot donate as charity. Please select a different user from the
            dropdown
          </div>
        </div>
      );
    }

    if (authedOrUnauthedDonationStore.checkoutDetailsFail) {
      isLoading = false;
      content = (
        <div className="flex-column flex-center flex-expand">
          <div className="text-bold">Couldn't get checkout Details</div>
          <Button
            style={{ marginTop: '2rem' }}
            className="is-medium"
            onClick={() =>
              authedOrUnauthedDonationStore.getCheckoutDetails({
                eventId,
                charityIds,
              })
            }
          >
            Try Again
          </Button>
        </div>
      );
    }

    useEffect(() => {
      setProps({
        size: 'half',
      });
    }, []);

    if (isLoading) {
      return <CauzeSpinner fullscreen />;
    }

    return content;
  },
);

const DonationCheckoutView = ({ authStore, ...props }) => {
  return (
    <ElementsWrapper
      elementOptions={{
        setupFutureUsage: authStore.isAuthenticated ? 'on_session' : undefined,
      }}
    >
      <DonationCheckoutViewContent authStore={authStore} {...props} />
    </ElementsWrapper>
  );
};

export default withUserContext(
  inject(
    'uiStore',
    'profileStore',
    'authStore',
    'donationCheckoutStore',
    'donationUnauthedCheckoutStore',
  )(DonationCheckoutView),
);
