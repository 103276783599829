import React from 'react';
import { observer } from 'mobx-react';

import AmountSelector from '../../../AmountSelector/AmountSelector';
import Currency from '../../../Currency/Currency';

const SelectEventAmounts = ({ store, setValidator }) => {
  const match = store.currentMatch;
  setValidator(() => {
    if (match.maxPerGiver === 0 || match.maxTotal === 0) {
      return 'Please select a maximum per giver and a maxiumum total match to proceed';
    } else {
      return '';
    }
  });

  return (
    <div className="modal-match-height">
      {store.isEditingMatchAdmin && (
        <div className="option-group">
          {/* <p style={{ marginBottom: '1rem' }}>
            <input
              type="checkbox"
              checked={match.matchType === 'EVENT'}
              onChange={e =>
                store.updateMatch({
                  matchType: match.matchType === 'EVENT' ? 'COMPANY' : 'EVENT',
                })
              }
            />
            &nbsp; Enable match for non-employees
          </p> */}
          <p className="match-title">Maximum Total Amount</p>
          <AmountSelector
            amounts={[50000, 100000, 500000, 1000000]}
            onAmountUpdate={(matchTotal) => store.updateMatch({ matchTotal })}
            defaultAmount={match.matchTotal}
          />
          <p
            className="match-title flex-row"
            style={{ marginTop: '1rem', marginRight: '1rem' }}
          >
            Match up to&nbsp;
            <span className="text-bold">
              <Currency amount={match.matchTotal} />
            </span>
            &nbsp;Total
          </p>
        </div>
      )}
      <div className="option-group" style={{ marginTop: '2rem' }}>
        <div className="match-title">
          <p className="option-info" style={{ marginBottom: '0.5rem' }}>
            This is where you can limit the amount of matching per giver. This
            avoids the scenario where one giver uses the whole match amount.
          </p>
        </div>
        <p className="match-title">Maximum Per Giver</p>
        <AmountSelector
          amounts={[5000, 10000, 25000, 50000]}
          onAmountUpdate={(matchLimit) => store.updateMatch({ matchLimit })}
          defaultAmount={match.matchLimit}
        />
        <p
          className="match-title flex-row"
          style={{ marginTop: '1rem', marginRight: '1rem' }}
        >
          Match up to&nbsp;
          <span className="text-bold">
            <Currency amount={match.matchLimit} />
          </span>
          &nbsp;per Giver
        </p>
      </div>
    </div>
  );
};

export default observer(SelectEventAmounts);
