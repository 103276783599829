import React from 'react';
import { navigate } from '@reach/router';

import Avatar from 'components/Avatar/Avatar';

import LinkPreview from 'components/LinkPreview/LinkPreview';

const ViewFeedCommentItem = ({
  onImagePress,
  primaryComment,
  body,
  image,
  imageUrls,
  previewUrl,
  style,
  props,
  onToggleClose,
}) => {
  const commentImage = image || imageUrls;

  const getSource = () => {
    if (props?.project?.avatarUrls?.sm) {
      return props?.project.avatarUrls.sm;
    }
    if (props?.company?.avatarUrls?.sm) {
      return props?.company.avatarUrls.sm;
    }
    if (props?.user?.avatarUrls?.sm) {
      return props?.user.avatarUrls.sm;
    }
    return '';
  };

  const renderName = () => {
    if (props?.project) return props?.project.name;
    if (props?.company) return props?.company.name;
    if (props?.user?.isCurrentEntity) return 'You';
    return props?.user?.name;
  };

  const handleClick = () => {
    onToggleClose(false);
    if (props?.charityId) {
      navigate(`/charity/${props?.charityId}`);
    }
    if (props?.company && !props?.company?.id?.includes('fake')) {
      navigate(`/company/${props?.company.id}`);
    }

    navigate(`/profile/${props?.user.id}`);
  };

  const renderBodyWithLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        const isInternalLink = part.includes(window.location.hostname);
        if (isInternalLink) {
          return (
            <span
              key={index}
              className="text-cauze-dark cursor-pointer hover:underline"
              onClick={() => {
                navigate(new URL(part).pathname);
                onToggleClose(false);
              }}
            >
              {part}
            </span>
          );
        }
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="text-cauze-dark hover:!text-cauze-dark hover:underline cursor-pointer"
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div
      className={`feed-comment-item ${primaryComment ? 'primary' : ''}`}
      style={style}
    >
      <div onClick={handleClick} className="avatar-link cursor-pointer">
        <Avatar
          src={getSource()}
          alt={renderName()}
          size={40}
          className="avatar-image"
        />
      </div>
      <div className="comment-content">
        <div className="comment-header">
          <div onClick={handleClick} className="name-link cursor-pointer">
            {renderName()}
          </div>
        </div>
        {!!body && (
          <div className="comment-body ">{renderBodyWithLinks(body)}</div>
        )}
        {!!previewUrl && (
          <LinkPreview className="link-preview" uri={previewUrl} />
        )}
        {!!commentImage?.lg && (
          <button
            className="comment-image-button"
            onClick={() => onImagePress(commentImage.lg)}
          >
            <img
              src={commentImage.lg}
              alt="Comment attachment"
              className="comment-image !w-full"
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default ViewFeedCommentItem;
