import React from 'react';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import Avatar from 'components/Avatar/Avatar';
import useFeedNotificationStore from 'stores/FeedNotificationsStore';
import useMobile from 'hooks/useMobile';

const EntityActivityItem = ({
  children,
  entity,
  isActive = false,
  onClick,
  hasUnread,
}) => {
  const textClassName = cx(
    'mt-2 font-agenda text-xs leading-none text-center',
    {
      'font-agenda-bold': isActive,
    },
  );

  const avatarClassName = cx('', { '!border-text-light': isActive });

  return (
    <div
      className="entity-activity-item cursor-pointer overflow-hidden flex flex-col items-center relative"
      onClick={onClick}
    >
      {Boolean(hasUnread) && (
        <div
          className={cx(
            'absolute w-[7px] h-[7px] top-0 right-0 my-auto rounded-full',
            {
              'bg-cauze-dark': true,
              'bg-lightgray-b2b': false,
            },
          )}
        />
      )}
      {children ?? (
        <Avatar
          className={avatarClassName}
          noBorder
          avatar={entity.avatar}
          avatarUrls={entity.avatarUrls}
          entityType={entity.entityType}
        />
      )}
      <div className={textClassName}>{entity.name}</div>
    </div>
  );
};

export const EntityActivitySelector = ({
  entities,
  activeKey,
  setActiveKey,
}) => {
  const feedNotificationsStore = useFeedNotificationStore();
  const { isMobile } = useMobile();

  // no reason for show if there are no other entities
  if (!entities?.length || entities.length === 1) return <></>;

  const getEntityKey = (entity) => {
    return `${entity.entityType}-${entity.id}`;
  };

  return (
    <Swiper
      className={cx('activity-event-list w-full', {
        'show-arrows': entities && entities.length > 3,
      })}
      navigation
      modules={[Navigation]}
      spaceBetween={24}
      slidesPerView={isMobile ? 3.5 : 3.9}
    >
      {entities.map((entity) => (
        <SwiperSlide key={entity.id}>
          <EntityActivityItem
            entity={entity}
            isActive={getEntityKey(entity) === activeKey}
            hasUnread={feedNotificationsStore.unreadCount[getEntityKey(entity)]}
            onClick={() => {
              feedNotificationsStore.getNotifications(entity);
              setActiveKey(getEntityKey(entity));
            }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
