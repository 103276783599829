import React, { useEffect, useMemo, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';
import cx from 'classnames';

import withUserContext from 'behaviors/withUserContext';
import LoginModal from 'components/modals/LoginModal/LoginModal';
import { CauzesSection, MobileTabs } from './components';

import useProfileById from 'hooks/useProfileById';
import useDonationLanding from 'hooks/useDonationLanding';
import GiveStartCauze from 'components/Asides/GiveStartCauze';
import { getYourCauzesUrl } from 'util/stringUtils';
import GlobalFeed from 'components/Feed/GlobalFeed';
import FollowingFeed from 'components/Feed/FollowingFeed';
import useDashboardStore from 'stores/DashboardStore';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import useMobile from 'hooks/useMobile';

const DonationLandingView = ({
  donationLandingStore,
  uiStore,
  authStore,
  activeEntity,
  userProfileStore,
  profileStore,
}) => {
  useProfileById({ id: activeEntity?.id, isLanding: true });
  const { setProps } = useDashboardStore();
  const { isTablet } = useMobile();

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const events = profileStore.cauzes || [];
  const joinedEvents = userProfileStore.joinedEvents.get(activeEntity?.id);

  useDonationLanding();

  useEffect(() => {
    if (donationLandingStore.lastScrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, donationLandingStore.lastScrollPosition);
      }, 1);
    }

    return () => donationLandingStore.updateScrollPosition();
  }, []);

  if (!authStore.isAuthenticated && donationLandingStore.giftRedeemed) {
    uiStore.showNotification({
      body: 'This gift has already been donated.',
      type: 'ERROR',
      onDismiss: () => {},
      autoDismiss: true,
    });
  }
  const isEmpty = useMemo(() => {
    return !events?.length && !joinedEvents?.length;
  }, [events, joinedEvents]);

  if (!authStore.isAuthenticated && donationLandingStore.giftRedeemed) {
    uiStore.showNotification({
      body: 'This gift has already been donated.',
      type: 'ERROR',
      onDismiss: () => {},
      autoDismiss: true,
    });
  }

  const isLoading =
    authStore.isAuthenticated &&
    (profileStore.loading || userProfileStore.joinedEventsLoading);

  useEffect(() => {
    setProps({
      size: authStore.isAuthenticated ? 'full' : 'half',
      title: isEmpty ? 'My Dashboard' : '',
      noPadding: isTablet,
      asideChildren: authStore.isAuthenticated && !isLoading && (
        <div
          className={cx('lg:block', {
            hidden: tabIndex === 1,
            'pb-4': isTablet,
          })}
        >
          <GiveStartCauze />
        </div>
      ),
    });
  }, [authStore.isAuthenticated, isEmpty, tabIndex, isLoading, isTablet]);

  if (isLoading) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div className="donation-landing">
      <LoginModal
        isOpen={showLoginModal}
        onToggleClose={() => setShowLoginModal(false)}
      />
      <div>
        {isEmpty && (
          <div className="mx-4 lg:mx-0 p-10 flex flex-col items-center text-center gap-y-6 border border-solid border-lightgray-f1f rounded-[10px] h-auto mb-10">
            <h2 className="font-agenda-bold text-[28px] leading-none">
              Join others and track your influence here
            </h2>
            <p className="font-agenda">
              Giving together is how we all make a difference. When inspired by
              others, join them and pass it on.
            </p>
            <button
              className="cz-btn-black uppercase h-12"
              onClick={() => navigate('/discover')}
            >
              discover cauzes
            </button>
          </div>
        )}
        <div
          className={cx('lg:hidden pt-4', {
            '!mb-4': tabIndex === 1,
            '!mb-8': tabIndex === 0,
            block: authStore.isAuthenticated,
            hidden: !authStore.isAuthenticated,
          })}
        >
          <MobileTabs tabIndex={tabIndex} setTabIndex={setTabIndex} />
        </div>
        <div
          className={cx('lg:flex flex-col gap-y-10 mb-0 lg:mb-10', {
            hidden: tabIndex === 1,
            'hidden lg:hidden': !authStore.isAuthenticated,
            'px-4 pb-10': isTablet,
          })}
        >
          <CauzesSection
            title="Your Cauzes"
            events={events}
            loading={userProfileStore.eventsLoading}
            viewAllHref={getYourCauzesUrl(
              activeEntity?.id,
              activeEntity?.entityType,
            )}
          />
          <CauzesSection
            title="Cauzes You've Joined"
            events={joinedEvents}
            loading={userProfileStore.joinedEventsLoading}
            viewAllHref={getYourCauzesUrl(
              activeEntity?.id,
              activeEntity?.entityType,
            )}
          />
        </div>
        <div
          className={cx('lg:block', {
            hidden: tabIndex === 0 && authStore.isAuthenticated,
          })}
        >
          {!authStore.isAuthenticated ? (
            <GlobalFeed
              activeEntity={activeEntity}
              setShowLoginModal={setShowLoginModal}
              title="Global Feed"
            />
          ) : (
            <FollowingFeed
              activeEntity={activeEntity}
              setShowLoginModal={setShowLoginModal}
              title="People You Follow"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserContext(
  inject(
    'donationLandingStore',
    'searchStore',
    'uiStore',
    'authStore',
    'profileStore',
    'userProfileStore',
  )(observer(DonationLandingView)),
);
