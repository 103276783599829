import React from 'react';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import useKeyboardEvent from '../../../hooks/useKeyboardEvent.js';
import classnames from 'classnames';

const TermsModal = ({ onToggleClose, action, isOpen }) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  return (
    <div
      className={classnames('modal confirm-modal', {
        'is-active': isOpen,
      })}
    >
      <div
        className="modal-background"
        onClick={() => {
          onToggleClose(false);
        }}
      ></div>
      <div className="modal-card ">
        <header className="modal-card-head">
          <p className="modal-card-title">
            Terms and Conditions of Your Donation
          </p>
        </header>
        <section className="modal-card-body">
          <p className="modal-card-body-message">
            You are donating to Cauze Charitable Fund; a nonprofit, donor
            advised fund, which will distribute your donation to the charity
            that you indicated. The charity you recommend typically will receive
            the funds within 15-45 days of your original donation. It is rare,
            but if Cauze can't fund your chosen charity, it will ask you to
            recommend another. If Cauze can’t reach you, they will send the
            funds to a similar charity and keep you updated. The Cauze
            Charitable Fund EIN is 45-4602256. For any donation made to a
            nonprofit that is not based in the USA, 5% will be withheld from the
            final grant to cover the additional costs incurred by Cauze.
          </p>
        </section>
        <footer className="modal-card-foot">
          <CauzeButton
            className="button is-primary is-small"
            onClick={() => {
              action && action();
              onToggleClose(false);
            }}
          >
            Close
          </CauzeButton>
        </footer>
      </div>
    </div>
  );
};

export default TermsModal;
