import gql from 'graphql-tag';

const eventQuery = gql`
  query EventQuery($purchase_id: ID, $id: ID, $user_context: UserContext) {
    event(purchaseId: $purchase_id, id: $id, userContext: $user_context) {
      id
      name
      purchaseId
      description
      current
      startDate
      endDate
      eventType
      goal
      isHost
      isSelfFollowing
      previewUrl
      eventState
      externalImageUrl
      image {
        sm
        md
        lg
        xl
        full
      }
      shareLink
      charities {
        ein
        type
        id
        name
        description
        street
        state
        city
        hero {
          full
        }
        avatar {
          sm
          md
          lg
        }
      }
      matchSummaries {
        id
        active
        company {
          name
          subtitle
          avatar {
            sm
            md
          }
        }
        matchAdmin {
          id
          isSelf
          isSelfFollowing
          entityType
          name
          avatar {
            sm
            md
          }
        }
        description
        startDate
        endDate
        matchLimit
        matchTotal
        totalRaised
        totalRemaining
        userMatchLimit
        multiplier
        matchType
        shareLink
        matchSponsors {
          name
          id
          isSelfFollowing
          isSelf
          subtitle
          matchTotal
          entityType
          avatar {
            sm
            md
          }
        }
      }
      sponsors {
        id
        name
        avatar {
          sm
          md
        }
        entityType
      }
      purchases {
        insertedAt
        id
        donationAmount
        user {
          avatar {
            sm
            md
          }
          firstName
          lastName
          name
          id
          isSelfFollowing
        }
        company {
          avatar {
            sm
            md
          }
          name
          id
          isSelfFollowing
        }
        donorProject {
          avatar {
            sm
            md
          }
          name
          id
          isSelfFollowing
        }
      }
    }
  }
`;

const eventQueryApi2 = gql`
  query EventQuery($purchaseId: Int, $eventId: Int) {
    event(purchaseId: $purchaseId, eventId: $eventId) {
      id
      config {
        donationAmountPresets
        minimumDonation
        template
      }
      name
      joinPurchaseId
      description
      purchaserCount
      current
      giverCount
      startDate
      endDate
      eventType
      goal
      currentEntityIsHost
      currentEntityIsFollowing
      currentEntityHasPinned
      previewUrl
      eventState
      externalImageUrl
      image {
        sm
        md
        lg
        xl
        full
      }
      shareLink
      charities {
        ein
        id
        name
        description
        street
        state
        city
        heroUrls {
          full
          md
        }
        avatarUrls {
          sm
          md
          lg
        }
      }
      matches {
        id
        active
        company {
          name
          subtitle
          avatarUrls {
            sm
            md
          }
        }
        matchAdmin {
          id
          isCurrentEntity
          currentEntityIsFollowing
          type
          name
          avatarUrls {
            sm
            md
          }
        }
        description
        startDate
        endDate
        eventId
        matchLimit
        matchTotal
        totalRaised
        totalRemaining
        currentEntityRemaining
        multiplier
        matchType
        shareLink
        matchSponsors {
          name
          id
          currentEntityIsFollowing
          isCurrentEntity
          subtitle
          matchTotal
          remaining
          type
          avatarUrls {
            sm
            md
          }
        }
      }
      hosts {
        id
        name
        user {
          bio
        }
        company {
          description
        }
        project {
          description
        }
        avatarUrls {
          sm
          md
        }
        type
      }
      topPurchasers {
        insertedAt
        id
        actor {
          avatarUrls {
            md
          }
        }
        donationAmount
        user {
          avatarUrls {
            sm
            md
          }
          firstName
          lastName
          name
          id
          currentEntityIsFollowing
          isActive
          unclaimed
        }
        company {
          avatarUrls {
            sm
            md
          }
          name
          id
          currentEntityIsFollowing
        }
        donorProject {
          avatarUrls {
            sm
            md
          }
          name
          id
          currentEntityIsFollowing
        }
      }
    }
  }
`;

const eventPurchasersQuery = gql`
  query EventPurchasesQuery($id: Int!, $page: Int!, $pageSize: Int!) {
    eventPurchasers(eventId: $id, page: $page, pageSize: $pageSize) {
      id
      name
      isSelfFollowing
      user {
        avatarUrls {
          md
          sm
        }
        firstName
        lastName
        id
        username
        isActive
        unclaimed
      }
      company {
        active
        avatarUrls {
          sm
          md
        }
        name
        id
      }
    }
  }
`;

const eventPurchaserQuery = gql`
  query EventPurchaseQuery($purchaseId: Int!) {
    eventPurchaser(purchaseId: $purchaseId) {
      id
      name
      isSelfFollowing
      user {
        avatarUrls {
          md
          sm
        }
        firstName
        lastName
        id
        username
        isActive
        unclaimed
      }
      company {
        active
        avatarUrls {
          sm
          md
        }
        name
        id
      }
    }
  }
`;

const eventFeedQuery = gql`
  query EventFeedQuery($purchase_id: ID, $id: ID) {
    eventFeed(purchaseId: $purchase_id, id: $id, page: 1, pageSize: 25) {
      total
      items {
        actors {
          id
          avatar {
            sm
            md
          }
          name
          body
          previewUrl
          image {
            md
            full
            __typename
          }
          type
        }
        id
        hasLiked
        donationAmount
        commentsCount
        hasCommented
        likes
        date
        subjects {
          id
          name
          type
          avatar {
            sm
            md
            __typename
          }
        }
        type
      }
    }
  }
`;

const createCommentMutation = gql`
  mutation createCommentMutation(
    $body: String
    $event_id: ID
    $image_id: ID
    $preview_url: String
    $user_context: UserContext
  ) {
    createComment(
      body: $body
      eventId: $event_id
      imageId: $image_id
      previewUrl: $preview_url
      userContext: $user_context
    ) {
      id
    }
  }
`;

const followEventMutation = gql`
  mutation followEvent($event_id: ID, $user_context: UserContext) {
    followEvent(eventId: $event_id, userContext: $user_context) {
      id
    }
  }
`;

const unfollowEventMutation = gql`
  mutation unfollowEvent($event_id: ID, $user_context: UserContext) {
    unfollowEvent(eventId: $event_id, userContext: $user_context) {
      id
    }
  }
`;

const companyEventsQuery = gql`
  query companyEventQuery(
    $companyId: Int!
    $limit: Int = 12
    $pinnedOnly: Boolean = false
  ) {
    companyEvents(
      companyId: $companyId
      limit: $limit
      pinnedOnly: $pinnedOnly
    ) {
      name
      id
      giverCount
      image {
        md
      }
      hosts {
        name
        avatarUrls {
          md
        }
      }
      topPurchasers {
        actor {
          name
          avatarUrls {
            md
            sm
          }
        }
      }
    }
  }
`;

const userEventsQuery = gql`
  query userEventsQuery(
    $userId: Int!
    $limit: Int = 12
    $pinnedOnly: Boolean = false
  ) {
    userEvents(userId: $userId, limit: $limit, pinnedOnly: $pinnedOnly) {
      name
      id
      giverCount
      currentEntityHasPinned
      previewUrl
      current
      image {
        md
      }
      hosts {
        name
        avatarUrls {
          md
        }
      }
      topPurchasers {
        actor {
          name
          avatarUrls {
            md
            sm
          }
        }
      }
    }
  }
`;

const projectEventsQuery = gql`
  query projectEventsQuery(
    $projectId: Int!
    $limit: Int = 12
    $pinnedOnly: Boolean = false
  ) {
    projectEvents(
      projectId: $projectId
      limit: $limit
      pinnedOnly: $pinnedOnly
    ) {
      name
      id
      giverCount
      image {
        md
      }
      hosts {
        name
        avatarUrls {
          md
        }
      }
      topPurchasers {
        actor {
          name
          avatarUrls {
            md
            sm
          }
        }
      }
    }
  }
`;

const pinEventMutation = gql`
  mutation pinEventMutation($eventId: Int!, $pinned: Boolean) {
    pinProfileEvent(eventId: $eventId, toggle: $pinned) {
      pinned
    }
  }
`;

export {
  eventQuery,
  eventQueryApi2,
  eventPurchasersQuery,
  eventPurchaserQuery,
  eventFeedQuery,
  createCommentMutation,
  followEventMutation,
  unfollowEventMutation,
  companyEventsQuery,
  userEventsQuery,
  projectEventsQuery,
  pinEventMutation,
};
