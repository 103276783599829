import React from 'react';
import CauzeTabButton from 'components/CauzeTabs/CauzeTabButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const CauzeTabHeader = ({
  leftButtonText,
  leftButtonOnClick,
  leftButtonActive,
  rightButtonText,
  rightButtonOnClick,
  rightButtonActive,
  subHeaderComponent,
  subHeaderComponentIndex,
  onToggleClose,
}) => {
  const isSubHeaderComponentRendered = () => {
    const isRenderedOneIndexOnly =
      subHeaderComponentIndex === 0 || subHeaderComponentIndex === 1;

    if (!subHeaderComponent) {
      return false;
    } else if (!isRenderedOneIndexOnly) {
      return true; // If no index is provided, render on both indexes
    } else {
      if (subHeaderComponentIndex === 0 && leftButtonActive) {
        return true;
      }
      if (subHeaderComponentIndex === 1 && rightButtonActive) {
        return true;
      }
      return false;
    }
  };

  return (
    <div
      className="header-container "
      style={{ height: isSubHeaderComponentRendered() ? '135px' : '55px' }}
    >
      <div className="header-nav-container">
        <div className="header-button-container">
          <div className="button-row">
            <div
              className="hidden max-md:flex items-center justify-center cursor-pointer mr-3 "
              onClick={() => {
                onToggleClose(false);
              }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="text-xl text-gray-949"
              />
            </div>
            <CauzeTabButton
              className="rounded-l-lg border shadow-sm"
              isActive={leftButtonActive}
              onClick={leftButtonOnClick}
            >
              {leftButtonText}
            </CauzeTabButton>
            <CauzeTabButton
              className="rounded-r-lg border shadow-sm"
              isActive={rightButtonActive}
              onClick={rightButtonOnClick}
            >
              {rightButtonText}
            </CauzeTabButton>
          </div>
        </div>
      </div>
      {isSubHeaderComponentRendered() && (
        <div className="sub-header-component-container">
          {subHeaderComponent}
        </div>
      )}
    </div>
  );
};

export default CauzeTabHeader;
