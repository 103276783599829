import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

const DateWithNullText = ({ value: { date, nullText } }) => (
  <span
    className={classnames({
      'col-date-highlight': !date,
    })}
  >
    {date ? moment(date).format('M/D/Y') : nullText}
  </span>
);

export { DateWithNullText };
