import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { navigate } from '@reach/router';

import Currency from 'components/Currency/Currency';
import { getSubject, getLegacyCardNav, getLegacyVerbByType } from './util';
import Avatar from 'components/Avatar/Avatar';
import { getEntityUrl } from 'util/navHelpers';

const FeedItemWrapper = ({
  item,
  children,
  onNotificationPress,
  onToggleOpen,
}) => {
  const entity = item.actors?.[0];
  const entityUrl = getEntityUrl({
    id: entity.id,
    entityType: entity.entityType || entity.__typename,
  });

  const daysAgo = moment().diff(moment(item.date), 'days');
  return (
    <div
      className="py-2.5 flex gap-x-[15px] font-agenda text-[13px] leading-[1.05] cursor-pointer hover:bg-lightgray-f7f active:bg-lightgray-f1f px-5"
      onClick={(e) => {
        onNotificationPress?.(e);
        onToggleOpen?.();
      }}
    >
      <div className="flex">
        <div
          className={cx('w-[7px] h-[7px] my-auto rounded-full', {
            'bg-cauze-dark': !item.read,
            'bg-lightgray-b2b': item.read,
          })}
        />
      </div>
      <Avatar
        noBorder
        avatar={item.actors?.[0]?.avatar}
        className="my-auto"
        onClick={() => {
          if (entityUrl) {
            navigate(entityUrl);
            onToggleOpen();
          }
        }}
      />
      <div>
        {children}
        <div className="pt-1 h-3 text-[0.75rem] leading-[0.75rem] text-text-light">
          {daysAgo === 0 ? 'today' : `${daysAgo} days ago`}
        </div>
      </div>
    </div>
  );
};

const Mention = ({ item, navigation, markRead, onToggleOpen }) => (
  <FeedItemWrapper
    item={item}
    onToggleOpen={onToggleOpen}
    navigation={navigation}
    onNotificationPress={() => {
      markRead();

      if (item.subjects[0].subjectType === 'EVENT') {
        navigate(`/event/${item.subjects[0].id}`);
      }
    }}
  >
    <div>
      <span className="capitalize font-agenda-bold">
        {item.actors[0]?.name}
      </span>{' '}
      mentioned you in a comment
    </div>
  </FeedItemWrapper>
);

const EventWithMatchCreated = ({
  item,
  navigation,
  markRead,
  onToggleOpen,
}) => (
  <FeedItemWrapper
    item={item}
    onToggleOpen={onToggleOpen}
    navigation={navigation}
    onNotificationPress={() => {
      markRead();
      const eventId = item.subjects[0].id;
      navigate(`/event/${eventId}`);
    }}
  >
    <div>
      <span className="capitalize font-agenda-bold">
        {item.actors[0]?.name}
      </span>{' '}
      supported
      <span className="capitalize font-agenda-bold">
        {item.subjects[0]?.name}
      </span>{' '}
      and is offering a match. Check it out
    </div>
  </FeedItemWrapper>
);

const EventCreated = ({ item, navigation, markRead, onToggleOpen }) => (
  <FeedItemWrapper
    item={item}
    onToggleOpen={onToggleOpen}
    navigation={navigation}
    onNotificationPress={() => {
      markRead();

      const foundEvent = item.subjects.find(
        (subject) => subject.subjectType === 'EVENT',
      );

      const eventId = foundEvent?.id || item.subjects[0].id;

      if (eventId) {
        navigate(`/event/${eventId}`);
      }
    }}
  >
    <div>
      <span className="capitalize font-agenda-bold">
        {item.actors[0]?.name}
      </span>{' '}
      supported
      <span className="capitalize font-agenda-bold">
        {item.subjects[0]?.name}.
      </span>{' '}
      Check it out
    </div>
  </FeedItemWrapper>
);

const MatchSponsorAdded = ({ item, navigation, markRead, onToggleOpen }) => (
  <FeedItemWrapper
    item={item}
    onToggleOpen={onToggleOpen}
    navigation={navigation}
    onNotificationPress={() => {
      markRead();

      const eventId = item.subjects[0].id;

      navigate(`/event/${eventId}`);
    }}
  >
    <div>
      <span className="capitalize font-agenda-bold">
        {item.actors[0]?.name}
      </span>{' '}
      has offered a match to
      <span className="capitalize font-agenda-bold">
        {item.subjects[0]?.name}
      </span>
    </div>
  </FeedItemWrapper>
);

const JoinEvent = ({ item, navigation, markRead, onToggleOpen }) => {
  const foundEvent = item.subjects.find(
    (subject) => subject.subjectType === 'EVENT',
  );

  return (
    <FeedItemWrapper
      item={item}
      navigation={navigation}
      onToggleOpen={onToggleOpen}
      onNotificationPress={() => {
        markRead();

        if (!foundEvent) return;

        navigate(`/event/${foundEvent.id}`);
      }}
    >
      <div>
        <span className="capitalize font-agenda-bold">
          {item.actors[0]?.name}
        </span>{' '}
        has joined you in supporting{' '}
        <span className="capitalize font-agenda-bold">
          {item.subjects[0]?.name}
        </span>
      </div>
    </FeedItemWrapper>
  );
};

const RecurringDeposit = ({ item, navigation, markRead, onToggleOpen }) => (
  <FeedItemWrapper
    item={item}
    onToggleOpen={onToggleOpen}
    navigation={navigation}
    onNotificationPress={() => {
      markRead();

      navigate('/wallet');
    }}
  >
    <div>
      Your monthly funds were added. Thank you for your monthly commitment to
      giving!
    </div>
  </FeedItemWrapper>
);

const DonationReferral = ({ item, navigation, markRead, onToggleOpen }) => (
  <FeedItemWrapper
    item={item}
    navigation={navigation}
    onToggleOpen={onToggleOpen}
    onNotificationPress={() => {
      markRead();

      const foundEvent = item.subjects.find(
        (subject) => subject.subjectType === 'EVENT',
      );

      if (!foundEvent) return;

      navigate(`/event/${foundEvent.id}`);
    }}
  >
    <div>
      <span className="capitalize font-agenda-bold">
        {item.actors[0]?.name}
      </span>{' '}
      joined you. Your Total Good went up!
    </div>
  </FeedItemWrapper>
);

const DonationReward = ({ item, navigation, markRead, onToggleOpen }) => (
  <FeedItemWrapper
    item={item}
    navigation={navigation}
    onToggleOpen={onToggleOpen}
    onNotificationPress={() => {
      markRead();

      navigate('/donate/search');
    }}
  >
    <div>
      <span>You</span> have received a
      {item.verbMeta?.amount && (
        <span>
          {' '}
          <Currency amount={item.verbMeta.amount} />
        </span>
      )}{' '}
      reward! Go find a nonprofit you love and support them with your new
      balance.
    </div>
  </FeedItemWrapper>
);

const GiftRefund = ({ item, navigation, markRead, onToggleOpen }) => (
  <FeedItemWrapper
    item={item}
    navigation={navigation}
    onToggleOpen={onToggleOpen}
    onNotificationPress={() => {
      markRead();

      navigate('/wallet');
    }}
  >
    <div>
      <span>You</span> were refunded
      {item.verbMeta?.amount && (
        <span>
          {' '}
          <Currency amount={item.verbMeta.amount} />
        </span>
      )}{' '}
      {item.verbMeta?.recipients && (
        <span>
          {item.verbMeta.recipients.length === 1 &&
            `from an unclaimed gift to ${item.verbMeta.recipients[0]}.`}
          {item.verbMeta.recipients.length > 1 && `from unclaimed gifts to `}
          {item.verbMeta.recipients.length > 1 &&
            item.verbMeta.recipients.map((recipient, index) => (
              <span key={`recipient-${index}`}>
                {item.verbMeta.recipients.length === index + 1 && 'and '}
                {recipient}
                {item.verbMeta.recipients.length !== index + 1 ? ', ' : '.'}
              </span>
            ))}
        </span>
      )}
    </div>
  </FeedItemWrapper>
);

const AdminGift = ({ item, navigation, markRead, onToggleOpen }) => (
  <FeedItemWrapper
    item={item}
    navigation={navigation}
    onToggleOpen={onToggleOpen}
    onNotificationPress={() => {
      markRead();

      navigate('/wallet');
    }}
  >
    <div>
      <span>You</span> were gifted <Currency amount={item.verbMeta.amount} />{' '}
      from Cauze
    </div>
  </FeedItemWrapper>
);

const Deposit = ({ item, navigation, markRead, onToggleOpen }) => (
  <FeedItemWrapper
    item={item}
    navigation={navigation}
    onToggleOpen={onToggleOpen}
    onNotificationPress={() => {
      markRead();

      navigate('/wallet');
    }}
  >
    <div>
      <span>You</span> deposited <Currency amount={item.verbMeta.amount} />
    </div>
  </FeedItemWrapper>
);

const Payroll = ({ item, navigation, markRead, onToggleOpen }) => (
  <FeedItemWrapper
    item={item}
    onToggleOpen={onToggleOpen}
    navigation={navigation}
    onNotificationPress={() => {
      markRead();

      navigate('/wallet');
    }}
  >
    <div>
      <div>Your payroll deduction has been added to your Cauze account</div>
    </div>
  </FeedItemWrapper>
);

const CauzeLike = ({ item, navigation, markRead, onToggleOpen }) => (
  <FeedItemWrapper
    item={item}
    onToggleOpen={onToggleOpen}
    navigation={navigation}
    onNotificationPress={() => {
      markRead();

      const entity = item.actors?.[0];
      const entityUrl = getEntityUrl({
        id: entity.id,
        entityType: entity.entityType || entity.__typename,
      });

      navigate(entityUrl);
    }}
  >
    <div>
      <span className="capitalize font-agenda-bold">
        {item.actors[0]?.name}
      </span>{' '}
      liked your donation to
      <span> {getSubject(item.subjects, item.type)}</span>
    </div>
  </FeedItemWrapper>
);

const CommentPost = ({ item, navigation, markRead, onToggleOpen, uiStore }) => (
  <FeedItemWrapper
    item={item}
    onToggleOpen={onToggleOpen}
    navigation={navigation}
    onNotificationPress={() => {
      markRead();

      uiStore.showModal('COMMENT', {
        commentProps: {
          purchaseId: item.subjects[0].id,
        },
      });
    }}
  >
    <div>
      <span className="capitalize font-agenda-bold">
        {item.actors[0]?.name}
      </span>
      {' posted an update.'}
    </div>
  </FeedItemWrapper>
);

const LegacyNotifications = ({
  item,
  navigation,
  markRead,
  onToggleOpen,
  uiStore,
}) => {
  return (
    <FeedItemWrapper
      item={item}
      onToggleOpen={onToggleOpen}
      navigation={navigation}
      onNotificationPress={() => {
        if (item.type === 'CAUZE_COMMENT') {
          markRead();

          const purchase = item.subjects.find(
            (s) => s.subjectType === 'PURCHASE',
          );

          if (!purchase) return;

          uiStore.openModal('COMMENT', {
            commentProps: {
              purchaseId: purchase.id,
            },
          });
        } else {
          getLegacyCardNav(item, navigation, markRead);
        }
      }}
    >
      <div>
        <span className="capitalize font-agenda-bold">
          {item.actors[0]?.name}
        </span>{' '}
        {`${getLegacyVerbByType(item.type)} `}
        {item.verbMeta?.amount && (
          <span>
            <Currency amount={item.verbMeta.amount} />{' '}
          </span>
        )}
        <span>{getSubject(item.subjects, item.type)}</span>
      </div>
    </FeedItemWrapper>
  );
};

export {
  EventWithMatchCreated,
  MatchSponsorAdded,
  RecurringDeposit,
  Mention,
  EventCreated,
  JoinEvent,
  LegacyNotifications,
  DonationReferral,
  DonationReward,
  GiftRefund,
  AdminGift,
  Deposit,
  CauzeLike,
  Payroll,
  CommentPost,
};
