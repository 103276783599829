import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import useKeyboardEvent from '../../hooks/useKeyboardEvent.js';

// refer to https://bulma.io/documentation/components/dropdown/

const ActionMenu = ({
  actions,
  _style,
  isActive,
  onToggleOpen,
  onToggleClose,
}) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose();
  });

  return (
    <div className={classnames('dropdown is-right', { 'is-active': isActive })}>
      <div className="dropdown-trigger">
        <button
          onClick={onToggleOpen}
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <span className="icon is-small">
            <FontAwesomeIcon icon={faEllipsisV} />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {actions.map((action, index) => (
            <a
              key={`${action.name}-${index}`}
              onClick={(ev) => {
                ev.preventDefault();
                action.action();
              }}
              className="dropdown-item"
            >
              {action.name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActionMenu;
