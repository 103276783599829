import React, { useState } from 'react';
import cx from 'classnames';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useKeyboardEvent from 'hooks/useKeyboardEvent.js';

import CauzeButton from 'components/CauzeButton/CauzeButton';

const BlockModal = ({
  onToggleClose,
  isOpen,
  userReportsStore,
  reportProps,
  uiStore,
  blockUsername,
  unblock = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  useKeyboardEvent('Escape', () => {
    onToggleClose();
  });

  const handleBlock = async () => {
    console.log('handleBlock', reportProps);
    setIsSubmitting(true);
    try {
      const action = unblock
        ? userReportsStore.unblock({
            ...reportProps,
          })
        : userReportsStore.block({
            ...reportProps,
          });
      await action;
      uiStore.showNotification({
        body: unblock ? 'User has been unblocked' : 'User has been blocked',
        type: 'SUCCESS',
      });
      onToggleClose();
    } catch (err) {
      uiStore.showNotification({
        body: unblock ? 'Fail to unblock the user' : 'Fail to block the user',
        type: 'ERROR',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className={cx('modal block-modal', {
        'is-active': isOpen,
      })}
    >
      <div className="modal-background" onClick={onToggleClose} />
      <div className="modal-card md:!w-[400px]">
        <header className="confirm-modal-head">
          <div className="modal-card-title">
            <div
              className="close-button flex-row flex-justify-end"
              onClick={onToggleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="modal-card-title-text">
              {unblock ? 'Unblock' : 'Block'} @{blockUsername}?
            </div>
          </div>
        </header>
        <section className="modal-card-body !flex-grow-0">
          <p>
            {unblock
              ? `You will be able to see posts and notifications from @${blockUsername}.`
              : `You will not be able to see posts or notifications from @${blockUsername}.`}
          </p>
        </section>
        <footer className="modal-card-foot gap-x-3">
          <button className="cz-btn-outline brand" onClick={onToggleClose}>
            cancel
          </button>
          <CauzeButton
            className="!bg-cauzeorange"
            onClick={handleBlock}
            isSubmitting={isSubmitting}
          >
            {unblock ? 'Unblock' : 'Block'}
          </CauzeButton>
          {/* <button className="cz-btn-brand" >
          </button> */}
        </footer>
      </div>
    </div>
  );
};

export default BlockModal;
