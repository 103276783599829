import React from 'react';
import cx from 'classnames';

import { navigate } from '@reach/router';
import FeedCardPreview from './FeedCardPreview';

const FeedCardCauze = ({ event, href, hasComment }) => {
  const isVisible = event.previewUrl || event.image?.lg;

  if (!isVisible) {
    return <></>;
  }

  return (
    <a
      href={href}
      className={cx('feed-card-cauze', { 'has-comment': hasComment })}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        navigate(href);
      }}
    >
      {event.previewUrl ? (
        <FeedCardPreview previewUrl={event.previewUrl} />
      ) : (
        <div
          className={cx('feed-card-body', {
            'no-image': !event.image?.lg,
          })}
          style={{
            backgroundImage: event.image?.lg
              ? `url('${event.image?.lg}')`
              : undefined,
          }}
        />
      )}
    </a>
  );
};

export default FeedCardCauze;
