import React from 'react';
import classnames from 'classnames';

const Currency = ({
  amount,
  className = '',
  showCents = false,
  superCents = false,
  showDollarSign = true,
  compact = false, // prop to control compact formatting
}) => {
  const amountNum =
    Math.round((Math.abs(amount) / 100) * Math.pow(10, 2)) / Math.pow(10, 2);

  // Function to format large numbers with 'K', 'M', 'B'
  const formatCompact = (num) => {
    if (num >= 1e9) {
      return `${(num / 1e9).toFixed(2)}B`;
    } else if (num >= 1e6) {
      return `${(num / 1e6).toFixed(2)}M`;
    } else if (num >= 1e3) {
      return `${(num / 1e3).toFixed(2)}K`;
    }
    return num.toFixed(2);
  };

  // Decide how to format based on the 'compact' prop
  const formattedAmount = compact
    ? formatCompact(amountNum)
    : amountNum
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        .slice(0, !showCents || superCents ? -3 : undefined);

  return (
    <span className={classnames(`currency ${className}`)}>
      {amount < 0 && '-'}
      {showDollarSign && '$'}
      {formattedAmount}
      {superCents && !compact && (
        <span>
          <sup>.{amountNum.toFixed(2).slice(-2)}</sup>
        </span>
      )}
    </span>
  );
};

export default Currency;
