import React from 'react';
import cx from 'classnames';

import LargeNumber from 'components/LargeNumber/LargeNumber';

const ProfileMetric = ({ title, value, isCurrency = false, onClick }) => {
  let valueDisplay = value;

  if (isCurrency) {
    valueDisplay = (
      <>
        <LargeNumber isCurrency amount={value} />
      </>
    );
  } else {
    valueDisplay = <LargeNumber amount={value} />;
  }

  return (
    <div
      className={cx('profile-metric', { 'cursor-pointer': Boolean(onClick) })}
      onClick={onClick}
    >
      <div className="profile-metric-value">{valueDisplay}</div>
      <div className="profile-metric-title">{title}</div>
    </div>
  );
};

export default ProfileMetric;
