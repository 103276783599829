import { getEntityUrl } from './navHelpers';
import dompurify from 'dompurify';

const sanitizer = dompurify.sanitize;
const markdownRegexGlobal = /@\[([^[]*)]\(([^(^)]*)\)\(([^(^)]*)\)/g;
const markdownRegex = /@\[([^[]*)]\(([^(^)]*)\)\(([^(^)]*)\)/;

const shorten = (string, maxLength, ellipsis = true) => {
  const showEllipsis = string?.length > maxLength && ellipsis;
  return `${string?.substring(0, maxLength)}${showEllipsis ? '…' : ''}`;
};

const convertMarkdownToFormatted = (text) =>
  text.replace(markdownRegexGlobal, (str) => {
    let [_full, username, id, entityType] = str.match(markdownRegex);
    return `<a href="${getEntityUrl({ entityType, id })}">@${username}</a>`;
  });

const convertSnakeToSpaces = (text) =>
  text
    .split('_')
    .filter((x) => x.length > 0)
    .join(' ');

const getLedgerUrl = (id, entityType) => {
  if (entityType === 'CHARITY') {
    return `/admin/charity/${id}`;
  } else if (entityType === 'COMPANY') {
    return `/admin/company/${id}`;
  } else {
    return `/admin/user/${id}/activity`;
  }
};
const getYourCauzesUrl = (id, entityType) => {
  if (entityType === 'USER') {
    return `/admin/user/${id}/cauzes`;
  } else if (entityType === 'COMPANY') {
    return `/admin/company/${id}/cauzes`;
  } else if (entityType === 'CHARITY') {
    return `/admin/charity/${id}/cauzes`;
  } else if (entityType === 'INFLUENCER') {
    return `/admin/influencer/${id}/cauzes`;
  } else {
    return '/cauzes';
  }
};
const getProfileUrl = (id, entityType) => {
  if (!id || !entityType) {
    return;
  }
  if (entityType === 'COMPANY') {
    return `/company/${id}`;
  } else if (entityType === 'CHARITY') {
    return `/charity/${id}`;
  } else {
    return `/profile/${id}`;
  }
};

const getQueryParam = (search, key) => {
  const params = new URLSearchParams(search);
  return params.get(key);
};
export {
  getProfileUrl,
  getQueryParam,
  getLedgerUrl,
  getYourCauzesUrl,
  shorten,
  convertMarkdownToFormatted,
  convertSnakeToSpaces,
  markdownRegex,
  sanitizer,
};
export const linkify = require('linkifyjs');
