import { useState, useEffect } from 'react';

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(document.body.clientWidth <= 425);
  const [isTablet, setIsTablet] = useState(document.body.clientWidth <= 768);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 425 && !isMobile) {
        setIsMobile(true);
      } else if (window.innerWidth > 425 && isMobile) {
        setIsMobile(false);
      }

      if (window.innerWidth <= 768 && !isTablet) {
        setIsTablet(true);
      } else if (window.innerWidth > 768 && isTablet) {
        setIsTablet(false);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile, isTablet]);

  return {
    isMobile,
    isTablet,
  };
};

export default useMobile;
