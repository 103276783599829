import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { emailValidate, zipValidate } from 'util/formFieldValidators';
import Button from 'components/Button/Button';

import emailIcon from 'assets/images/icons/email/email.svg';
import lockIcon from 'assets/images/icons/lock/lock.svg';
import userIcon from 'assets/images/icons/user/user-dark.svg';
import locationIcon from 'assets/images/icons/location/location-dark.svg';
import useDashboardStore from 'stores/DashboardStore';

const validate = ({ user, _fieldIsValid, setFieldIsValid }) => {
  const fieldValidators = {
    firstName: user.firstName.length !== 0,
    lastName: user.lastName.length !== 0,
    email: emailValidate(user.email),
    zip: zipValidate(user.zip),
    password: user.password.length > 7,
  };

  let userValid =
    typeof Object.keys(fieldValidators).find(
      (key) => fieldValidators[key] === false,
    ) === 'undefined';
  setFieldIsValid(fieldValidators);
  return userValid;
};

const onSignUpClick = async ({ user, authStore }) => {
  await authStore.signup(user);
};

const onLogoutClick = async (authStore) => {
  await authStore.changeAuthentication();
};

const CreateAccountView = ({ authStore, _token }) => {
  const { setProps } = useDashboardStore();
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    zip: '',
    password: '',
    isSubmitting: '',
    email: '',
  });
  const [fieldIsValid, setFieldIsValid] = useState({
    firstName: true,
    lastName: true,
    zip: true,
    password: true,
    isSubmitting: true,
    email: true,
  });

  let userValid =
    typeof Object.keys(fieldIsValid).find(
      (key) => fieldIsValid[key] === false,
    ) === 'undefined';

  useEffect(() => {
    setProps({
      noSidebar: true,
      bodyClassName: 'bg-lightgray-e0e',
      navbarProps: {
        hideLoginPrompt: true,
      },
    });
  }, []);

  return (
    <div className="login-view max-w-96 mx-auto">
      <div className="form-container section flex-expand">
        {authStore.isAuthenticated ? (
          <div>
            <div className="notification">You are currently logged in.</div>
            <div className="form-row flex-center">
              <div className="login-button flex-expand">
                <Button
                  purple
                  className="is-medium is-fullwidth"
                  type="button"
                  onClick={(_e) => onLogoutClick(authStore)}
                >
                  Log Out
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (validate({ user, fieldIsValid, setFieldIsValid })) {
                onSignUpClick({ user, authStore });
              }
            }}
          >
            {authStore.createUserErrors.emailTakenOrInvalid && (
              <div className="notification">
                Invalid email or account already exists.
              </div>
            )}
            <div className="login-title text-title">Create an Account</div>
            <fieldset className="mb-4">
              <legend>Create an Account</legend>
              <div className="form-row">
                <label htmlFor="first-name" className="flex-column flex-center">
                  <img alt="First Name" src={userIcon} />
                </label>
                <input
                  id="first-name"
                  placeholder="First Name"
                  type="text"
                  value={user.firstName}
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      setFieldIsValid({ ...fieldIsValid, firstName: true });
                    }
                    setUser({ ...user, firstName: e.target.value });
                  }}
                  style={
                    !fieldIsValid.firstName ? { backgroundColor: 'pink' } : {}
                  }
                />
              </div>
              <div className="form-row">
                <label htmlFor="last-name" className="flex-column flex-center">
                  <img alt="Last Name" src={userIcon} />
                </label>
                <input
                  id="last-name"
                  placeholder="Last Name"
                  type="text"
                  value={user.lastName}
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      setFieldIsValid({ ...fieldIsValid, lastName: true });
                    }
                    setUser({ ...user, lastName: e.target.value });
                  }}
                  style={
                    !fieldIsValid.lastName ? { backgroundColor: 'pink' } : {}
                  }
                />
              </div>
              <div className="form-row">
                <label htmlFor="email" className="flex-column flex-center">
                  <img alt="email" src={emailIcon} />
                </label>
                <input
                  id="email"
                  placeholder="Email"
                  type="text"
                  value={user.email}
                  onChange={(e) => {
                    if (emailValidate(e.target.value)) {
                      setFieldIsValid({ ...fieldIsValid, email: true });
                    }
                    setUser({ ...user, email: e.target.value });
                  }}
                  style={!fieldIsValid.email ? { backgroundColor: 'pink' } : {}}
                />
              </div>
              <div className="form-row">
                <label htmlFor="zip" className="flex-column flex-center">
                  <img alt="zip" src={locationIcon} />
                </label>
                <input
                  id="zip"
                  placeholder="Zip Code"
                  type="text"
                  value={user.zip}
                  onChange={(e) => {
                    if (zipValidate(e.target.value)) {
                      setFieldIsValid({ ...fieldIsValid, zip: true });
                    }
                    setUser({ ...user, zip: e.target.value });
                  }}
                  style={!fieldIsValid.zip ? { backgroundColor: 'pink' } : {}}
                />
              </div>
              <div className="form-row">
                <label htmlFor="password" className="flex-column flex-center">
                  <img alt="password" src={lockIcon} />
                </label>
                <input
                  id="password"
                  placeholder="Password (at least 8 characters)"
                  type="password"
                  value={user.password}
                  onChange={(e) => {
                    if (e.target.value.length > 7) {
                      setFieldIsValid({ ...fieldIsValid, password: true });
                    }
                    setUser({ ...user, password: e.target.value });
                  }}
                  style={
                    !fieldIsValid.password ? { backgroundColor: 'pink' } : {}
                  }
                />
              </div>
              {!fieldIsValid.password && (
                <div className="form-validation-error">
                  Password must be at least 8 characters
                </div>
              )}
            </fieldset>
            <div className="form-row flex-center">
              <div className="login-button flex-expand">
                <Button
                  purple
                  className="is-medium is-fullwidth"
                  type="submit"
                  disabled={!userValid}
                  isSubmitting={authStore.isLoggingIn}
                >
                  Sign Up
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default inject('authStore')(observer(CreateAccountView));
