import React from 'react';
import classNames from 'classnames';

const CauzeTabButton = ({ isActive, onClick, children, className }) => {
  return (
    <button
      className={classNames('cauze-tab-button', className, {
        'cauze-tab-button--active': isActive,
      })}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default CauzeTabButton;
