import React from 'react';
import { navigate } from '@reach/router';
import Button from 'components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

const AddEmployees = ({
  companyAdminStore,
  pendingCount,
  onAddEmployee,
  companyId,
  isGroup,
}) =>
  pendingCount > 0 ? (
    <div className="dashboard-item pending-invites flex-row">
      <div className="dashboard-icon">
        <FontAwesomeIcon icon={faUserPlus} />
      </div>
      <div className="dashboard-content flex-column flex-expand">
        <div className="dashboard-details flex-column">
          <div className="dashboard-item-title">Pending Invites</div>
          <div className="dashboard-item-body">
            You have {companyAdminStore.pending.length} pending invites.
          </div>
        </div>
        <div className="dashboard-item-actions flex-row">
          <Button
            className="is-small"
            onClick={() => navigate(`/admin/company/${companyId}/pending`)}
          >
            View/Edit Invites
          </Button>
          <Button
            onClick={() =>
              companyAdminStore.resendCompanyInvites({
                ids: companyAdminStore.pending.map((user) => user.tokenId),
                companyId,
              })
            }
            className="is-small"
          >
            Send All
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className="dashboard-item pending-invites flex-row">
      <div className="dashboard-icon">
        <FontAwesomeIcon icon={faUserPlus} />
      </div>
      <div className="dashboard-content">
        <div className="dashboard-details flex-column">
          <div className="dashboard-item-title">
            Add {isGroup ? 'Members' : 'Employees'}
          </div>
          <div className="dashboard-item-body">
            Add {isGroup ? 'members' : 'employees'} to Cauze by entering their
            email addresses
          </div>
        </div>
        <div className="dashboard-item-actions flex-row">
          <Button className="is-small" onClick={onAddEmployee}>
            Add {isGroup ? 'Members' : 'Employees'}
          </Button>
        </div>
      </div>
    </div>
  );

export default AddEmployees;
