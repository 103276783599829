import React from 'react';
import { getIosLink, getAndroidLink } from 'util/userAgent';

import * as events from 'util/events/nav';

import facebookIcon from 'assets/images/icons/social/facebook.svg';
import instagramIcon from 'assets/images/icons/social/instagram.svg';
import twitterIcon from 'assets/images/icons/social/twitter.svg';
import appStoreLogo from 'assets/images/icons/appStores/appStore.svg';
import playStoreLogo from 'assets/images/icons/appStores/googlePlay.svg';
import logo from 'assets/images/brand/cauze-logo-no-background.svg';

const FooterNew = () => (
  <footer className="bg-black flex lg:grid lg:grid-cols-5">
    <div />
    <div className="pt-16 lg:pt-[4.50rem] pb-10 lg:pb-16 font-agenda col-span-3 flex flex-col lg:grid grid-cols-3 w-full">
      <div className="hidden lg:block">
        <div className="font-agenda-bold text-[25px] flex flex-col mb-4">
          <a
            onClick={events.footerHomeLinkPress}
            href="https://www.cauze.com/home"
          >
            Home
          </a>
          <a
            onClick={events.footerEmployersLinkPress}
            href="https://www.cauze.com/employers"
          >
            Employers
          </a>
          <a
            onClick={events.footerNonprofitsLinkPress}
            href="https://www.cauze.com/nonprofits"
          >
            Nonprofits
          </a>
          <a
            onClick={events.footerAboutLinkPress}
            href="https://www.cauze.com/our-story"
          >
            About
          </a>
          <a
            onClick={events.footerHelpLinkPress}
            href="https://www.cauze.com/help"
          >
            Help
          </a>
        </div>
        <div className="text-[10px] text-lightgray-aaa">
          App Store®, the Apple logo, and iPhone® are registered trademarks of
          Apple Inc.
        </div>
        <div className="text-[10px] text-lightgray-aaa">
          Google Play and the Google Play logo are trademarks of Google LLC.
        </div>
      </div>
      <div className="flex-expand flex-center logo-container hidden lg:flex">
        <img src={logo} className="logo" alt="Cauze" />
      </div>
      <div>
        <div className="items-end w-full flex justify-evenly lg:flex-col gap-y-[18px] mb-7 lg:mb-12">
          <div className="h-10">
            <a
              className="block h-10"
              onClick={events.playStoreDownloadPress}
              href={getAndroidLink()}
            >
              <img className="h-10" src={playStoreLogo} />
            </a>
          </div>
          <div className="h-10">
            <a
              className="block h-10"
              onClick={events.appStoreDownloadPress}
              href={getIosLink()}
            >
              <img className="h-10" src={appStoreLogo} />
            </a>
          </div>
        </div>
        <div>
          <div className="flex text-gray-111 justify-center lg:justify-end gap-x-[14px] mb-12 lg:mb-7">
            <a
              onClick={events.footerTwitterPress}
              href="https://twitter.com/cauzeapp/"
            >
              <img className="icon" src={twitterIcon} />
            </a>
            <a
              onClick={events.footerFacebookPress}
              href="https://www.facebook.com/cauzeapp/"
            >
              <img className="icon" src={facebookIcon} />
            </a>
            <a
              onClick={events.footerInstragramPress}
              href="https://www.instagram.com/cauzeapp/"
            >
              <img className="icon" src={instagramIcon} />
            </a>
          </div>
          <div className="flex-expand flex-center logo-container flex lg:hidden">
            <img src={logo} className="logo" alt="Cauze" />
          </div>
          <div className="pt-[10px] px-9 block lg:hidden text-center text-[10px] text-lightgray-aaa">
            App Store®, the Apple logo, and iPhone® are registered trademarks of
            Apple Inc. Google Play and the Google Play logo are trademarks of
            Google LLC.
          </div>
          <div className="text-[13px] hidden lg:flex font-agenda-bold justify-end gap-x-8">
            <a
              target="_blank"
              className="!text-lightgray-aaa"
              onClick={events.footerTermsLinkPress}
              href="https://www.cauze.com/terms-of-service"
            >
              Terms
            </a>
            <a
              target="_blank"
              className="!text-lightgray-aaa"
              onClick={events.footerPrivacyLinkPress}
              href="https://www.cauze.com/privacy-policy"
            >
              Privacy
            </a>
            <a
              target="_blank"
              className="!text-lightgray-aaa"
              onClick={events.footerDonorAgreementPress}
              href="https://www.cauze.com/donor-agreement"
            >
              Donor Agreement
            </a>
          </div>
        </div>
      </div>
    </div>
    <div />
  </footer>
);

export default FooterNew;
