import React, { useState, useRef } from 'react';
import ReactTable from 'react-table';
import { observer, inject } from 'mobx-react';
import { debounce } from 'lodash';
import classnames from 'classnames';
import useKeyboardEvent from '../../hooks/useKeyboardEvent.js';
import columns from './CharitiesTableColumns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faTimes,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';

const CharitiesTable = ({ charitiesStore, onGotoCharity }) => {
  const [pageSize, setPageSize] = useState(charitiesStore.charities.length);
  const [searchTerm, setSearchTerm] = useState(charitiesStore.searchString);
  const [mobileSortActive, setMobileSortActive] = useState(false);
  const [mobileSort, setMobileSort] = useState({
    id: 'name',
    desc: false,
  });

  const MAX_PAGE_SIZE = 100;

  const tableColumns = columns(charitiesStore.filterRows);

  let tableRef = useRef(null);
  let searchInputRef = useRef(null);

  const debouncedSetSearchTerm = debounce(setSearchTerm, 1000);

  useKeyboardEvent('Escape', () => {
    clearSearch();
  });

  const clearSearch = () => {
    setSearchTerm('');
  };

  const toggleMobileSort = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(true);
      return;
    }
    setMobileSortActive(false);
  };

  return (
    <div className="rt-container">
      <div className="action-bar flex-row">
        <div className="responsive-row">
          <div className={classnames('search-input')}>
            <div tabIndex="0" className="action-button">
              <FontAwesomeIcon
                icon={faSearch}
                className={classnames('', {
                  orange: searchTerm !== '',
                })}
              />
            </div>
            <input
              tabIndex={0}
              className="input"
              placeholder="Search Charities&#8230;"
              ref={searchInputRef}
              onChange={(e) => debouncedSetSearchTerm(e.target.value)}
              defaultValue={charitiesStore.searchString}
            />
            <div
              alt="clear all"
              className="action-button close-button flex-column"
              onClick={clearSearch}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </div>
        <div className="responsive-row flex-expand">
          <div className="flex-row flex-expand is-hidden-tablet"></div>
          <div className="flex-row">
            <div
              className={classnames('sort-action flex-column is-hidden-tablet')}
              onClick={
                mobileSortActive
                  ? () =>
                      setMobileSort({ ...mobileSort, desc: !mobileSort.desc })
                  : () => {
                      setMobileSort({ ...mobileSort, desc: !mobileSort.desc });
                      toggleMobileSort(true);
                    }
              }
            >
              <FontAwesomeIcon
                icon={faSortUp}
                className={classnames('', {
                  orange: mobileSort.desc,
                })}
              />
              <FontAwesomeIcon
                icon={faSortDown}
                className={classnames('', {
                  orange: !mobileSort.desc,
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <ReactTable
        filterable
        className="ledger-table"
        resizable={false}
        keyField={'id'}
        ref={tableRef}
        columns={tableColumns}
        data={charitiesStore.charities}
        pageSize={MAX_PAGE_SIZE}
        minRows={0}
        getTdProps={onGotoCharity}
        filtered={[{ id: 'charity', value: searchTerm }]}
        // defaultSorted={[{ id: 'balance', desc: true }, { id: 'charity', desc: false }]}
        defaultPageSize={MAX_PAGE_SIZE}
        showPaginationBottom={pageSize > MAX_PAGE_SIZE}
        onFilterUpdateLength={(len) => setPageSize(len)}
        {...(mobileSortActive > 0 && { sorted: [mobileSort] })}
      />
    </div>
  );
};

export default inject('charitiesStore')(observer(CharitiesTable));
