import cx from 'classnames';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useKeyboardEvent from 'hooks/useKeyboardEvent.js';

import React from 'react';
import { observer } from 'mobx-react';

import ViewFeedCommentItem from './ViewFeedCommentItem';
import EditFeedCommentInput from './EditFeedCommentInput';

const EditFeedCommentModal = ({
  onToggleClose,
  isOpen,
  isSubmitting = false,
  activeEntity,
  inputRef,
  onImagePress,
  selectedComment,
  onEditComment,
  cauzePurchaseStore,
  onToggleCloseCommentModal,
}) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });
  return (
    <>
      <div
        className={cx('modal edit-comment-modal', {
          'is-active': isOpen,
        })}
      >
        <div
          className="modal-background"
          onClick={() => {
            onToggleClose(false);
          }}
        />
        <div className="modal-card ">
          <header className="confirm-modal-head">
            <div className="modal-card-title">
              <div
                className="close-button flex-row flex-justify-end"
                onClick={() => {
                  onToggleClose(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div className="modal-card-title-text">Edit Comment</div>
            </div>
          </header>
          <div
            className=" confirm-modal-body "
            style={{ backgroundColor: 'white' }}
          >
            <ViewFeedCommentItem
              imageUrls={selectedComment.imageUrls}
              props={{ ...selectedComment }}
              body={selectedComment.body}
              onImagePress={onImagePress}
              onToggleClose={onToggleCloseCommentModal}
            />
          </div>
          <footer className="modal-card-foot gap-x-3 shadow-2xl">
            <EditFeedCommentInput
              key={
                cauzePurchaseStore.comment.purchaseId ||
                cauzePurchaseStore.comment.commentId
              }
              activeEntity={activeEntity}
              inputRef={inputRef}
              uploadingComment={isSubmitting}
              comment={selectedComment}
              cauzePurchaseStore={cauzePurchaseStore}
              postButtonText="Save"
              onUpdateComment={cauzePurchaseStore.updateComment}
              onConfirm={onEditComment}
            />
          </footer>
        </div>
      </div>
    </>
  );
};

export default observer(EditFeedCommentModal);
