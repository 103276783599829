import React from 'react';
import Avatar from '../Avatar/Avatar';

const Charity = ({ value, original }) => (
  <div className="flex-row col-charity-container">
    <div className="flex-column flex-expand min-width-reset">
      <div className="flex-row">
        <Avatar type="charity" avatar={original.avatar} />
        <div className="flex-column flex-expand col-charity-details">
          <div>
            <div className="col-charity-name">
              <button>{value}</button>
            </div>
            <div className="col-charity-place">
              {original.city}, {original.state}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Charity;
