import React, { useState } from 'react';
import { ReactComponent as DownChevron } from '../../assets/images/icons/chevron/down-chevron-2.svg';

const Select = ({ options, stateChanger }) => {
  const [isActive, setIsActive] = useState(false);
  const [placeholderText, setPlaceholderText] = useState(
    'What country would you like to find a nonprofit in?',
  );

  const updateCountry = (country) => {
    stateChanger(country);
  };

  return (
    <div className={`select-wrapper ${isActive && 'dropdown-active'}`}>
      <a className="select-header" onClick={() => setIsActive(!isActive)}>
        <span className="select-header-placeholder">{placeholderText}</span>
        <span className="select-dropdown-chevron">
          <DownChevron />
        </span>
        <div className="select-header-title"></div>
      </a>
      <div className="select-list">
        {options.map((option, index) => (
          <button
            className="select-list-item"
            id={index}
            key={index}
            onClick={() => {
              updateCountry(option.name);
              setPlaceholderText(option.name);
              setIsActive(false);
            }}
          >
            {option.name}
          </button>
        ))}
        <div className={`${options} `}></div>
      </div>
    </div>
  );
};

export default Select;
