import React from 'react';
import { Link, navigate } from '@reach/router';
import { observer, inject } from 'mobx-react';

import { getAppLink } from 'util/userAgent';
import { headerDownloadLinkPress } from 'util/events/nav';
import withUserContext from 'behaviors/withUserContext';

import Currency from 'components/Currency/Currency';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import UserDropDown from './UserDropDown';

import { ReactComponent as CauzeLogo } from 'assets/images/brand/cauze-logo.svg';
import { ReactComponent as MenuLogo } from 'assets/images/icons/svgs/menu.svg';
import giftIcon from 'assets/images/icons/gift/gift-box-color.png';
import logo from 'assets/images/brand/cauze-logotype-light.svg';
import CharitySearch from 'components/CharitySearch/GlobalCharitySearch';
import ActivityDropDown from './ActivityDropDown/ActivityDropDown';

const GiftCard = ({ amount }) => (
  <Link className="gift-card" to="/donate">
    <img className="gift-icon" src={giftIcon} />
    {amount && (
      <Currency amount={amount} superCents className="text-title gift-amount" />
    )}
  </Link>
);

const Navbar = ({
  authStore,
  hideLoginPrompt,
  uiStore,
  cobrand, // this is a company object
  activeEntity = {},
  hideContextSelector = false,
  redirectAfterContextSelect = true,
  expanded,
  setExpanded,
}) => {
  const isCobrand = cobrand && cobrand.id !== '1';
  const isAuthenticated = authStore.isAuthenticated;

  return (
    <nav
      className="w-screen bg-black py-[10px] lg:py-[18px] shadow-deep flex-row h-[3.75rem] lg:h-[76px]"
      role="navigation"
      aria-label="main navigation"
    >
      {global.IS_DEV ? <div className="env-indicator dev">DEV</div> : null}
      {global.IS_LOCAL ? (
        <div className="env-indicator local">LOCAL</div>
      ) : null}

      <div className="flex w-full justify-between">
        <div className="my-auto hidden lg:block w-[1/3] lg:w-[28vw]">
          <a
            onClick={headerDownloadLinkPress}
            href={'/donate'}
            className="content-center justify-start ml-5 lg:ml-[62px]"
          >
            {isCobrand && cobrand.logo ? (
              <div className="cobrand">
                <img
                  src={cobrand.logo.original}
                  className="app-logo app-logo-cobrand"
                  alt={cobrand.name}
                />
                <div className="powered-by">
                  Powered by
                  <img src={logo} className="app-logo" alt="Cauze" />
                </div>
              </div>
            ) : (
              <CauzeLogo color="#ffffff" />
            )}
          </a>
        </div>
        <div className="flex lg:hidden pl-4 lg:pl-0 w-[1/3]">
          <button
            className="bg-transparent border-none text-white h-8 w-8 my-auto !p-0"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <MenuLogo height="32px" width="32px" />
          </button>
        </div>
        <div className="flex lg:hidden justify-center w-[1/3]">
          <a
            onClick={headerDownloadLinkPress}
            href={'/donate'}
            className="flex lg:hidden"
          >
            <img src={logo} className="app-logo my-auto h-8" alt="Cauze" />
          </a>
        </div>
        <div className="!min-h-0 flex justify-end h-10 w-[1/3] lg:w-[72vw]">
          <div className="hidden lg:flex col-span-1 w-[44vw]">
            {!hideLoginPrompt && <CharitySearch />}
          </div>
          <div className="flex justify-end pr-[10px] lg:pr-5 w-[1/3] lg:w-[28vw]">
            {isAuthenticated ? (
              <>
                <ActivityDropDown
                  id="user-activity-mobile"
                  activeEntity={activeEntity}
                />
                <UserDropDown
                  id="user-dropdown-mobile"
                  redirectAfterContextSelect={redirectAfterContextSelect}
                  hideContextSelector={hideContextSelector}
                  activeEntity={activeEntity}
                />
              </>
            ) : (
              <>
                {uiStore.savedUiState.userToken?.gift && (
                  <div className="gift-container">
                    <GiftCard
                      amount={
                        !uiStore.savedUiState.userToken.gift.hideAmount &&
                        uiStore.savedUiState.userToken.gift?.amount
                      }
                    />
                  </div>
                )}
                <div className="flex items-center">
                  {!hideLoginPrompt ? (
                    <div className="flex-row flex-center">
                      <button
                        className="cz-btn-outline h-8 pt-2"
                        id="nav-login-button-mobile"
                        support
                        style={{ marginRight: '0.5rem' }}
                        onClick={() => navigate('/login')}
                      >
                        Login
                      </button>
                      <button
                        className="cz-btn-brand h-8 pt-[9px] hidden sm:block"
                        onClick={() => navigate('/signup')}
                      >
                        Signup
                      </button>
                    </div>
                  ) : (
                    <CauzeButton
                      onClick={headerDownloadLinkPress}
                      href={getAppLink()}
                    >
                      {uiStore.hasApp ? 'Open in Cauze' : 'Download App'}
                    </CauzeButton>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withUserContext(
  inject(
    'uiStore',
    'authStore',
    'eventStore',
    'profileStore',
  )(observer(Navbar)),
);
