import React from 'react';

import { ReactComponent as GiftIcon } from 'assets/images/icons/svgs/gift-new.svg';
import { ReactComponent as CheckedPersonIcon } from 'assets/images/icons/svgs/checked-person.svg';
import { ReactComponent as AddPersonIcon } from 'assets/images/icons/svgs/add-person.svg';
import { ReactComponent as ShareIcon } from 'assets/images/icons/share/share-grey-thick.svg';

const ProfileAction = ({ variant, onClick }) => {
  let Icon = GiftIcon;

  if (variant === 'gift') {
    Icon = GiftIcon;
  }

  if (variant === 'follow') {
    Icon = AddPersonIcon;
  }

  if (variant === 'unfollow') {
    Icon = CheckedPersonIcon;
  }

  if (variant === 'share') {
    Icon = ShareIcon;
  }

  if (variant === 'edit') {
    return (
      <button className="profile-action-edit" onClick={onClick}>
        <span>EDIT PROFILE</span>
      </button>
    );
  }

  if (variant === 'give') {
    return (
      <button className="profile-action-give" onClick={onClick}>
        <span>GIVE</span>
      </button>
    );
  }

  if (variant === 'unfollow') {
    return (
      <button className="profile-action unfollow" onClick={onClick}>
        <Icon />
      </button>
    );
  }

  return (
    <button className="profile-action" onClick={onClick}>
      <Icon />
    </button>
  );
};

export default ProfileAction;
