import { useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { navigate } from '@reach/router';

import { DashboardCard, YourAccountsCard } from 'views/Donate/components';
import LargeNumber from 'components/LargeNumber/LargeNumber';
import withUserContext from 'behaviors/withUserContext';

const GiveStartCauze = ({ profileStore, activeEntity, userProfileStore }) => {
  const startCauzeUrl = useMemo(() => {
    if (activeEntity.entityType === 'COMPANY') {
      return `/admin/company/${activeEntity.userContext.companyId}/cauzes/create`;
    }

    if (activeEntity.entityType === 'USER') {
      return `/admin/user/${activeEntity.id}/cauzes/create`;
    }

    if (activeEntity.entityType === 'CHARITY') {
      return `/admin/charity/${activeEntity.userContext.charityId}/cauzes/create`;
    }

    if (activeEntity.entityType === 'INFLUENCER') {
      return `/admin/influencer/${activeEntity.userContext.influencerId}/cauzes/create`;
    }
  }, [activeEntity]);

  const data = userProfileStore.users.get(activeEntity?.id);
  console.log(
    startCauzeUrl,
    Boolean(startCauzeUrl),
    'startCauzeUl',
    activeEntity,
  );
  return (
    <div className="flex flex-col gap-y-[22px] mt-0 pt-0 px-4 lg:pt-12 lg:px-0">
      {Boolean(startCauzeUrl) && (
        <button
          className="cz-btn-black uppercase h-12"
          onClick={() => {
            navigate(startCauzeUrl);
          }}
        >
          give / start a cauze
        </button>
      )}

      <DashboardCard title="Your Impact">
        <div className="flex gap-x-[60px]">
          <div>
            <div className="font-agenda-bold text-[22px] h-[22px] leading-none">
              <LargeNumber
                isCurrency
                amount={
                  (data?.profileData?.userGood?.alltimeGoodSum || 0) / 100
                }
              />
            </div>
            <div className="font-agenda text-sm">Total Good</div>
          </div>
          <div>
            <div className="font-agenda-bold text-[22px] h-[22px] leading-none">
              <LargeNumber
                amount={
                  data?.profileData?.userGood?.alltimeInfluencedCount || 0
                }
              />
            </div>
            <div className="font-agenda text-sm">People Inspired</div>
          </div>
        </div>
      </DashboardCard>

      <DashboardCard title="Your Accounts">
        <div className="flex flex-col gap-y-5">
          <YourAccountsCard
            name="Your Personal Account"
            avatar={
              activeEntity.avatar
                ? activeEntity.avatar
                : activeEntity?.id === profileStore?.data?.id &&
                  profileStore?.data?.avatar
            }
            entityType={activeEntity.entityType}
            userEntity
            amounts={[profileStore?.data?.balance?.total || 0]}
            userProfileStore={userProfileStore}
            id={activeEntity?.id}
          />

          {profileStore.availableUserEntities.map((context, index) =>
            index === 0 ? null : (
              <YourAccountsCard
                key={`${index}-${context.id}`}
                id={context?.id}
                avatar={context.avatar}
                name={context.name}
                amounts={[context.balance?.total || 0]}
                entityType={context.entityType}
                userProfileStore={userProfileStore}
              />
            ),
          )}

          {profileStore.groupRoles.map((entity, index) => (
            <YourAccountsCard
              key={`${index}-${entity.id}`}
              id={entity?.id}
              name={entity.name}
              avatar={entity.avatar}
              amounts={[entity.balance?.total || 0]}
              entityType={entity.entityType}
              userProfileStore={userProfileStore}
            />
          ))}
        </div>
      </DashboardCard>
    </div>
  );
};

export default withUserContext(
  inject(
    'authStore',
    'profileStore',
    'userProfileStore',
  )(observer((props) => <GiveStartCauze {...props} />)),
);
