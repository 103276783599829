import React, { useState } from 'react';

import { getEntityUrl } from 'util/navHelpers';

import CauzeButton from 'components/CauzeButton/CauzeButton';
import Avatar from 'components/Avatar/Avatar';
import ConfirmModal from 'components/modals/ConfirmModal/ConfirmModal';
import LoginModal from 'components/modals/LoginModal/LoginModal';

const EntityListItem = ({
  showFollow,
  entity,
  buttonLabel,
  store,
  userContext,
  targetContext,
  pageContext,
  targetId,
  setUnfollow,
  index,
  isAuthenticated,
  confirmMessage = 'Please Login or Signup to follow givers',
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isFollowing, setIsFollowing] = useState(entity.isSelfFollowing);

  const isInactiveUser =
    entity.entityType === 'USER' && (!entity?.isActive || entity?.unclaimed);
  const entityUrl = getEntityUrl({
    id: entity.id,
    entityType: entity.entityType || entity.__typename,
  });

  const onUnfollow = () => {
    store.follow({
      follow: false,
      targetContext,
      actorContext: userContext,
      targetId,
      pageContext,
    });
  };

  let followButton;

  if (showFollow && !isInactiveUser && !entity.isSelf) {
    if (!isFollowing) {
      followButton = (
        <CauzeButton
          className={`action-button`}
          white
          onClick={() => {
            if (!isAuthenticated) {
              setShowConfirmModal(true);
            } else {
              setIsFollowing(true);
              store.follow({
                follow: true,
                targetContext,
                actorContext: userContext,
                targetId,
                pageContext,
              });
            }
          }}
        >
          {buttonLabel || 'Follow'}
        </CauzeButton>
      );
    } else {
      followButton = (
        <CauzeButton
          className={`action-button is-follow`}
          childrenOnHover="UNFOLLOW"
          onClick={() => {
            setIsFollowing(false);
            setUnfollow({ onfollowCallback: onUnfollow, title: entity.name });
          }}
        >
          UNFOLLOW
        </CauzeButton>
      );
    }
  }

  // Bifurcated avatar due to data structure different in cauze api v1 versus v2
  const getAvatarUrls = (entity) => {
    if (entity.entityType === 'USER') {
      if (entity.user) {
        return entity.user.avatarUrls;
      } else {
        return entity.avatarUrls || entity.avatar;
      }
    }

    if (entity.entityType === 'COMPANY') {
      if (entity.company) {
        return entity.company.avatarUrls;
      } else {
        return entity.avatar;
      }
    }
  };

  const AvatarWrapper = isInactiveUser ? 'div' : 'a';

  return (
    <>
      <ConfirmModal
        isOpen={showConfirmModal}
        onToggleClose={() => setShowConfirmModal(false)}
        confirmOnly={true}
        message={confirmMessage}
        action={() => {
          setShowLoginModal(true);
        }}
        confirmText={'Login / Signup'}
        cancelText={'Not Now'}
      />
      <LoginModal
        isOpen={showLoginModal}
        onToggleClose={() => setShowLoginModal(false)}
      />
      <div className="entity-list-item-container">
        <AvatarWrapper href={entityUrl} className="entity-list-item-link">
          <Avatar
            md
            entityType={entity.entityType || 'CHARITY'}
            index={index}
            avatar={getAvatarUrls(entity)}
            onClick={null}
          />
          <div>
            <p className="name">{entity.name}</p>
          </div>
        </AvatarWrapper>
        {followButton}
      </div>
    </>
  );
};

export default EntityListItem;
