import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';

import AuthViewLogin from './AuthView/AuthViewLogin.js';
import useDashboardStore from 'stores/DashboardStore.js';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner.js';

const LoginView = ({ authStore, profileStore, uiStore, _token }) => {
  const { setProps } = useDashboardStore();

  useEffect(() => {
    if (
      authStore.isAuthenticated &&
      profileStore.hasFetchedInitial &&
      profileStore.availableUserEntities.length > 1 &&
      authStore.stashedPathDetails.path &&
      !authStore.stashedPathDetails.path.contains('addsponsor')
    ) {
      uiStore.openModal('CONTEXT_SELECTOR');
    }
  }, [profileStore.hasFetchedInitial, authStore.isAuthenticated]);

  useEffect(() => {
    setProps({
      noSidebar: true,
      noPadding: true,
      bodyClassName: 'bg-lightgray-e0e',
      navbarProps: {
        hideLoginPrompt: true,
      },
    });
  }, []);

  if (authStore.isAuthenticated && !profileStore.hasFetchedInitial) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div className="signup-view-container md:py-4">
      <AuthViewLogin />
    </div>
  );
};

export default inject(
  'authStore',
  'profileStore',
  'uiStore',
)(observer(LoginView));
