import { create } from 'zustand';

import { client as apolloClient } from 'util/apolloClient';
import { client as api2Client } from 'util/api2Client';
import { localFeedQuery, parseLocationQuery } from 'graphql/feed';
import { searchLocalCharitiesQuery } from 'graphql/search';

const initialState = {
  locationOverride: '',
  parsedLocationString: '',
  localEntities: [],
  fetchingLocalEntities: false,
  feedLoading: false,
  feedLoadingMore: false,
  offset: 0,
  lastFetchTime: new Date().toISOString(),
};

// TODO: port remaining functionality from mobile repo
const useLocalFeedStore = create((set, get) => ({
  ...initialState,
  getLocalNonprofits: async ({ location }) => {
    const state = get();

    set({ fetchingLocalEntities: true });

    if (!location || location.trim('') === '') {
      if (!state.locationOverride || state.locationOverride.trim('') === '') {
        return;
      }
    }

    const options = {
      variables: {
        location: state.locationOverride || location,
      },
      query: searchLocalCharitiesQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.query(options);
      set({ localEntities: result.data?.locationProjects?.projects });
    } catch (err) {
      console.log(err);
    } finally {
      set({ fetchingLocalEntities: false });
    }
  },
  getParsedLocation: async ({ location }) => {
    const options = {
      variables: {
        location,
      },
      query: parseLocationQuery,
      errorPolicy: global.IS_DEV ? 'all' : 'none',
      fetchPolicy: 'no-cache',
    };
    try {
      const result = await apolloClient
        .query(options)
        .then((res) => res.data.parseLocation);

      if (result.city && result.stateCode) {
        set({ parsedLocationString: `${result.city}, ${result.stateCode}` });
      } else {
        set({ parsedLocationString: '' });
      }

      if (result.zipcode) {
        set({ parsedZip: result.zipcode });
        return result.zipcode;
      }

      return null;
    } catch (err) {
      console.log(err);
      set({ parsedLocationString: '' });
      return null;
    }
  },
  getFeed: async (arg) => {
    const state = get();

    if (state.feedLoading || state.feedLoadingMore) {
      return;
    }
    let zip = null;
    if (arg?.zip) {
      zip = arg.zip;
    }

    set({
      feedLoading: true,
      offset: 0,
      lastFetchTime: new Date().toISOString(),
    });

    try {
      const result = await api2Client.query({
        variables: {
          limit: state.limit,
          offset: state.offset,
          zipcode: zip || state.zipOverride,
        },
        fetchPolicy: 'no-cache',
        query: localFeedQuery,
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      });

      set({
        feed: result.data.localFeed,
        hasFetchedInitial: true,
        offset: state.offset + result.data.localFeed.length,
        hasMoreItems: result.data.localFeed.length === state.limit,
      });
    } catch (err) {
      console.log(err);
    }

    set({ feedLoading: false });
  },
}));

export default useLocalFeedStore;
