import React, { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

import { ReactComponent as ThreeDotsIcon } from 'assets/images/icons/dots/three-dots.svg';

const FeedItemActionMenu = ({
  className,
  uiStore,
  purchaseId,
  isCurrentEntity,
}) => {
  const [visible, setVisible] = useState(false);

  const { getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    placement: 'bottom',
    trigger: 'click',
    interactive: true,
    closeOnTriggerHidden: true,
    visible,
    onVisibleChange: (isVisible) => {
      setVisible(isVisible);
    },
  });

  if (isCurrentEntity) return <></>;

  return (
    <>
      <div
        className={className}
        ref={setTriggerRef}
        style={{ display: 'inline-block' }}
      >
        <div className="mt-3 h-4 text-lightgray-999">
          <ThreeDotsIcon />
        </div>
      </div>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className:
              'tooltip-container tooltip-container-checkout-summary !p-0 !py-2 !border !border-solid !border-lightgray-e8e',
          })}
        >
          <div className="tooltip-body">
            <button
              className="font-agenda text-[16px] w-full p-2.5 text-center bg-transparent hover:bg-lightgray-e8e cursor-pointer border-none"
              onClick={() => {
                setVisible(false);
                uiStore.openModal('REPORT', {
                  reportProps: {
                    purchaseId,
                  },
                });
              }}
            >
              Report
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedItemActionMenu;
