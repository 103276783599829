import { observable, action, toJS, makeObservable } from 'mobx';
import mixpanel from 'mixpanel-browser';
import { pinEventMutation, userEventsQuery } from 'graphql/event';

import { client as apolloClient } from 'util/apolloClient';
import { client as api2Client } from 'util/api2Client';
import {
  userActivityById,
  userJoinedCauzesQuery,
  userProfileByIdQueryV2,
} from 'graphql/user';
import {
  followMutation,
  unfollowMutation,
  updateFollowerMutation,
} from 'graphql/follow';
import { trackFollow, trackUnfollow } from 'util/tracking/follow';
import { parseLocationQuery, userFeedQuery } from 'graphql/feed';
import { likeMutation, unlikeMutation } from 'graphql/like';
import { updateProfileMutation } from 'graphql/profile';

const LEDGER_PAGE_SIZE = 200;

class UserProfileStore {
  @observable loading = true;
  @observable feedLoading = true;
  @observable feedLoadingMore = false;
  @observable ledgerLoading = true;
  @observable ledgerLoadingMore = false;
  @observable ledgerFilters = {};
  @observable ledgerHasMore = true;
  @observable eventsLoading = true;
  @observable joinedEventsLoading = true;
  @observable isError = false;
  @observable data;
  @observable userFeed;
  @observable totalPages;
  @observable currentPage = 1;
  @observable currentLedgerPage = 1;
  @observable users = new Map();
  @observable feeds = new Map();
  @observable events = new Map();
  @observable supportedCharities = new Map();
  @observable charities = new Map();
  @observable joinedEvents = new Map();
  @observable updatingZip = false;

  @action setCharities = ({ followerId, charities }) => {
    this.charities.set(followerId, charities);
  };

  @action setLedgerFilters = (filters) => {
    this.ledgerFilters = filters;
  };

  @action getUser = async ({ id, silent = false }) => {
    if (!silent) {
      this.loading = true;
    }
    const options = {
      variables: { userId: parseInt(id) },
      query: userProfileByIdQueryV2,
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const result = await api2Client.query(options);
      this.users.set(id, {
        ...result.data.data,
        profileData: result.data.profileData,
      });

      this.charities.set(id, result.data.data.featuredProjects);
      this.loading = false;
      this.isError = false;
    } catch (err) {
      this.loading = false;
      this.isError = true;
      this.error = err;
    }
  };

  @action getUserActivity = async ({ id, loadMore = false, filters }) => {
    if (!loadMore) {
      this.currentLedgerPage = 1;
      this.ledgerLoading = true;
      this.ledgerHasMore = true;
    } else {
      this.currentLedgerPage += 1;
      this.ledgerLoadingMore = true;
    }

    const options = {
      variables: {
        userId: parseInt(id),
        offset: (this.currentLedgerPage - 1) * LEDGER_PAGE_SIZE,
        limit: LEDGER_PAGE_SIZE,
        ...filters,
      },
      query: userActivityById,
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const result = await api2Client.query(options);
      const userActivity =
        result?.data?.userProfile?.userBalanceCreditsAndDebits || [];

      if (!userActivity?.length || userActivity.length < LEDGER_PAGE_SIZE) {
        this.ledgerHasMore = false;
      }

      return userActivity;
    } catch (err) {
      // ignore
    } finally {
      this.ledgerLoading = false;
      this.ledgerLoadingMore = false;
    }
  };

  @action getFeed = async ({ id }) => {
    this.feedLoading = true;
    this.currentPage = 1;

    try {
      const variables = {
        userId: parseInt(id),
        limit: 20,
        offset: 0,
      };

      const options = {
        variables: variables,
        query: userFeedQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);

      this.feeds.set(id, results.data.userFeed);
    } catch (err) {
      // pass
    }

    this.feedLoading = false;
  };

  @action getEvents = async ({ id }) => {
    this.eventsLoading = true;

    try {
      const variables = {
        userId: parseInt(id),
        pinnedOnly: true,
      };

      const options = {
        variables: variables,
        query: userEventsQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);
      this.events.set(id, results.data.userEvents);
    } catch {
      // pass
    }

    this.eventsLoading = false;
  };

  @action getUserJoinedEvents = async ({ id }) => {
    this.joinedEventsLoading = true;

    try {
      const variables = {};

      const options = {
        variables,
        query: userJoinedCauzesQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);
      this.joinedEvents.set(id, results.data.joinedCauzes);
    } catch {
      // pass
    }

    this.joinedEventsLoading = false;
  };

  @action loadMore = async ({ id }) => {
    this.currentPage = this.currentPage + 1;
    this.feedLoadingMore = true;
    const currentFeedData = toJS(this.feeds.get(id));

    if (this.currentPage > this.totalPages) {
      return;
    }

    try {
      const variables = {
        userId: parseInt(id),
        limit: 20,
        offset: 20 * (this.currentPage - 1),
      };

      const options = {
        variables: variables,
        query: userFeedQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);
      this.feeds.set(id, currentFeedData.concat(results.data.userFeed));
    } catch (err) {
      // pass
    }

    this.feedLoadingMore = false;
  };

  @action like = ({ like = true, id, purchaseId, userId, userContext }) => {
    const currentFeed = toJS(this.feeds.get(userId));
    const feedItem = currentFeed.find(
      (feedItem) => feedItem?.activityPurchase?.id === id,
    );
    if (!feedItem) return;
    feedItem.currentEntityLiked = like;
    feedItem.likeCount = like ? feedItem.likeCount + 1 : feedItem.likeCount - 1;
    this.feeds.set(userId, currentFeed);

    const updatedUserContext = { ...userContext };
    if (updatedUserContext.influencerId) {
      updatedUserContext.userId = updatedUserContext.influencerId;
      delete updatedUserContext.influencerId;
    }

    const options = {
      variables: { id: purchaseId, userContext: updatedUserContext },
      mutation: like ? likeMutation : unlikeMutation,
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };
    apolloClient.mutate(options).catch(() => {
      feedItem.currentEntityLiked = !like;
      feedItem.likeCount = like
        ? feedItem.likeCount - 1
        : feedItem.likeCount + 1;
      this.feeds.set(userId, currentFeed);
    });
  };

  @action follow = ({ follow = true, actorContext, userId }) => {
    const targetContext = { userId };
    this.setFollow({ userId, follow });

    if (follow) {
      trackFollow({ actorContext, targetContext });
    } else {
      trackUnfollow({ actorContext, targetContext });
    }

    const options = {
      variables: {
        targetContext,
        actorContext,
      },
      mutation: follow ? followMutation : unfollowMutation,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options).catch((err) => {
      this.setFollow({ userId, follow: !follow });
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    });
  };

  @action setFollow = ({ userId, follow }) => {
    const userData = this.users.get(userId);
    userData.isSelfFollowing = follow;
    userData.followerCount = userData.followerCount + (follow ? 1 : -1);
    this.users.set(userId, userData);
  };

  @action pinEvent = ({ eventId, userId, pinned = true }) => {
    const event = this.events
      .get(userId)
      ?.find(
        (event) => eventId && event.id?.toString() === eventId?.toString(),
      );

    if (!event) return;

    event.currentEntityHasPinned = pinned;

    mixpanel.track('Pin Event', { eventId });

    const variables = {
      eventId: parseInt(eventId),
      pinned: Boolean(pinned),
    };

    const options = {
      variables: variables,
      mutation: pinEventMutation,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    api2Client.mutate(options).catch(() => {
      event.currentEntityHasPinned = pinned;
    });
  };

  @action resetStore = () => {
    apolloClient.resetStore();
  };

  @action updateZipFromParsedLocation = async ({ location, id }) => {
    this.updatingZip = true;
    let options = {
      variables: {
        location,
      },
      query: parseLocationQuery,
      errorPolicy: global.IS_DEV ? 'all' : 'none',
      fetchPolicy: 'no-cache',
    };
    try {
      const idKey = `${id}`;
      await apolloClient.query(options);

      options = {
        variables: { id, zip: location },
        mutation: updateProfileMutation,
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      };
      await apolloClient.mutate(options);

      const user = this.users.get(idKey);

      if (user) {
        this.users.set(idKey, {
          ...user,
          zip: location,
        });
      }
    } catch (err) {
      console.log(err);
    }
    this.updatingZip = false;
  };

  updateFollow = async ({ charityId, priority }) => {
    const variables = {
      charityId: parseInt(charityId),
      priority: parseInt(priority),
    };

    const options = {
      variables: variables,
      mutation: updateFollowerMutation,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    await api2Client.mutate(options);
  };

  constructor() {
    makeObservable(this);
  }
}

const userProfileStore = new UserProfileStore();
export default userProfileStore;
