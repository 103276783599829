import { useEffect, useState } from 'react';
import cx from 'classnames';

import getCardIcon from 'util/getCardIcon';

import Currency from 'components/Currency/Currency';
import AppleButton from 'components/Button/AppleButton';
import AddCard from 'components/SavedPaymentMethods/AddCard';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import useCheckoutStore from 'stores/CheckoutStore';
import GoogleButton from 'components/Button/GoogleButton';
import PaypalButton from 'components/Button/PaypalButton';
import VenmoButton from 'components/Button/VenmoButton';

const PaymentMethodOption = ({ card, isActive, onClick }) => {
  const containerClassName = cx(
    'font-agenda flex justify-between px-2.5 py-[15px] rounded-[10px] capitalize cursor-pointer hover:bg-lightgray-e8e',
    {
      'bg-lightgray-e8e': isActive,
    },
  );

  return (
    <div className={containerClassName} onClick={onClick}>
      <div className="text-sm h-[14px] my-auto">{card.name || card.brand}</div>
      <div className="flex gap-x-[5px] text-sm">
        {card.brand && <img src={getCardIcon(card.brand)} />}
        {card.last4 && (
          <>
            <div className="h-4 my-auto">****</div>
            <div className="h-4 my-auto">{card.last4}</div>
          </>
        )}
        {card.balance && (
          <>
            <div className="flex h-4 my-auto gap-x-1">
              <span className="text-gray-595">Available Balance</span>
              <Currency showCents amount={card.balance} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const PaymentMethodsContent = ({
  cards = [],
  setFrame,
  isMobile,
  onAddCard,
}) => {
  const elements = useElements();
  const stripe = useStripe();

  const addFundsGiftCheckoutStore = useCheckoutStore();
  const currentCheckout = addFundsGiftCheckoutStore.currentCheckout;

  const [selectedCard, setSelectedCard] = useState(
    addFundsGiftCheckoutStore.activePaymentMethod,
  );
  const [showAddCard, setShowAddCard] = useState(false);

  const handleConfirm = (card) => {
    addFundsGiftCheckoutStore.setActivePaymentMethod(card);
    addFundsGiftCheckoutStore.startCheckout();

    setFrame(isMobile ? 2 : 0);
  };

  useEffect(() => {
    setSelectedCard(addFundsGiftCheckoutStore.activePaymentMethod);
  }, [addFundsGiftCheckoutStore.activePaymentMethod]);

  return (
    <div className="px-5 flex flex-col h-full">
      <div className="flex justify-center w-full pb-2.5">
        <div className="font-agenda-bold text-[20px] mx-auto py-2">
          Payment Method
        </div>
      </div>
      <div className="pb-2.5 mb-2.5 flex-1 overflow-auto">
        {currentCheckout.balance >= currentCheckout.splitAmount?.total &&
          currentCheckout.giftType?.toUpperCase() !== 'SELF_GIFT' && (
            <PaymentMethodOption
              card={{
                name: 'Cauze Balance',
                balance: currentCheckout.balance,
              }}
              isActive={selectedCard?.paymentType === 'BALANCE'}
              onClick={() => {
                setSelectedCard({
                  paymentType: 'BALANCE',
                });
              }}
            />
          )}
        {cards.map((card) => (
          <PaymentMethodOption
            key={card.id}
            card={card}
            isActive={selectedCard?.id === card.id}
            onClick={() => {
              setSelectedCard(card);
            }}
          />
        ))}
      </div>
      {window.ApplePaySession ? (
        <AppleButton
          className="mb-1.5"
          onClick={() => {
            handleConfirm({ paymentType: 'APPLE_PAY' });
          }}
        />
      ) : (
        <GoogleButton
          className="mb-1.5"
          onClick={() => {
            handleConfirm({ paymentType: 'GOOGLE_PAY' });
          }}
        />
      )}
      <div className="flex gap-x-1.5 mb-1.5">
        <PaypalButton
          className="flex-1"
          onClick={() => {
            addFundsGiftCheckoutStore.setActivePaymentMethod({
              paymentType: 'paypal',
            });

            setFrame(isMobile ? 2 : 0);
          }}
        />
        <VenmoButton
          className="flex-1"
          onClick={() => {
            addFundsGiftCheckoutStore.setActivePaymentMethod({
              paymentType: 'paypal',
            });

            setFrame(isMobile ? 2 : 0);
          }}
        />
      </div>
      <div className="mb-2">
        {isMobile ? (
          <button
            className="py-3 text-center w-full border-none bg-white cursor-pointer font-agenda-bold text-sm text-cauzeorange hover:bg-lightgray-e8e rounded-[10px] mb-2.5"
            onClick={() => {
              setFrame(3);
            }}
          >
            Add New Card
          </button>
        ) : (
          <AddCard
            showAddCard={showAddCard}
            setShowAddCard={setShowAddCard}
            isCheckout
          />
        )}
      </div>
      <button
        className={'cz-btn-brand w-full mt-auto '}
        disabled={!selectedCard && !showAddCard}
        onClick={async () => {
          let card = selectedCard;

          if (showAddCard) {
            card = await onAddCard({ elements, stripe });
            setSelectedCard(card);
            setShowAddCard(false);
          }

          handleConfirm(card);
        }}
      >
        OK
      </button>
    </div>
  );
};

export default PaymentMethodsContent;
