import gql from 'graphql-tag';

const giftTokenDetailsQuery = gql`
  query userToken($token: String!) {
    userToken(token: $token) {
      gift {
        amount
        initiatorCompany {
          id
        }
        initiator {
          id
        }
        hideAmount
      }
      redeemer {
        firstName
        lastName
        id
      }
      company {
        name
        id
        description
        avatar {
          sm
          md
        }
        logo {
          original
        }
        accentColor
      }
      revoked
      redeemed
      token
      email
      id
    }
  }
`;

const featuredEventsFragment = `
  name
  startDate
  endDate
  id
  giverCount
  externalImageUrl
  current
  image {
    full
    original
    lg
    md
    sm
  }
  purchaserCount
  description
  topPurchasers {
    actor {
      id
      type
      avatarUrls {
        sm
      }
    }
  }
  matches {
    active
    id
    startDate
    totalRemaining
    currentEntityRemaining
    multiplier
    matchType
  }
  hosts {
    name
    type
    avatarUrls {
      sm
    }
  }
  charities {
    name
    avatarUrls {
      sm
    }
  }
`;

const featuredEventsQuery = gql`
  query featuredEvents($zipcode: String, $limit: Int = 5) {
    featuredEvents(zipcode: $zipcode, limit: $limit) {
      ${featuredEventsFragment}
    }
  }
`;

const associatedEventsQuery = gql`
  query associatedEvents($user_context: UserContext!) {
    associatedEvents(userContext: $user_context) {
      ${featuredEventsFragment}
    }
  }
`;

const redeemGiftTokenMutation = gql`
  mutation redeemGiftToken($token: String!) {
    redeemGiftToken(token: $token) {
      gift {
        amount
      }
    }
  }
`;

const redeemCompanyInviteTokenMutation = gql`
  mutation redeemCompanyToken($token: String!) {
    redeemCompanyToken(token: $token) {
      company {
        id
        name
        username
      }
    }
  }
`;

const inspectTokenQuery = gql`
  query inspectToken($token: String!) {
    inspectToken(token: $token)
  }
`;

export {
  giftTokenDetailsQuery,
  featuredEventsQuery,
  associatedEventsQuery,
  redeemGiftTokenMutation,
  inspectTokenQuery,
  redeemCompanyInviteTokenMutation,
};
