import classnames from 'classnames';

import GiveStartCauze from 'components/Asides/GiveStartCauze';
import { EventListItem } from 'components/EventList/DiscoverEventList';
import useDashboardStore from 'stores/DashboardStore';
import { useEffect } from 'react';

const EntityCauzesView = ({
  uiStore,
  joinedCauzes = [],
  activeCauzes = [],
  draftCauzes = [],
  endedCauzes = [],
  isAuthenticated,
  loading,
}) => {
  const { setProps } = useDashboardStore();

  const renderCauzeSection = (title, cauzes) => (
    <>
      <div
        className={classnames(
          'text-left mb-5 font-agenda-bold text-[17px] leading-[17px] text-black ',
          { 'mt-2': title === 'Joined', 'mt-5': title !== 'Joined' },
        )}
      >
        {title} ({cauzes.length})
      </div>
      <div className="flex flex-col gap-5">
        {cauzes &&
          cauzes.length > 0 &&
          cauzes.map((event) => (
            <EventListItem key={event.id} event={event} uiStore={uiStore} />
          ))}
      </div>
    </>
  );

  useEffect(() => {
    setProps({
      title: 'YOUR CAUZES',
      asideChildren: isAuthenticated && <GiveStartCauze />,
      loading,
    });
  }, [isAuthenticated, loading]);

  return (
    <>
      {renderCauzeSection('Joined', joinedCauzes)}
      {renderCauzeSection('Drafts', draftCauzes)}
      {renderCauzeSection('Active', activeCauzes)}
      {renderCauzeSection('Ended', endedCauzes)}
    </>
  );
};

export default EntityCauzesView;
