import React from 'react';
import { observer, inject } from 'mobx-react';

import withUserContext from 'behaviors/withUserContext';
import useCharityProfileById from 'hooks/useCharityProfileById';
import { normalizeContext } from 'util/contextUtils';

import EntityProfile from 'components/EntityProfile/EntityProfile';
import convertFeedToV1 from 'util/feed/convertFeedToV1';
import mergeFeedItems from 'util/feed/mergeFeedItems';

const CharityProfileView = ({
  profileStore,
  charityStore,
  authStore,
  uiStore,
  id,
  userContext,
}) => {
  useCharityProfileById({ id, userContext });
  const charityData = charityStore.charities.get(id) || {};

  const feed = charityStore.feeds.get(id);
  const events = charityStore.events.get(id);

  const onFollow = () => {
    charityStore.follow({
      follow: !charityData.isSelfFollowing,
      actorContext: normalizeContext(userContext),
      charityId: id,
    });
  };

  const onLike = ({ feedId }) => {
    const feedItem = feed?.find(
      (feedItem) => feedItem?.activityPurchase?.id === feedId,
    );

    if (feedItem) {
      charityStore.like({
        like: !feedItem.currentEntityLiked,
        id: feedId,
        purchaseId: feedItem.activityPurchase?.id,
        charityId: id,
        userContext: userContext,
      });
    }
  };

  const feedLoadMore = () => {
    charityStore.loadMore({ id });
  };

  const getCharitySocials = () => {
    const socials = [
      charityData.facebookName && {
        socialType: 'facebook',
        url: `https://www.facebook.com/${charityData.facebookName}/`,
      },
      charityData.instagramName && {
        socialType: 'instagram',
        url: `https://www.instagram.com/${charityData.instagramName}/`,
      },
      charityData.twitterName && {
        socialType: 'x',
        url: `https://twitter.com/${charityData.twitterName}`,
      },
      charityData.youtubeUrl && {
        socialType: 'youtube',
        url: charityData.youtubeUrl,
      },
    ].filter((s) => s);

    return socials;
  };

  return (
    <>
      <EntityProfile
        loading={charityStore.loading || !charityStore.charities.has(id)}
        entityData={{
          ...charityData,
          userSocials: getCharitySocials(),
          profileData: {
            ...charityData,
          },
        }}
        isAuthenticated={authStore.isAuthenticated}
        onFollow={onFollow}
        onLike={onLike}
        uiStore={uiStore}
        events={events}
        eventsLoading={charityStore.eventsLoading}
        feedLoading={charityStore.feedLoading}
        feedLoadMore={feedLoadMore}
        feedLoadingMore={charityStore.feedLoadingMore}
        feed={mergeFeedItems(feed)?.map(convertFeedToV1)}
        entityType="NONPROFIT"
        isVerified
        showEditButton={
          charityData.isSelf || profileStore.activeEntity.sysAdmin
        }
      />
    </>
  );
};

export default withUserContext(
  inject(
    'profileStore',
    'charityStore',
    'authStore',
    'uiStore',
  )(observer(CharityProfileView)),
);
