import { PaymentElement } from '@stripe/react-stripe-js';

import stripeOptions from 'config/stripe';

const AddCard = ({
  onAddCard,
  showAddCard,
  setShowAddCard,
  isCheckout,
  defaultOpen,
}) => {
  return (
    <div>
      {!defaultOpen && (
        <button
          className="cz-btn-outline black w-full text-[13px] rounded-[5px]"
          type="button"
          onClick={() => setShowAddCard(!showAddCard)}
        >
          ADD NEW CARD
        </button>
      )}
      {(showAddCard || defaultOpen) && (
        <>
          <PaymentElement options={stripeOptions.cardElement} />
          {!isCheckout && (
            <button
              style={{ marginTop: '0.5rem', marginBottom: '2rem' }}
              onClick={onAddCard}
              className="cz-btn-black w-full"
            >
              ADD CARD
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default AddCard;
