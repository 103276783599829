import gql from 'graphql-tag';

const getPurchaseCommentsQuery = gql`
  query getPurchaseCommentsQuery($purchaseId: Int!) {
    cauzePurchaseComments(purchaseId: $purchaseId) {
      id
      charityId
      user {
        id
        avatarUrls {
          sm
          md
        }
        isCurrentEntity
        name
        firstName
        lastName
        username
        isActive
        unclaimed
      }
      company {
        id
        name
        avatarUrls {
          sm
          md
        }
      }
      project {
        name
        avatarUrls {
          sm
          md
        }
      }
      body
      previewUrl
      imageUrls {
        lg
        xl
        full
      }
      imageId
    }
  }
`;

const cauzePurchaseByIdQuery = gql`
  query cauzePurchaseById($id: ID) {
    cauzePurchaseById(id: $id) {
      id
      company {
        name
        id
        avatar {
          sm
        }
      }
      user {
        id
        name
        firstName
        lastName
        avatar {
          sm
        }
        isSelf
      }
      donationAmount
      matchAmount
      donorProject {
        name
        avatar {
          sm
          md
        }
        id
      }
      body: commentBody
      previewUrl
      image {
        lg
        xl
        full
      }
      imageId
      project {
        id
        name
      }
      total
      shareLink
    }
  }
`;

const cauzePurchaseByIdQueryV2 = gql`
  query cauzePurchaseById($id: Int!) {
    purchase(cauzePurchaseId: $id) {
      id
      eventId
    }
  }
`;

const likeActorsForPurchaseQuery = gql`
  query likeActorsForPurchase($purchase_id: ID!, $user_context: UserContext) {
    likeActorsForPurchase(
      purchaseId: $purchase_id
      userContext: $user_context
    ) {
      id
      name
      subtitle
      entityType
      isSelfFollowing
      isSelf
      avatar {
        sm
        md
      }
    }
  }
`;

const likeActorsForPurchaseQueryV2 = gql`
  query likeActorsForPurchase($purchaseId: Int!) {
    cauzePurchaseLikes(purchaseId: $purchaseId) {
      id
      name
      subtitle
      type
      currentEntityIsFollowing
      isCurrentEntity
      avatarUrls {
        sm
        md
      }
    }
  }
`;

const addPurchaseCommentMutation = gql`
  mutation addPurchaseComment(
    $purchase_ids: [ID]!
    $body: String
    $preview_url: String
    $image_id: ID
    $user_context: UserContext
  ) {
    addPurchaseComment(
      purchaseIds: $purchase_ids
      body: $body
      previewUrl: $preview_url
      imageId: $image_id
      userContext: $user_context
    ) {
      id
      charityId
      charityDisplayName
      charityAvatar {
        sm
        md
      }
      user {
        id
        avatar {
          sm
          md
        }
        isSelf
        firstName
        lastName
      }
      company {
        id
        name
        avatar {
          sm
          md
        }
      }
      body
      previewUrl
      image {
        lg
        xl
        full
      }
      imageId
    }
  }
`;

const editPurchaseCommentMutation = gql`
  mutation editPurchaseComment(
    $body: String
    $preview_url: String
    $comment_id: ID!
    $image_id: ID
  ) {
    editPurchaseComment(
      body: $body
      previewUrl: $preview_url
      commentId: $comment_id
      imageId: $image_id
    ) {
      id
      body
      previewUrl
      image {
        lg
      }
      imageId
    }
  }
`;

const deletePurchaseCommentMutation = gql`
  mutation deletePurchaseComment($comment_id: ID!) {
    deletePurchaseComment(commentId: $comment_id) {
      id
    }
  }
`;
const deletePurchaseMutation = gql`
  mutation deletePurchase($purchase_id: ID!, $user_context: UserContext) {
    deletePurchase(purchaseId: $purchase_id, userContext: $user_context) {
      id
    }
  }
`;

const editPurchaseMutation = gql`
  mutation editPurchase(
    $purchase_id: ID!
    $body: String
    $preview_url: String
    $image_id: ID
    $user_context: UserContext
  ) {
    editPurchase(
      purchaseId: $purchase_id
      body: $body
      previewUrl: $preview_url
      imageId: $image_id
      userContext: $user_context
    ) {
      id
    }
  }
`;

export {
  deletePurchaseMutation,
  getPurchaseCommentsQuery,
  likeActorsForPurchaseQuery,
  likeActorsForPurchaseQueryV2,
  cauzePurchaseByIdQuery,
  editPurchaseCommentMutation,
  deletePurchaseCommentMutation,
  editPurchaseMutation,
  addPurchaseCommentMutation,
  cauzePurchaseByIdQueryV2,
};
