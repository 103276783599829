import React, { useMemo, useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import cx from 'classnames';
import { ReactComponent as PeopleIcon } from 'assets/images/icons/svgs/people-bold.svg';
import { ReactComponent as GiveIcon } from 'assets/images/icons/svgs/give-bold.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import Avatar from 'components/Avatar/Avatar';

import { shorten } from 'util/stringUtils';
import LargeNumber from 'components/LargeNumber/LargeNumber';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import useMobile from 'hooks/useMobile';
import Tooltip from 'components/Tooltip/Tooltip';

const EventListItem = ({ event }) => {
  const { isMobile } = useMobile();

  const eventItemImageClassName = useMemo(() => {
    const i = event.name.length % 6;
    return `h-full w-full background-gradient-${i + 1}`;
  }, [event]);

  const giverCount = useMemo(() => {
    return Math.max(
      event.purchaserCount || 0,
      event.topPurchasers?.length || 0,
    );
  }, [event]);

  return (
    <div
      key={`${event.name}-${event.id}`}
      onClick={() => navigate(`/event/${event.id}`)}
      className="flex w-full gap-x-2 border-solid border border-lightgray-b2b font-agenda rounded-[5px] h-[201px] cursor-pointer"
    >
      <div className="flex-shrink-0 w-[115px] h-full bg-[#e6e6e6] flex items-center">
        {event.image?.md ? (
          <img className="w-full h-full object-cover " src={event.image?.md} />
        ) : (
          <div className={eventItemImageClassName} />
        )}
      </div>
      <div className="flex-grow  pr-5 pl-3 flex flex-col    h-[167px] my-auto overflow-hidden">
        {event.hosts?.length === 1 ? (
          <div className="mb-[15px] event-item-host flex gap-x-[10px]">
            <Avatar
              className="my-auto"
              avatarUrls={event.hosts?.[0]?.avatarUrls}
            />

            <div className="text-text-light my-auto leading-none text-[13px]">
              {shorten(event && event.name, 50)}
            </div>
          </div>
        ) : (
          <div className="mb-[15px] event-item-host flex flex-col gap-1">
            <div className="text-[#1B2031BD] leading-none my-auto text-[13px]">
              For {event.hosts.length} NonProfits:
            </div>
            <div className="flex -space-x-1">
              {event.hosts.slice(0, 5).map((host, index) => (
                <Tooltip
                  key={index}
                  text={host.name}
                  className="w-[34px] h-[34px]"
                >
                  <Avatar
                    className="my-auto !border-solid !border-[0.5px] !border-[#ccc]"
                    avatarUrls={host.avatarUrls}
                  />
                </Tooltip>
              ))}
              {event.hosts.length > 5 && (
                <div className=" !border-solid !border-[0.5px] !border-[#ccc] min-w-8 h-8 flex items-center justify-center text-sm font-agenda font-bold bg-gray-200 rounded-full">
                  +{event.hosts.length - 5}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="flex flex-col gap-0 justify-between h-full">
          <div className="text-[13px] font-agenda text-[#1B2031] row-span-2 overflow-hidden mb-[15px]">
            {shorten(
              event && event.description,
              isMobile || event.hosts?.length > 1 ? 80 : 120,
            )}
          </div>
          <div className="flex gap-8">
            <div className="flex items-center gap-x-2">
              <div className="flex items-center justify-center">
                <PeopleIcon />
              </div>
              <div className=" text-sm">
                <LargeNumber amount={giverCount} />
              </div>
            </div>
            <div className="flex  items-center gap-x-2">
              <GiveIcon />

              <div className=" text-sm">
                <LargeNumber isCurrency amount={(event.current || 0) / 100} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EventList = ({ events, loading, onPin, isSelf, uiStore }) => {
  const [listEvents, setListEvents] = useState(events);
  const { isMobile } = useMobile();

  useEffect(() => {
    setListEvents(events);
  }, [events]);

  if (loading) {
    return (
      <div className="discover-event-list">
        <CauzeSpinner />
      </div>
    );
  }

  return (
    <Swiper
      className={cx('discover-event-list', {
        'show-arrows': listEvents && listEvents.length > 2,
      })}
      navigation
      modules={[Navigation]}
      spaceBetween={isMobile ? 10 : 20}
      centeredSlides={false}
      freeMode={true}
      slidesPerView="auto"
    >
      {listEvents.map((event) => (
        <SwiperSlide key={event.id} className="!w-[319px] sm:!w-[329px]">
          <EventListItem
            event={event}
            isSelf={isSelf}
            uiStore={uiStore}
            onPin={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if (event.currentEntityHasPinned) {
                setListEvents(listEvents.filter((evt) => evt.id !== event.id));
              }

              onPin?.({
                eventId: event.id,
                pinned: !event.currentEntityHasPinned,
              });
            }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export { EventListItem };
export default EventList;
