import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faTimes,
  faFilter,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

const ServerTable = ({
  id,
  loading,
  columnDefs,
  rows,
  limit,
  offset,
  offsetChanged,
  totalRows,
  sort,
  sortChanged,
  sortDirection,
  sortDirectionChanged,
  searchTerm,
  searchTermChanged,
  filterClicked,
  menuActions,
  canBeSelected,
  getItemKey,
}) => {
  const id_ = id || `ServerTable-${Math.random().toString()}`;

  const sortClass = ` sort-${sortDirection}`;
  const nPages = Math.floor(totalRows / limit);
  const page = Math.floor(offset / limit) + 1;
  const [allSelected, setAllSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState(() => new Set());
  const [selectedAction, setSelectedAction] = useState(null);

  useEffect(() => {
    (rows || []).forEach((row) => {
      if (allSelected) {
        clearItems();
        (rows || []).forEach((row) => {
          if (canBeSelected(row, selectedAction)) {
            addItem(getItemKey(row));
          }
        });
      } else if (
        !canBeSelected(row, selectedAction) &&
        selectedItems.has(getItemKey(row))
      ) {
        removeItem(getItemKey(row));
      }
    });
  }, [selectedAction]);

  const addItem = (item) => {
    setSelectedItems((prev) => new Set(prev).add(item));
  };

  const removeItem = (item) => {
    setSelectedItems((prev) => {
      const next = new Set(prev);

      next.delete(item);

      return next;
    });
  };

  const clearItems = () => {
    setSelectedItems(() => {
      const next = new Set();
      return next;
    });
  };

  return (
    <div id={`${id_}-wrap`}>
      <div className="table-actions">
        <select
          onChange={(e) => {
            if (menuActions[e.target.value] === undefined) {
              setSelectedAction(null);
            }
            setSelectedAction(e.target.value);
          }}
        >
          <option id={`${id_}-action-default`}>Actions</option>
          {menuActions.map((ma, index) => (
            <option id={`${id_}-action-${ma.name}`} value={index}>
              {ma.name}
            </option>
          ))}
        </select>
        <button
          className="button is-primary is-small"
          onClick={() => {
            if (!menuActions[selectedAction]) {
              return;
            }
            const copySelectedItems = cloneDeep(Array.from(selectedItems));
            menuActions[selectedAction].action(copySelectedItems);
            clearItems();
          }}
        >
          apply
        </button>
        <div className="right-actions">
          {loading && <FontAwesomeIcon icon={faSpinner} spin />}
          <FontAwesomeIcon
            icon={faFilter}
            onClick={() => {
              filterClicked();
            }}
          />
          <FontAwesomeIcon icon={faSearch} />
          <input
            className="input"
            placeholder="Search by recipient"
            onChange={(e) => searchTermChanged(e.target.value)}
            value={searchTerm}
          />
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
              searchTermChanged('');
            }}
          />
        </div>
      </div>
      <table id={id_} className="server-table">
        <thead>
          <tr>
            {menuActions.length > 0 && (
              <th className="action-col st-th">
                <input
                  type="checkbox"
                  onClick={() => {
                    if (allSelected) {
                      clearItems();
                      setAllSelected(false);
                    } else {
                      (rows || []).forEach((row) => {
                        if (canBeSelected(row, selectedAction)) {
                          addItem(getItemKey(row));
                        }
                      });
                      setAllSelected(true);
                    }
                  }}
                  checked={allSelected}
                />
              </th>
            )}
            {columnDefs.map((cd, headerNumber) => (
              <th
                style={{
                  ...cd.headerStyle,
                  cursor: cd.sortKey ? 'pointer' : 'inherit',
                }}
                key={`${id_}-header-${headerNumber}-col-${cd.title}`}
                className={`st-th ${cd.headerClassName}${
                  cd.sortKey === sort ? sortClass : ''
                }`}
                onClick={(_) => {
                  if (cd.sortKey) {
                    if (cd.sortKey !== sort) {
                      sortDirectionChanged('desc');
                      sortChanged(cd.sortKey);
                    } else if (cd.sortKey === sort) {
                      if (sortDirection === 'desc') {
                        sortDirectionChanged('asc');
                      } else {
                        sortDirectionChanged('desc');
                      }
                    }
                  }
                }}
              >
                {typeof cd.Header === 'function' ? cd.Header() : cd.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {(rows || []).map((row, rowNumber) => (
            <tr
              key={`${id_}-row-${rowNumber}`}
              className={`${
                !canBeSelected(row, selectedAction) && 'row-cannot-refund'
              }`}
            >
              {menuActions.length > 0 && (
                <td className="action-col">
                  <input
                    type="checkbox"
                    ref={(input) => {
                      if (input) {
                        if (!canBeSelected(row, selectedAction)) {
                          input.indeterminate = true;
                        } else {
                          input.indeterminate = false;
                        }
                      }
                    }}
                    onClick={() => {
                      if (canBeSelected(row, selectedAction)) {
                        if (selectedItems.has(getItemKey(row))) {
                          removeItem(getItemKey(row));
                          setAllSelected(false);
                        } else {
                          addItem(getItemKey(row));
                        }
                      }
                    }}
                    disabled={!canBeSelected(row, selectedAction)}
                    checked={selectedItems.has(getItemKey(row))}
                  />
                </td>
              )}
              {columnDefs.map((cd) => (
                <td
                  key={`${id_}-row-${rowNumber}-col-${cd.id}`}
                  style={cd.style}
                  className={`st-td ${cd.className}`}
                >
                  {typeof cd.Cell === 'function' ? cd.Cell(row) : cd.Cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={columnDefs.length + (menuActions.length > 0 ? 1 : 0)}>
              <div className="pagination">
                <button
                  disabled={page === 1 || loading}
                  onClick={async () => {
                    clearItems();
                    setAllSelected(false);
                    await offsetChanged(offset - limit);
                    window.scrollTo(0, 0);
                  }}
                >
                  Previous
                </button>
                <div>
                  Page {page} of {Math.max(nPages, 1)}
                </div>
                <button
                  disabled={page >= nPages || loading}
                  onClick={async () => {
                    clearItems();
                    setAllSelected(false);
                    await offsetChanged(offset + limit);
                    window.scrollTo(0, 0);
                  }}
                >
                  Next
                </button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default ServerTable;
