import React from 'react';

import { observer, inject } from 'mobx-react';
import classnames from 'classnames';
import useKeyboardEvent from '../../../hooks/useKeyboardEvent.js';
import AuthView from '../../../views/Login/AuthView/AuthView.js';

const LoginModal = ({ onToggleClose, isOpen, authStore, defaultTab }) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
    return null;
  });
  if (authStore.isAuthenticated) {
    return null;
  }
  return (
    <div
      className={classnames('modal login-modal', {
        'is-active': isOpen,
      })}
    >
      <div onClick={onToggleClose} className="modal-background"></div>
      <div className="modal-card confirm-modal">
        <section className="modal-card-body auth-modal" style={{ padding: 0 }}>
          <AuthView defaultTab={defaultTab} />
        </section>
      </div>
    </div>
  );
};

export default inject(
  'profileStore',
  'authStore',
  'donationCheckoutStore',
)(observer(LoginModal));
