import React from 'react';
import { navigate } from '@reach/router';
import Avatar from 'components/Avatar/Avatar';
import { shorten } from 'util/stringUtils';

const LikeEntity = ({ onToggleClose, name, avatarUrl, link }) => {
  console.log('avatarUrl', avatarUrl);
  const handleClick = () => {
    onToggleClose(false);
    navigate(link);
  };

  return (
    <div
      onClick={handleClick}
      className="event-host-item cursor-pointer flex gap-x-[10px] mb-[15px]"
    >
      <Avatar className="my-auto !w-12 !h-12" md avatarUrls={avatarUrl} />
      <div className="event-host-name text-text-light my-auto text-base font-agenda-bold">
        {shorten(name, 50)}
      </div>
    </div>
  );
};

export default LikeEntity;
