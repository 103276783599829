import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';

import mergeFeedItems from 'util/feed/mergeFeedItems';
import convertFeedToV1 from 'util/feed/convertFeedToV1';
import FeedItem from 'components/FeedItem/FeedItem';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import inboxEmpty from 'assets/images/illustrations/inbox-empty.png';

import useFollowingFeedStore from 'stores/FollowingFeedStore';

const FollowingFeed = ({
  authStore,
  uiStore,
  activeEntity,
  setShowLoginModal,
  title,
}) => {
  const followingFeedStore = useFollowingFeedStore();

  const onLike = ({ feedId }) => {
    if (!authStore.isAuthenticated) {
      uiStore.openModal('CONFIRM', {
        message: 'Please Login or Signup to like this post.',
        action: () => {
          setShowLoginModal(true);
        },
        confirmText: 'Login/Signup',
        cancelText: 'Not Now',
        bodyMessage:
          'Create your own Cauze account and join our community of givers.',
      });
      return;
    }

    const feed = followingFeedStore.feed;
    let feedIndex = -1;
    const feedItem = feed?.find((item, index) => {
      if (item?.activityPurchase?.id === feedId) {
        feedIndex = index;
        return true;
      }
      return false;
    });

    if (feedItem) {
      followingFeedStore.like({
        like: !feedItem.currentEntityLiked,
        id: feedId,
        index: feedIndex,
        purchaseId: feedItem.activityPurchase?.id,
        userContext: activeEntity.userContext,
      });
    }
  };

  useEffect(() => {
    followingFeedStore.getFeed();
  }, []);

  let feed = null;

  if (followingFeedStore.feedLoading) {
    feed = (
      <div className="loading-feed-items">
        <CauzeSpinner />
      </div>
    );
  } else if (followingFeedStore.feed.length > 0) {
    feed = (
      <div className="feed-wrapper">
        <div className="feed">
          {mergeFeedItems(followingFeedStore.feed).map((item, index) => (
            <FeedItem
              item={convertFeedToV1(item)}
              key={index}
              index={index}
              onLike={onLike}
              uiStore={uiStore}
              followingFeedStore={followingFeedStore}
            />
          ))}
        </div>
        <CauzeButton
          isSubmitting={followingFeedStore.feedLoadingMore}
          white
          onClick={followingFeedStore.loadMore}
          style={{ marginTop: '5rem' }}
        >
          Load More Activity
        </CauzeButton>
      </div>
    );
  } else {
    feed = (
      <div className="mx-auto font-agenda text-center max-w-80 pt-2.5">
        <img src={inboxEmpty} />
        <h5 className="mb-2.5 font-agenda-semibold text-[20px]">No Activity</h5>
        <p className="text-sm leading-none">
          As soon as you start following people or nonprofits, their activity
          will appear in your "Following" feed.
        </p>
      </div>
    );
  }

  return (
    <>
      {Boolean(title) && (
        <div className="mb-[10px] font-agenda-bold text-xl hidden lg:block">
          {title}
        </div>
      )}
      {feed}
    </>
  );
};

export default inject(
  'authStore',
  'uiStore',
)(observer((props) => <FollowingFeed {...props} />));
