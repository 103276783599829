import React from 'react';

const SectionHeader = ({ title, subtitle, style }) => {
  return (
    <div className="section-header-wrapper" style={style}>
      <h3 className="section-header-title">{title}</h3>
      <p className="section-header-subtitle">{subtitle}</p>
    </div>
  );
};

export default SectionHeader;
