import { useMemo } from 'react';
import cx from 'classnames';

import { onLogoutClick } from 'util/userUtils';
import { navigate } from '@reach/router';

import SidebarItem from './SidebarItem';

import { ReactComponent as HomeIcon } from 'assets/images/icons/svgs/home.svg';
import { ReactComponent as GiveIcon } from 'assets/images/icons/svgs/give.svg';
import { ReactComponent as PeopleIcon } from 'assets/images/icons/svgs/people.svg';
import { ReactComponent as DocDollarIcon } from 'assets/images/icons/svgs/document-dollar.svg';
import { ReactComponent as WalletIcon } from 'assets/images/icons/svgs/wallet.svg';
import { ReactComponent as GearIcon } from 'assets/images/icons/svgs/gear.svg';
import { ReactComponent as StarIcon } from 'assets/images/icons/star/star-outline.svg';
import { ReactComponent as GiftIcon } from 'assets/images/icons/gift/gift-box.svg';
import { ReactComponent as ReportingIcon } from 'assets/images/icons/svgs/reporting.svg';
import useMobile from 'hooks/useMobile';

const Sidebar = ({
  activeEntity,
  expanded,
  setExpanded,
  authStore,
  profileStore,
  eventStore,
}) => {
  const { isTablet } = useMobile();
  const primaryEntity = useMemo(() => {
    return profileStore.availableUserEntities?.[0];
  }, [profileStore.availableUserEntities]);

  const isPrimaryEntity = () => {
    return Boolean(
      primaryEntity &&
        activeEntity &&
        primaryEntity?.id === activeEntity?.id &&
        primaryEntity?.entityType === activeEntity?.entityType,
    );
  };

  const activeEntityId = activeEntity?.id;
  const entityType = activeEntity?.entityType?.toUpperCase() || '';
  const isCompany = entityType === 'COMPANY';
  const isCharity = entityType === 'CHARITY';
  const isInfluencer =
    entityType === 'INFLUENCER' ||
    (entityType === 'USER' && !isPrimaryEntity());
  const entityTypeForUrl = isInfluencer ? 'user' : entityType?.toLowerCase();

  const groupItems = useMemo(() => {
    return (
      profileStore.groupRoles?.map((entity) => ({
        label: entity.name,
        href: `/company/${entity.id}`,
      })) || []
    );
  }, [profileStore.groupRoles]);

  const adminItems = [
    {
      label: 'Companies',
      href: '/admin/companies',
    },
    {
      label: 'Charities',
      href: '/admin/charities',
    },
    {
      label: 'Influencers',
      href: '/admin/influencers',
    },
    {
      label: 'Users',
      href: '/admin/users',
    },
  ];

  const accountActivityItems = useMemo(() => {
    return profileStore.availableUserEntities.map((entity, index) => {
      let href = `/admin/user/${entity.id}/activity`;

      if (entity.entityType === 'CHARITY') {
        href = `/admin/charity/${entity.id}`;
      } else if (entity.entityType === 'COMPANY') {
        href = `/admin/company/${entity.id}`;
      } else if (entity.entityType === 'INFLUENCER') {
        href = `/admin/user/${entity.id}/activity`;
      }

      return {
        label: index === 0 ? 'Your Account' : entity.name,
        href: href,
      };
    });
  }, [profileStore.availableUserEntities]);

  const handleLogOut = () => {
    onLogoutClick(authStore, profileStore, eventStore);
    navigate('/login');
  };

  return (
    <aside
      className={cx(
        'cauze-sidebar py-12 border-solid border-0 border-r border-lightgray-f1f bg-white fixed lg:absolute transition-[width] lg:w-[320px] overflow-x-hidden overflow-y-auto z-[1000] lg:z-10',
        {
          'w-[95vw]': expanded,
          'w-0': !expanded,
          'pr-4': !isTablet,
        },
      )}
    >
      {!isCompany && !isCharity && (
        <SidebarItem
          icon={<HomeIcon />}
          label="Home"
          href="/donate"
          setExpanded={setExpanded}
        />
      )}

      {isCompany && (
        <SidebarItem
          icon={<HomeIcon />}
          label="Home"
          href={`/company/${activeEntityId}`}
          setExpanded={setExpanded}
        />
      )}
      {isCharity && (
        <SidebarItem
          icon={<HomeIcon />}
          label="Home"
          href={`/charity/${activeEntityId}`}
          setExpanded={setExpanded}
        />
      )}

      {isCompany && (
        <SidebarItem
          icon={<PeopleIcon />}
          label="Manage Employees"
          href={`/admin/company/${activeEntityId}`}
          setExpanded={setExpanded}
        />
      )}
      <SidebarItem
        icon={<StarIcon />}
        label="Discover"
        href="/discover"
        setExpanded={setExpanded}
      />

      {authStore.isAuthenticated && (
        <SidebarItem
          items={accountActivityItems}
          icon={<DocDollarIcon />}
          label="Account Activity"
          setExpanded={setExpanded}
        />
      )}

      {isCompany && (
        <>
          <SidebarItem
            icon={<ReportingIcon />}
            label="Reporting"
            href={`/admin/company/${activeEntityId}/reporting`}
            setExpanded={setExpanded}
          />
          <SidebarItem
            icon={<GiveIcon />}
            label="Matching"
            href={`/admin/company/${activeEntityId}/matching`}
            setExpanded={setExpanded}
          />
        </>
      )}

      {authStore.isAuthenticated && (
        <SidebarItem
          icon={<GiveIcon />}
          label="Your Cauzes"
          href={`/admin/${entityType.toLowerCase()}/${activeEntityId}/cauzes`}
          setExpanded={setExpanded}
        />
      )}

      {(isCompany || isInfluencer) && (
        <>
          <SidebarItem
            icon={<GiftIcon />}
            label="Gifting"
            href={`/admin/${entityType.toLowerCase()}/${activeEntityId}/gifts`}
            setExpanded={setExpanded}
          />
        </>
      )}

      {isCompany && (
        <>
          <SidebarItem
            icon={<WalletIcon />}
            label="Payroll Deductions"
            href={`/admin/company/${activeEntityId}/payroll`}
            setExpanded={setExpanded}
          />
        </>
      )}

      {authStore.isAuthenticated && (
        <>
          {!isCharity && (
            <SidebarItem
              icon={<WalletIcon />}
              label="Wallet"
              href={
                isCompany
                  ? `/admin/${entityTypeForUrl}/${activeEntityId}/wallet`
                  : `/admin/${entityTypeForUrl}/${activeEntityId}/activity?active-tab=1`
              }
              setExpanded={setExpanded}
            />
          )}
          {Boolean(groupItems.length) && (
            <SidebarItem
              items={groupItems}
              icon={<PeopleIcon />}
              label="Groups"
              setExpanded={setExpanded}
            />
          )}
        </>
      )}

      {profileStore.data?.sysAdmin && (
        <SidebarItem
          items={adminItems}
          icon={<GearIcon />}
          label="Admin"
          setExpanded={setExpanded}
        />
      )}

      <div className="h-5" />

      {authStore.isAuthenticated && (
        <SidebarItem
          nested
          label="Logout"
          onClick={handleLogOut}
          setExpanded={setExpanded}
        />
      )}

      <SidebarItem
        nested
        label="Feedback"
        href="mailto:feedback@cauze.com?subject=Feedback%20on%20Cauze"
        setExpanded={setExpanded}
      />
      <SidebarItem
        nested
        label="About Cauze"
        href="https://www.cauze.com/about"
        target="_blank"
        setExpanded={setExpanded}
      />
      <SidebarItem
        nested
        label="FAQ"
        href="https://www.cauze.com/faqs"
        target="_blank"
        setExpanded={setExpanded}
      />
    </aside>
  );
};

export default Sidebar;
