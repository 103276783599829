import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';

import useCompanyAdmin from 'hooks/useCompanyAdmin';
import withUserContext from 'behaviors/withUserContext';
import withAuthRequired from 'behaviors/withAuthRequired';

import UpdateGiftsModal from 'components/modals/UpdateGiftsModal/UpdateGiftsModal';
import UserTable from 'components/UserTable/UserTable';
import Button from 'components/Button/Button';
import useDashboardStore from 'stores/DashboardStore';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const PendingInvitesView = ({
  profileStore,
  companyAdminStore,
  companyId,
  uiStore,
}) => {
  const [showSendGiftModal, setShowSendGiftModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const { setProps } = useDashboardStore();

  useCompanyAdmin(companyId);
  const activeEntity = profileStore.getActiveEntity();
  const pendingInviteActions = [
    {
      name: 'Remove Invite',
      action: (ids) => {
        let unredeemedIds = ids.map((id) => id.replace('unredeemed-', ''));
        if (unredeemedIds.length > 0) {
          companyAdminStore.revokeUserTokens({ ids: unredeemedIds });
        }
      },
    },
    {
      name: 'Send Invite',
      action: (ids) => {
        let unredeemedIds = ids.map((id) => id.replace('unredeemed-', ''));
        if (unredeemedIds.length > 0) {
          companyAdminStore.resendCompanyInvites({
            ids: unredeemedIds,
            companyId,
          });
        }
      },
    },
    {
      name: 'Update Gift',
      action: (ids) => {
        setSelectedIds(ids);
        setShowSendGiftModal(true);
      },
    },
  ];

  useEffect(() => {
    setProps({
      title: 'Pending Invites',
    });
  }, []);

  if (companyAdminStore.loading) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div className="company-admin pending-invites-view flex-column">
      <div>
        {showSendGiftModal && (
          <UpdateGiftsModal
            selectedIds={selectedIds}
            isOpen
            activeEntity={activeEntity}
            updateGiftAmount
            companyAdminStore={companyAdminStore}
            companyId={companyId}
            onToggleClose={() => setShowSendGiftModal(false)}
            uiStore={uiStore}
          />
        )}
        <section>
          <div className="page-header container flex-row">
            {companyAdminStore.pending.length > 0 && (
              <div className="flex-row">
                <Button
                  className="is-small invite-button"
                  onClick={() => {
                    companyAdminStore.resendCompanyInvites({
                      ids: companyAdminStore.pending.map(
                        (user) => user.tokenId,
                      ),
                      companyId,
                    });
                    navigate('/admin');
                  }}
                >
                  Send All
                </Button>
                <Button
                  className="is-small"
                  onClick={() =>
                    uiStore.openModal('SEND_GIFTS', {
                      giftType: 'EMPLOYEE_GIFT',
                      isInviteFollowerExperience: true,
                      companyId,
                    })
                  }
                >
                  Add
                </Button>
              </div>
            )}
          </div>
        </section>
        <section>
          <div className="container">
            {companyAdminStore.pending.length > 0 ? (
              <UserTable
                totalUsersBalance={companyAdminStore.totalUsersBalance}
                companyEligibleMatch={companyAdminStore.companyEligibleMatch}
                companyRemainingMatch={companyAdminStore.companyRemainingMatch}
                actions={pendingInviteActions}
                users={companyAdminStore.pending}
                tableType="pending"
                noFilter
                store={companyAdminStore}
              />
            ) : (
              <div className="container flex-column flex-center flex-expand">
                <p>You have no pending invites.</p>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default withAuthRequired(
  withUserContext(
    inject(
      'profileStore',
      'companyAdminStore',
      'uiStore',
    )(observer(PendingInvitesView)),
  ),
  { entityType: 'COMPANY' },
);
