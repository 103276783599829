import React from 'react';
import matchSorter from 'match-sorter';
import { TableCurrency } from '../UserTable/components';
import { MatchStatus, MatchName } from './components';
import Currency from 'components/Currency/Currency';
import ColumnHeader from 'components/ColumnHeader/ColumnHeader';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

/* a column's cell property passes props.original to the render function, which describes the whole
match for that row. props.value sends the specific key on the match object defined in 'accessor' */

const matchColumns = (
  onEdit,
  onDisable,
  onDownloadMatchLedger,
  userContext,
  isGroup,
  onViewSponsors,
) => [
  {
    id: 'name',
    accessor: 'name',
    className: 'col-left col-wrap col-match-name flex-justify-left',
    Header: () => <ColumnHeader title="Name" classNames="col-header" />,
    headerClassName: 'background-grey',
    filterAll: true,
    minWidth: 220,
    Cell: MatchName,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: ['name', '_original.charityNames'],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    id: 'status',
    Header: () => <ColumnHeader title="Status" classNames="col-header" />,
    accessor: 'matchStatus',
    className: 'col-center is-hidden-mobile',
    headerClassName: 'col-center is-hidden-mobile background-grey',
    Cell: MatchStatus,
    style: {
      textAlign: 'center',
      padding: '2rem',
    },
    minWidth: 160,
    filterMethod: (filter, row) => {
      if (filter.value.indexOf('all') > -1) {
        return true;
      }
      if (filter.value.indexOf('live') > -1) {
        return row[filter.id] === 'live';
      }
      if (filter.value.indexOf('future') > -1) {
        return row[filter.id] === 'future';
      }
      if (filter.value.indexOf('disabled') > -1) {
        return row[filter.id] === 'disabled';
      }
      return row[filter.id] === -1;
    },
  },
  {
    id: 'expand',
    expander: true,
    className: 'is-hidden-desktop',
    headerClassName: 'is-hidden-desktop background-grey',
  },
  // {
  //   id: 'charityNames',
  //   Header: () => (
  //     <ColumnHeader title="Eligible Givers" classNames="col-header" />
  //   ),
  //   accessor: 'charityNames',
  //   className: 'col-wrap is-hidden-mobile is-hidden-tablet-only',
  //   headerClassName: 'col-wrap is-hidden-mobile is-hidden-tablet-only',
  //   Cell: props => (
  //     <div>
  //       {props.value.slice(0, 3).map((charity, index) => (
  //         <span key={`charity-${index}`} className="eligible-charity-name">
  //           {charity}
  //         </span>
  //       ))}
  //       {props.value.length > 3 && <span>&hellip;</span>}
  //     </div>
  //   ),
  //   style: {
  //     textAlign: 'left',
  //   },
  // },

  {
    id: 'giverMax',
    Header: () => (
      <ColumnHeader title="Max Per Giver" classNames="col-header" />
    ),
    accessor: 'userMatchLimit',
    className: 'col-wrap is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only background-grey',
    minWidth: 80,
    Cell: TableCurrency,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'eligibleGivers',
    Header: () => (
      <ColumnHeader title="Eligible Givers" classNames="col-header" />
    ),
    accessor: 'matchType',
    className: 'col-wrap is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only background-grey',
    Cell: (props) => (
      <span>
        {props.value === 'COMPANY'
          ? isGroup
            ? 'Members'
            : 'Employees'
          : 'Anyone'}
      </span>
    ),
    style: {
      textAlign: 'center',
    },
    filterMethod: (filter, row) => {
      if (filter.value.indexOf('all') > -1) {
        return true;
      }
      if (filter.value.indexOf('anyone') > -1) {
        return row[filter.id] === 'EVENT';
      }
      return row[filter.id] === 'COMPANY';
    },
  },
  {
    id: 'companyMax',
    Header: () => <ColumnHeader title="Used Amount" classNames="col-header" />,
    accessor: 'matchTotal',
    className: 'col-wrap is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only background-grey',
    Cell: ({ value, original, showCents }) => (
      <div className="flex-column flex-grow flex-align-center">
        <Currency
          amount={value - original.sponsorMatchRemaining || 0}
          showCents={showCents || false}
        />
        {original.sponsorCount > 1 && (
          <a
            className="sponsor-count"
            onClick={() => onViewSponsors(original.matchSponsors)}
          >
            ({original.sponsorCount} sponsors)
          </a>
        )}
      </div>
    ),
    minWidth: 100,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'companyRemaining',
    Header: () => (
      <ColumnHeader title="Remaining Match" classNames="col-header" />
    ),
    accessor: 'sponsorMatchRemaining',
    className: 'col-wrap is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only background-grey',
    Cell: TableCurrency,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'companyTotal',
    Header: () => <ColumnHeader title="Total Match" classNames="col-header" />,
    accessor: 'matchTotal',
    className: 'col-wrap is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile is-hidden-tablet-only background-grey',
    Cell: TableCurrency,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'edit',
    // Header: () => <ColumnHeader title="Edit" classNames="col-header" />,
    accessor: 'id',
    className: 'is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'is-hidden-mobile background-grey',
    Cell: (props) => {
      const { buttonProps, itemProps, isOpen } = useDropdownMenu(3);
      return (
        <div className="flex-column flex-center">
          <div className="settings-dots" {...buttonProps}>
            <span className="icon is-small">
              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
          </div>
          <div className={isOpen ? 'visible flex-column' : ''} role="menu">
            {props.original.sponsorCount < 2 && (
              <a {...itemProps[0]} onClick={() => onEdit(props.original)}>
                Edit
              </a>
            )}
            {(props.original.matchStatus === 'live' ||
              (props.original.matchStatus === 'future' && // active matches only
                props.original.matchAdmin.id === userContext.id)) && (
              <a
                {...itemProps[1]}
                onClick={() => onDisable(props.value, props.original.active)}
              >
                Disable
              </a>
            )}
            <a
              {...itemProps[2]}
              onClick={() => onDownloadMatchLedger(props.value)}
            >
              Match Ledger CSV
            </a>
          </div>
        </div>
      );
    },
    style: {
      textAlign: 'right',
    },
  },
];

export default matchColumns;
