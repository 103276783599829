import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';

import useUsers from 'hooks/useUsers';
import withAuthRequired from 'behaviors/withAuthRequired';
import withUserContext from 'behaviors/withUserContext';

import Button from 'components/Button/Button';
import UsersTable from 'components/Admin/UsersTable';
import useDashboardStore from 'stores/DashboardStore';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const UsersView = ({ usersStore, uiStore }) => {
  const { setProps } = useDashboardStore();
  const goToUser = (_state, rowInfo, _column, _instance) => ({
    onClick: (_e, _handleOriginal) => {
      navigate(`/admin/user/${rowInfo.original.id}/profile`);
    },
  });

  useUsers();

  useEffect(() => {
    setProps({
      size: 'screen',
      bodyClassName: 'bg-lightgray-e0e',
    });
  }, []);

  if (usersStore.loading) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div className="company-reporting flex-column">
      <div>
        <section>
          <div className="page-header flex-row">
            <h1 className="title">Users</h1>
            <Button
              style={{ marginLeft: 200 }}
              className="is-small"
              onClick={() =>
                uiStore.openModal('USER_SEARCH', {
                  title: 'Add a User',
                  buttonText: 'Add User',
                  onConfirm: ({ email, userId }) =>
                    usersStore.addUser({ email, userId }),
                })
              }
            >
              Add User
            </Button>
          </div>
        </section>
        <section>
          <div className="flex-justify-center">
            {usersStore.users.length > 0 && <UsersTable goToUser={goToUser} />}
          </div>
        </section>
      </div>
    </div>
  );
};

export default withAuthRequired(
  withUserContext(
    inject('profileStore', 'usersStore', 'uiStore')(observer(UsersView)),
  ),
  { entityType: 'ADMIN' },
);
