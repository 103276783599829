import React from 'react';
import { navigate } from '@reach/router';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import Avatar from 'components/Avatar/Avatar';

import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import useMobile from 'hooks/useMobile';

const AvatarListItem = ({ avatar }) => {
  return (
    <div
      className="avatar-item"
      key={`${avatar.id}`}
      onClick={() => navigate(`/profile/${avatar.id}`)}
    >
      <Avatar
        noBorder
        md
        avatarUrls={avatar?.avatarUrls}
        avatar={avatar?.avatar}
      />
      <div>
        <div className="avatar-item-name">{avatar?.firstName}</div>
        <div className="avatar-item-name">{avatar?.lastName}</div>
      </div>
    </div>
  );
};

const AvatarList = ({ avatars, loading, isSelf, uiStore }) => {
  const { isMobile } = useMobile();

  if (loading) {
    return (
      <div className="avatar-list">
        <CauzeSpinner />
      </div>
    );
  }

  return (
    <Swiper
      className={cx('avatar-list', {
        'show-arrows': avatars && avatars.length > 7,
      })}
      navigation
      modules={[Navigation]}
      spaceBetween={1}
      slidesPerView={isMobile ? 4 : 7.5}
    >
      {avatars.map((avatar) => (
        <SwiperSlide key={avatar.id}>
          <AvatarListItem avatar={avatar} isSelf={isSelf} uiStore={uiStore} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default AvatarList;
