import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';

import LoginModal from 'components/modals/LoginModal/LoginModal';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import ProfileHeader from 'components/profiles/ProfileHeader';
import FeedItem from 'components/FeedItem/FeedItem';
import EventList from 'components/EventList/EventList';
import CharityList from 'components/CharityList/CharityList';
import Button from 'components/Button/Button';
import Currency from 'components/Currency/Currency';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import AvatarList from 'components/AvatarList/AvatarList';

import { ReactComponent as PencilIcon } from 'assets/images/icons/svgs/pencil.svg';
import useDashboardStore from 'stores/DashboardStore';

const EntityProfile = ({
  featuredProjects = [],
  loading,
  entityData,
  isAuthenticated,
  isInfluencer,
  onFollow,
  onLike,
  groupMember,
  uiStore,
  events = [],
  eventsLoading,
  feedLoading,
  feedLoadMore,
  feedLoadingMore,
  feed,
  entityType,
  isVerified,
  hideFollowerCount,
  showEditButton,
  onPin,
  companyMembers,
  store,
  showMembers,
  isSelf,
  onRearrange,
}) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { setProps } = useDashboardStore();
  const canLoadMore = true;

  const handleLike = ({ feedId }) => {
    if (!isAuthenticated) {
      uiStore.openModal('CONFIRM', {
        message: 'Please Login or Signup to like this post.',
        action: () => {
          setShowLoginModal(true);
        },
        confirmText: 'Login/Signup',
        cancelText: 'Not Now',
        bodyMessage:
          'Create your own Cauze account and join our community of givers.',
      });
    } else {
      onLike({ feedId });
    }
  };

  const handleSeeAll = () => {
    uiStore.openModal('ENTITY_LIST', {
      title: `${
        entityData.isCurrentEntity ? 'Your' : entityData.name
      }'s Members (${companyMembers.length})`,
      showFollow: true,
      entities: companyMembers?.map((member) => ({
        ...member,
        entityType: 'USER',
        name: `${member.firstName} ${member.lastName}`,
        isActive: true,
      })),
      buttonLabel: 'Follow',
      isSelf: entityData.isCurrentEntity,
      store: store,
      isAuthenticated: isAuthenticated,
      hasSearch: true,
      confirmMessage: 'PLEASE LOGIN OR SIGNUP TO FOLLOW MEMBERS',
    });
  };

  useEffect(() => {
    setProps({
      size: 'half',
    });
  }, []);

  if (loading || !entityData?.id) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div>
      <LoginModal
        isOpen={showLoginModal}
        onToggleClose={() => setShowLoginModal(false)}
      />
      <div className="profile-view-container">
        <div className="profile-view">
          <div>
            <ProfileHeader
              isVerified={isVerified}
              entityType={entityType}
              id={entityData.id}
              username={entityData.username}
              hero={entityData.hero}
              name={entityData.name}
              avatar={entityData.avatar}
              followerCount={entityData.followerCount}
              followingCount={entityData.followingCount}
              shareLink={entityData.linkUrl || entityData.shareLink}
              isSelfFollowing={entityData.isSelfFollowing}
              onFollow={onFollow}
              hideFollowerCount={hideFollowerCount}
              isAuthenticated={isAuthenticated}
              isInfluencer={isInfluencer}
              bio={entityData.bio}
              showEditButton={showEditButton}
              hasRecurringDeposit={entityData.hasRecurringDeposit}
              socialLinks={entityData.userSocials}
              profileData={entityData.profileData}
              foundation={null}
              webUrl={entityData.webUrl}
              isSelf={entityData.isCurrentEntity}
              email={entityData.email}
              activeEntity={entityData.activeEntity}
            />
            {groupMember && (
              <div className="flex-column flex-center">
                <div className="group-balance">
                  Group Balance: <Currency amount={groupMember.balance.total} />
                </div>
                <Button
                  style={{ marginBottom: '2rem' }}
                  className="is-medium"
                  onClick={() =>
                    uiStore.openModal('FUND_GROUP', {
                      groupId: entityData.id,
                    })
                  }
                >
                  Add Funds to Group
                </Button>
              </div>
            )}
            {(eventsLoading || (events && events.length > 0)) && (
              <>
                <div className="profile-view-cauzes-title">
                  {entityData.isCurrentEntity ? 'Your' : `${entityData.name}'s`}{' '}
                  Cauzes
                </div>
                <EventList
                  events={events}
                  loading={eventsLoading}
                  onPin={onPin}
                  isSelf={entityData.isCurrentEntity}
                  uiStore={uiStore}
                />
              </>
            )}
            {showMembers && (
              <>
                <div className="profile-view-cauzes-title">
                  <div className="profile-view-see-all">
                    <div>
                      {entityData.isCurrentEntity
                        ? 'Your'
                        : `${entityData.name}'s`}{' '}
                      Members ({companyMembers.length})
                    </div>
                    <div className="see-all" onClick={handleSeeAll}>
                      See All
                    </div>
                  </div>
                </div>
                <AvatarList avatars={companyMembers} />
              </>
            )}
            {featuredProjects && featuredProjects.length > 0 && (
              <>
                <div className="profile-view-cauzes-title-container">
                  <div className="profile-view-cauzes-title">
                    {entityData.isCurrentEntity
                      ? 'Your'
                      : `${entityData.name}'s`}{' '}
                    Favorite Nonprofits
                  </div>
                  {isSelf && (
                    <div
                      className="profile-view-edit-button"
                      onClick={() => {
                        uiStore.openModal('FAVORITE_NONPROFITS', {
                          charities: featuredProjects,
                          onRearrange,
                        });
                      }}
                    >
                      <span style={{ marginRight: 4, marginLeft: 8 }}>
                        <PencilIcon width="12" height="12" />
                      </span>
                      <span>EDIT</span>
                    </div>
                  )}
                </div>
                <CharityList
                  onLearnMore={(id) => navigate(`/charity/${id}`)}
                  charities={featuredProjects}
                />
              </>
            )}
            {!feedLoading ? (
              feed && (
                <>
                  <div className="profile-view-cauzes-title">
                    {entityData.isCurrentEntity
                      ? 'Your'
                      : `${entityData.name}'s`}{' '}
                    {entityData.isCurrentEntity ? 'Feed' : 'Activity'}
                  </div>
                  <div className="feed">
                    {feed.map((item, index) => (
                      <FeedItem
                        key={`${item.date}-${item.actor.id}-${index}`}
                        item={item}
                        index={index}
                        onLike={handleLike}
                        entity={{
                          ...entityData,
                          groupType: 'USER',
                        }}
                      />
                    ))}
                  </div>
                  <div className="flex-row flex-justify-center pt-4">
                    {canLoadMore && (
                      <CauzeButton
                        white
                        large
                        isSubmitting={feedLoadingMore}
                        onClick={feedLoadMore}
                      >
                        Load More Results
                      </CauzeButton>
                    )}
                  </div>
                </>
              )
            ) : (
              <CauzeSpinner />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntityProfile;
