import React, { useEffect } from 'react';

import CauzesView from './CauzesView';
import withAuthRequired from 'behaviors/withAuthRequired';
import useCauzes from 'hooks/useCauzes';
import useDashboardStore from 'stores/DashboardStore';

const InfluencerCauzesView = ({ influencerId }) => {
  const { setProps } = useDashboardStore();
  useCauzes({ userContext: { userId: influencerId } });

  useEffect(() => {
    setProps({
      size: 'screen',
      bodyClassName: 'bg-lightgray-e0e',
    });
  }, []);

  return <CauzesView userContext={{ userId: influencerId }} />;
};

export default withAuthRequired(InfluencerCauzesView, {
  entityType: 'INFLUENCER',
});
