import { useMemo, useState } from 'react';
import { navigate } from '@reach/router';

import CharityList from 'components/CharityList/CharityList';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import pinDrop from 'assets/images/icons/pin/pin-drop.png';

export const PopularNonprofitsSection = ({
  parsedLocationString,
  user,
  userLoading,
  localEntities,
  localLoading,
  onApply,
}) => {
  const locationDisplay = parsedLocationString || user?.zip;
  const [zip, setZip] = useState('');

  const renderContent = useMemo(() => {
    if (userLoading && !user?.id) {
      return <CauzeSpinner />;
    }

    if (localLoading) {
      return (
        <div className="flex gap-x-2">
          <div className="h-[170px] w-[160px] rounded-[10px] bg-lightgray-e8e animate-pulse" />
          <div className="h-[170px] w-[160px] rounded-[10px] bg-lightgray-e8e animate-pulse" />
          <div className="h-[170px] w-[160px] rounded-[10px] bg-lightgray-e8e animate-pulse" />
          <div className="h-[170px] w-[160px] rounded-[10px] bg-lightgray-e8e animate-pulse" />
        </div>
      );
    }

    if (!user?.zip) {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onApply?.(zip);
            return false;
          }}
          className="flex flex-col gap-y-3 bg-lightgray-f7f rounded-[0.625rem] px-8 pt-4 pb-8 text-center font-agenda"
        >
          <img
            src={pinDrop}
            className="w-12 mx-auto rounded-full bg-white p-2"
          />
          <div className="font-agenda-bold text-[26px]">
            Where in the world are you?
          </div>
          <div className="text-lg">
            Find local cauzes, and help your friends find you.
          </div>
          <input
            className="p-2 rounded-[10px] border border-lightgray-e0e border-solid"
            placeholder="Enter Zip"
            value={zip}
            onChange={(e) => {
              setZip(e.target.value);
            }}
          />
          <button className="cz-btn-brand mt-8" type="submit">
            Apply
          </button>
        </form>
      );
    }

    return (
      <div className="discover-charity-list">
        <CharityList
          charities={localEntities}
          mobileSlidePerView={2.1}
          onSelect={(charityId) => {
            navigate(`/charity/${charityId}`);
          }}
        />
      </div>
    );
  }, [localEntities, userLoading, user, localLoading, zip]);

  console.log(user);

  if (!user) return <></>;

  return (
    <>
      <div className="text-[22px] leading-none mt-8 mb-5">
        <span className="font-agenda-bold">Popular Local Nonprofits</span>{' '}
        {Boolean(locationDisplay) && (
          <span className="font-agenda">
            ({parsedLocationString || user?.zip})
          </span>
        )}
      </div>
      {renderContent}
    </>
  );
};
