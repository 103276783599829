import { navigate } from '@reach/router';
import { getEntityUrl } from 'util/navHelpers';

const getSubject = (subjects, type) => {
  if (subjects.length === 0 || type === 'REFUND') return;

  // This covers the case of single subject items, and non-event purchases which have
  // a charity subject and a purchase subject, which share a name
  if (subjects.length === 1 || subjects.length === 2) return subjects[0]?.name;

  // Event purchases, which have a charity subject, purchase subject, and event subject
  if (subjects.length >= 3) {
    return subjects.filter((subject) => subject.subjectType === 'EVENT')[0]
      ?.name;
  }
};

const getLegacyCardNav = (item, navigation, markNotificationRead) => {
  const eventId = item.subjects.filter(
    (subject) => subject.subjectType === 'EVENT',
  )[0]?.id;
  const entity = item.actors?.[0];
  const entityUrl = getEntityUrl({
    id: entity.id,
    entityType: entity.entityType || entity.__typename,
  });

  markNotificationRead();

  switch (item.type) {
    case 'USER_FOLLOWER': {
      return navigate(entityUrl);
    }
    case 'CAUZE_PURCHASE':
      if (eventId) {
        return navigate(`/event/${eventId}`);
      }
      return;
    case 'USER_GIFT':
      return navigate(entityUrl);
    case 'EMPLOYEE_GIFT':
      return navigate(entityUrl);

    default:
      return null;
  }
};

const getLegacyVerbByType = (type) => {
  switch (type) {
    case 'USER_FOLLOWER':
      return 'followed you';

    case 'CAUZE_PURCHASE':
      return 'supported';

    case 'CAUZE_COMMENT':
      return 'commented on your post for';

    case 'USER_GIFT':
      return 'gifted you';

    case 'EMPLOYEE_GIFT':
      return 'gifted you';

    default:
      return '';
  }
};

export { getLegacyCardNav, getLegacyVerbByType, getSubject };
