import mixpanel from 'mixpanel-browser';
import { navigate } from '@reach/router';

const getUserNameDefault = (user) => {
  if (!user) return;
  if (user.firstName) return user.firstName;

  if (user.username) return user.username;

  if (user.name) return user.name;
};

const onLogoutClick = async (authStore, profileStore, eventStore) => {
  mixpanel.track('User Drop Down Logout');
  await eventStore.onUnmount();
  await profileStore.onUnmount();
  await authStore.changeAuthentication();
  navigate(`/login`);
};

export { getUserNameDefault, onLogoutClick };
