import React, { useState, useMemo } from 'react';
import { observer, inject } from 'mobx-react';
import scriptLoader from 'react-async-script-loader';
import { navigate } from '@reach/router';

import useKeyboardEvent from 'hooks/useKeyboardEvent.js';
import useDonationCheckout from 'hooks/useDonationCheckout';
import withUserContext from 'behaviors/withUserContext';
import { checkoutFormValidate as validate } from 'util/formFieldValidators';
import { getThnksCompanyId } from 'util/cobrandUtils.js';

import Avatar from 'components/Avatar/Avatar';
import Currency from 'components/Currency/Currency';
import ErrorModal from 'components/modals/ErrorModal';
import CheckoutInputNew from 'views/DonationCheckout/CheckoutInputNew';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import dompurify from 'dompurify';

import classnames from 'classnames';

const CheckoutWithGiftModal = ({
  onToggleClose,
  isOpen,
  eventId,
  charityId,
  charityIds,
  giftToken,
  giftAmount,
  firstName,
  lastName,
  email,
  profileStore,
  donationUnauthedCheckoutStore,
  uiStore,
  activeEntity,
  initiatorId,
  joinPurchaseId,
  hideAmount,
  matchIds,
}) => {
  const [validation, setValidation] = useState({
    firstName: true,
    lastName: true,
    email: true,
    card: true,
    isValid: true,
  });

  useDonationCheckout({
    eventId,
    charityId,
    emailIsPrivate: profileStore?.data?.emailIsPrivate,
    giftToken,
    giftAmount,
    firstName,
    lastName,
    email,
    activeEntity,
    joinPurchaseId,
    charityIds: charityIds || [charityId],
  });
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });
  useKeyboardEvent('Enter', () => {}, true);
  const sanitizer = dompurify.sanitize;
  // thnks Id
  const isThnks = initiatorId === getThnksCompanyId();

  const checkoutDetails = donationUnauthedCheckoutStore.checkoutDetails;

  const renderHeroLocation = useMemo(() => {
    if (!Array.isArray(checkoutDetails?.charities)) return '';

    const displayValues = [
      checkoutDetails.charities[0]?.city,
      checkoutDetails.charities[0]?.state,
    ];

    return displayValues.filter((d) => d).join(', ');
  }, [checkoutDetails]);

  if (donationUnauthedCheckoutStore.checkoutLoading) {
    return (
      <div
        className={classnames('modal checkout-with-gift-modal checkout', {
          'is-active': isOpen,
        })}
      >
        <div
          className="modal-background"
          onClick={() => {
            onToggleClose(false);
          }}
        ></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <button
              className="delete"
              aria-label="close"
              onClick={onToggleClose}
            ></button>
            <div className="flex-column flex-expand">
              <CauzeSpinner fullscreen />
            </div>
          </section>
        </div>
      </div>
    );
  }

  const currentCheckout = donationUnauthedCheckoutStore.currentCheckout;
  const title = checkoutDetails.event
    ? checkoutDetails.event.name
    : checkoutDetails.charities[0]?.name;
  const isEvent = checkoutDetails.event;
  const avatarImage = !checkoutDetails.event
    ? checkoutDetails.charities[0]?.avatar
    : null;

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    const validationResult = validate({
      firstName: currentCheckout.firstName,
      lastName: currentCheckout.lastName,
      email: currentCheckout.email,
      charityIds: currentCheckout.charityIds,
      charityId,
    });

    if (validationResult.isValid || isThnks) {
      donationUnauthedCheckoutStore.updateCheckout({ matchIds });

      donationUnauthedCheckoutStore
        .startGiftCheckout()
        .then(() => donationUnauthedCheckoutStore.completeGiftCheckout())
        .then((success) => {
          if (success) {
            uiStore.saveUiState({ userToken: null });
            onToggleClose();
            navigate('/checkout/gift-success');
          }
        });
    } else {
      // events.checkoutValidationError();
      setValidation(validationResult);
    }
  };

  return (
    <div
      className={classnames('modal checkout-with-gift-modal checkout', {
        'is-active': isOpen,
      })}
    >
      <div
        className="modal-background"
        onClick={() => {
          onToggleClose(false);
        }}
      ></div>
      <form onSubmit={handleSubmit} className="modal-card">
        {donationUnauthedCheckoutStore.showErrorModal && (
          <ErrorModal
            isOpen
            errorText={donationUnauthedCheckoutStore.errorModalText}
            buttonText={donationUnauthedCheckoutStore.errorModalButtonText}
            onToggleClose={donationUnauthedCheckoutStore.onErrorModalClose}
          />
        )}
        <section className="modal-card-body">
          <div className={'flex-row hero'}>
            {!isEvent && (
              <Avatar md entityType="CHARITY" avatar={avatarImage} />
            )}
            <div className="hero-details flex-column flex-start">
              <div className="hero-title">{title}</div>
              {isEvent && (
                <>
                  {checkoutDetails?.event?.hosts?.length > 0 && (
                    <p className="hosted-by">
                      Hosted By: {checkoutDetails.event.hosts[0].name}
                    </p>
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(checkoutDetails.event.description, {
                        ADD_ATTR: ['target'],
                      }),
                    }}
                    className="hero-description"
                  />
                </>
              )}
              {!isEvent && (
                <>
                  <div className="hero-location">{renderHeroLocation}</div>
                  <div className="hero-description">
                    {checkoutDetails.charities[0]?.bio || ''}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex-column">
            <div className="checkout-details">
              <div className="form-container">
                <div className="input-group">
                  <p className="details-title">
                    {isThnks ? 'Donate Your Thnks' : `Donate Your Gift`}
                  </p>
                  <div className="form-row">
                    <CheckoutInputNew
                      value={currentCheckout.firstName}
                      fieldTitle={`First Name${isThnks ? ' (optional)' : ''}`}
                      onChange={(ev) => {
                        ev.preventDefault();
                        donationUnauthedCheckoutStore.updateCheckout({
                          firstName: ev.target.value,
                        });
                        setValidation({ ...validation, firstName: true });
                      }}
                      isError={!validation.firstName}
                      errorText="First name required"
                    />
                    <CheckoutInputNew
                      value={currentCheckout.lastName}
                      fieldTitle={`Last Name${isThnks ? ' (optional)' : ''}`}
                      onChange={(ev) => {
                        ev.preventDefault();
                        donationUnauthedCheckoutStore.updateCheckout({
                          lastName: ev.target.value,
                        });
                        setValidation({ ...validation, lastName: true });
                      }}
                      isError={!validation.lastName}
                      errorText="Last name required"
                    />
                  </div>
                  <div className="form-row" style={{ marginBottom: '0' }}>
                    <CheckoutInputNew
                      value={currentCheckout.email}
                      fieldTitle={`Email${isThnks ? ' (optional)' : ''}`}
                      className="email-input"
                      onChange={(ev) => {
                        ev.preventDefault();
                        donationUnauthedCheckoutStore.updateCheckout({
                          email: ev.target.value,
                        });
                        setValidation({ ...validation, email: true });
                      }}
                      isError={!validation.email}
                      errorText="Valid email required"
                      style={{ width: '50%', paddingRight: '1rem' }}
                    />
                  </div>
                  {donationUnauthedCheckoutStore.showMarketingOption && (
                    <div style={{ display: 'block' }}>
                      <input
                        style={{
                          height: 'unset',
                          marginRight: '1rem',
                          marginLeft: '3px',
                        }}
                        type="checkbox"
                        checked={!currentCheckout.emailIsPrivate}
                        onChange={() =>
                          donationUnauthedCheckoutStore.updateCheckout({
                            emailIsPrivate: !currentCheckout.emailIsPrivate,
                          })
                        }
                      />
                      Share my email with{' '}
                      {checkoutDetails.charities.length > 1 ? 'these' : 'this'}{' '}
                      nonprofit
                      {checkoutDetails.charities.length > 1 ? 's' : ''}.
                    </div>
                  )}
                  <div
                    className="form-row columns"
                    style={{ marginTop: '2rem' }}
                  >
                    <textarea
                      onInput={(input) =>
                        donationUnauthedCheckoutStore.updateCheckout({
                          comment: input.currentTarget.value,
                        })
                      }
                      className="message"
                      id="message"
                      placeholder="Message"
                      rows="5"
                      cols="70"
                    ></textarea>
                  </div>
                  <p className="legal-text">
                    Your donation is being made to the Cauze Charitable Fund, a
                    tax-exempt US 501c(3) charity that grants funds to charities
                    on your behalf.
                    <a href="https://www.cauze.com/terms-of-service">
                      {' '}
                      SEE TERMS
                    </a>
                    .
                  </p>
                  <div className="flex-row flex-align-center flex-expand">
                    <CauzeButton
                      onClick={handleSubmit}
                      isSubmitting={
                        donationUnauthedCheckoutStore.awaitTokenCreation ||
                        donationUnauthedCheckoutStore.awaitCompleteCheckout
                      }
                      large
                    >
                      Give {!hideAmount && <Currency amount={giftAmount} />}
                    </CauzeButton>
                    <CauzeButton
                      onClick={onToggleClose}
                      large
                      noBorder
                      style={{ marginLeft: '0.5rem' }}
                      white
                    >
                      Cancel
                    </CauzeButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
};

export default scriptLoader(
  'https://www.google.com/recaptcha/api.js?render=6Lc-e08jAAAAAEZ7BSXi10cVWgb2G_K2FoxSGsBD',
)(
  withUserContext(
    inject(
      'profileStore',
      'authStore',
      'donationUnauthedCheckoutStore',
      'uiStore',
    )(observer(CheckoutWithGiftModal)),
  ),
);
