import React from 'react';
// import { Redirect, navigate } from '@reach/router';
import Navbar from 'components/page/Navbar/Navbar';
import FooterNew from 'components/page/Footer/FooterNew';

const NotFoundView = () => (
  <div className="not-found-view h-screen flex flex-col overflow-y-auto">
    <Navbar />
    <div className="section flex-column flex-expand flex-1">
      <p className="not-found-message">
        We couldn't find the page you were looking for. This is either because:
      </p>

      <ul>
        <li>
          There is an error in the URL entered into your web browser. Please
          check the URL and try again.
        </li>
        <li>You requested a page you don't have permissions to view.</li>
        <li>The page you are looking for has been moved or deleted.</li>
      </ul>
    </div>
    <FooterNew />
  </div>
);

export default NotFoundView;
