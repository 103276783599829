import React, { useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import useKeyboardEvent from '../../hooks/useKeyboardEvent';
import { shorten } from '../../util/stringUtils';
import Avatar from '../Avatar/Avatar';
import Button from 'components/Button/Button';
import SectionHeader from 'components/SectionHeader/SectionHeader';

const CharityEventListItem = ({
  charity,
  isSelected,
  onSelect,
  onLearnMore,
  showLink = false,
  disabled = false,
  rounded = false,
  charityCount,
}) => (
  <a
    href={showLink ? `/charity/${charity.id}` : ''}
    className={`charity-event-item ${
      charityCount <= 1 ? 'single-charity' : ''
    }`}
    key={`${charity.name}-${charity.id}`}
    onClick={(ev) => {
      if (showLink) {
        return;
      }
      ev.preventDefault();
      if (disabled) {
        return;
      }
      if (!!onSelect) {
        onSelect(charity.id);
      } else if (!!onLearnMore) {
        onLearnMore(charity.id);
      }
    }}
  >
    <Avatar
      className="event-charity-avatar"
      type="charity"
      noBorder={true}
      dropShadow={false}
      avatar={charity.avatar || charity.avatarUrls}
      style={{ marginBottom: '5px' }}
    />
    <div className="flex-column flex-expand flex-align-center">
      <div
        className={`charity-info ${charityCount <= 1 ? 'single-charity' : ''}`}
      >
        <text
          alt="test"
          className={`charity-name ${
            charityCount <= 1 ? 'single-charity' : ''
          }`}
        >
          {charityCount <= 1 ? charity.name : shorten(charity.name, 60)}
        </text>
        <div className="charity-location">
          {charity.city && charityCount <= 1 && <span>{charity.city}, </span>}
          {charity.state && charityCount <= 1 && <span>{charity.state}</span>}
        </div>
      </div>
    </div>
    {onSelect && !disabled && (
      <div className="flex-column">
        <div className="flex-column flex-expand" />
        <Button
          onClick={(ev) => {
            ev.preventDefault();
            onSelect(charity.id);
            ev.currentTarget.blur();
          }}
          className={classnames('select-button button is-primary is-small', {
            'is-outlined': isSelected,
            selected: isSelected,
            'is-rounded': rounded,
          })}
        >
          {isSelected && <span style={{ fontSize: 14 }}>✓ </span>}
          {isSelected ? 'Selected' : 'Select'}
        </Button>
      </div>
    )}
  </a>
);

const CharityList = ({
  charities,
  onLearnMore,
  onSelect,
  selected = [],
  title,
  showLink,
  disabled = false,
  rounded,
}) => {
  const scrollHandler = useRef(null);
  const [isScrollEnd, setIsScrollEnd] = useState(true);
  const [isScrollStart, setIsScrollStart] = useState(true);

  useKeyboardEvent('ArrowRight', () => {
    scrollHandler.current.scrollLeft =
      scrollHandler.current.scrollLeft + scrollHandler.current.offsetWidth - 55;
  });

  useKeyboardEvent('ArrowLeft', () => {
    scrollHandler.current.scrollLeft =
      scrollHandler.current.scrollLeft - scrollHandler.current.offsetWidth + 55;
  });

  useEffect(() => {
    if (scrollHandler) {
      if (
        scrollHandler.current.scrollWidth - scrollHandler.current.offsetWidth >
        0
      ) {
        setIsScrollEnd(false);
      }
    }
  }, []);

  const onScroll = (_ev) => {
    if (scrollHandler.current.scrollLeft === 0) {
      if (!isScrollStart) {
        setIsScrollStart(true);
      }
    } else {
      if (isScrollStart) {
        setIsScrollStart(false);
      }
    }
    if (
      scrollHandler.current.scrollLeft +
        1 -
        (scrollHandler.current.scrollWidth -
          scrollHandler.current.offsetWidth) >=
      0
    ) {
      if (!isScrollEnd) {
        setIsScrollEnd(true);
      }
    } else {
      if (isScrollEnd) {
        setIsScrollEnd(false);
      }
    }
  };

  const charityCount = charities?.length;

  return (
    <div className="charity-list-new flex-column max-md:!p-0">
      {!onSelect && (
        <div
          className={`
          charity-select-title 
          flex-row
          ${charityCount > 1 ? 'multiple-charities' : ''}
          `}
        >
          <SectionHeader title={title || 'Nonprofits'} />
        </div>
      )}
      <div className="flex-row flex-expand" style={{ position: 'relative' }}>
        {!isScrollStart && (
          <div className="scroll-more-left">
            <button
              onClick={(ev) => {
                ev.preventDefault();
                scrollHandler.current.scrollLeft =
                  scrollHandler.current.scrollLeft -
                  scrollHandler.current.offsetWidth +
                  55;
              }}
            >
              {'<'}
            </button>
          </div>
        )}
        <div
          ref={scrollHandler}
          onScroll={onScroll}
          style={{
            // marginLeft: '2rem',
            marginRight: `-${
              charities.length < 2 ? 0 : charities.length * 7.5
            }rem`,
          }}
          className={classnames('flex-row flex-expand', {
            'scroll-horizontal': charities.length > 1,
            'flex-start': charities.length === 1,
          })}
        >
          {charities.map((charity) => (
            <CharityEventListItem
              key={`${charity.id}`}
              charity={charity}
              onSelect={onSelect}
              isSelected={selected.includes(charity.id)}
              onLearnMore={onLearnMore}
              disabled={disabled}
              showLink={showLink}
              rounded={rounded}
              charityCount={charityCount}
            />
          ))}
        </div>
        {!isScrollEnd && (
          <div className="scroll-more-right flex-center ">
            <button
              onClick={(ev) => {
                ev.preventDefault();
                scrollHandler.current.scrollLeft =
                  scrollHandler.current.scrollLeft +
                  scrollHandler.current.offsetWidth -
                  55;
              }}
            >
              {'>'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CharityList;
