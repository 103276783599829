import gql from 'graphql-tag';

const createContentReportMutation = gql`
  mutation createContentReport(
    $comment: String
    $reasons: [String!]!
    $userId: Int
    $companyId: Int
    $projectId: Int
    $eventId: Int
    $purchaseId: Int
    $purchaseCommentId: Int
  ) {
    createContentReport(
      comment: $comment
      reasons: $reasons
      userId: $userId
      companyId: $companyId
      projectId: $projectId
      eventId: $eventId
      purchaseId: $purchaseId
      purchaseCommentId: $purchaseCommentId
    ) {
      id
      insertedAt
    }
  }
`;

const blockEntityMutation = gql`
  mutation blockEntity($userId: Int, $companyId: Int, $projectId: Int) {
    blockEntity(userId: $userId, companyId: $companyId, projectId: $projectId) {
      id
      insertedAt
    }
  }
`;

const unblockEntityMutation = gql`
  mutation unblockEntity($userId: Int, $companyId: Int, $projectId: Int) {
    unblockEntity(
      userId: $userId
      companyId: $companyId
      projectId: $projectId
    ) {
      id
      insertedAt
    }
  }
`;

export {
  createContentReportMutation,
  blockEntityMutation,
  unblockEntityMutation,
};
