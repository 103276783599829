/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { navigate } from '@reach/router';
import 'react-tippy/dist/tippy.css';

import withUserContext from 'behaviors/withUserContext';

import Avatar from 'components/Avatar/Avatar';
import Currency from 'components/Currency/Currency';
import ShareModal from 'components/modals/ShareModal/ShareModal';
import LoginModal from 'components/modals/LoginModal/LoginModal';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import ChevronDown from 'assets/images/icons/chevron/down-chevron-2.svg';
import FundraiserContentOverView from 'components/fundraising/FundraiserContentOverview/FunraiserContentOverview';
import FundraiserFeed from 'components/fundraising/FundraiserFeed';
import ImpactAssets from 'assets/images/brand/externalBrands/impact-assets.png';
import FundraiserNews from 'components/fundraising/FundraiserNews';

const OVERVIEW_TAB = 'Overview';
const DONATIONS_TAB = 'Latest Donations';
const NEWS_TAB = 'News';

const getJoinerAvatar = (purchase) => {
  if (purchase.company) return purchase.company.avatarUrls;
  else if (purchase.donorProject) return purchase.donorProject.avatarUrls;
  else return purchase.user.avatarUrls;
};
// Remember to test unauthed cases on this view!

const FundraiserHero = () => (
  <div className="fundraiser-hero-container">
    <div className="hero-image-cover">
      <div className="hero-header">
        <h1 className="hero-title">Invest in the future of philanthropy</h1>
        <span className="hero-subtitle">
          Pooled donor fund for investment in Cauze powered by ImpactAssets
        </span>
      </div>
      <CauzeButton
        support
        children="Find Out How"
        large
        onClick={() => {
          document
            .getElementById('content-body')
            .scrollIntoView({ block: 'start', behavior: 'smooth' });
        }}
      />
    </div>
  </div>
);

const ContentNavbar = ({ setContentBodyTab, contentBodyTab }) => {
  const [isMobileSelectOpen, setIsMobileSelectOpen] = useState(false);
  return (
    <>
      <div className="content-body-nav is-hidden-mobile">
        <div
          className={`content-tab ${
            contentBodyTab === OVERVIEW_TAB && 'is-active'
          }`}
          onClick={() => setContentBodyTab(OVERVIEW_TAB)}
        >
          OverView
        </div>
        <div
          className={`content-tab ${
            contentBodyTab === DONATIONS_TAB && 'is-active'
          }`}
          onClick={() => setContentBodyTab(DONATIONS_TAB)}
        >
          Latest Donations
        </div>
        {/* <div
          className={`content-tab ${contentBodyTab === NEWS_TAB && 'is-active'}`}
          onClick={() => setContentBodyTab(NEWS_TAB)}
        >News</div> */}
      </div>
      <div className="content-body-nav-mobile is-hidden-tablet">
        <div
          className="content-body-nav-select"
          onClick={() => setIsMobileSelectOpen(!isMobileSelectOpen)}
        >
          {contentBodyTab}
          <img
            src={ChevronDown}
            className={`mobile-select-chevron ${
              !isMobileSelectOpen && 'is-open'
            }`}
            onClick={() => setIsMobileSelectOpen(!isMobileSelectOpen)}
          />
        </div>
        <div
          className={`mobile-select-options ${
            isMobileSelectOpen ? 'is-open' : 'is-closed'
          }`}
        >
          <div
            className="mobile-select-options-item"
            onClick={() => {
              setContentBodyTab(OVERVIEW_TAB);
              setIsMobileSelectOpen(false);
            }}
          >
            OverView
          </div>
          <div
            className="mobile-select-options-item"
            onClick={() => {
              setContentBodyTab(DONATIONS_TAB);
              setIsMobileSelectOpen(false);
            }}
          >
            Latest Donations
          </div>
          {/* <div
            className='mobile-select-options-item'
            onClick={() => {
              setContentBodyTab(NEWS_TAB);
              setIsMobileSelectOpen(false);
            }}
          >News</div> */}
        </div>
      </div>
    </>
  );
};

const FundraiserStatusCard = ({ event, onNavToCheckout }) => (
  <div className="fundraiser-status-card-container">
    <div className="amount-rasied-container">
      <h3 className="dollar-amount">
        <Currency amount={event.current} />
      </h3>
      <span className="raised">Raised</span>
    </div>
    <div className="investor-container">
      <h4 className="investor-count">
        {event.purchaserCount > 0 ? `${event.purchaserCount} Supporters` : ''}
      </h4>
      <div className="top-givers-avatar-container">
        {event.topPurchasers.slice(0, 5).map((purchaser) => (
          <Avatar md avatar={purchaser.actor.avatarUrls} />
        ))}
      </div>
      {event?.topPurchasers?.length > 5 && (
        <h4 className="more-purchasers">{`+ ${
          event.topPurchasers.length - 5
        } more`}</h4>
      )}
    </div>
    <CauzeButton onClick={onNavToCheckout} support large children="Give Now" />
  </div>
);

const ImpactAssetsCard = () => (
  <div className="impact-assets-card-container">
    <h4 className="powered-by">Powered by</h4>
    <a
      className="impact-assets-link"
      href="https://impactassets.org"
      target="_blank"
    >
      <img src={ImpactAssets} className="impact-assets-image" />
    </a>
    <h3 className="impact-assets-title">How does the investment work?</h3>
    <ol className="impact-assets-list">
      <li className="impact-assets-list-item">
        This is a pooled donor fund to be hosted at Impact Assets to raise
        operating capital for Cauze, Inc.,a profit + purpose social enterprise.
      </li>
      <li className="impact-assets-list-item">
        In the event Cauze has a liquidity event in the future (or an investment
        is not made), proceeds will be allocated back to your donor account at
        Cauze for your recommendation as distributions to nonprofits.
      </li>
      <li className="impact-assets-list-item">
        This is not a solicitation of an investment; this is a charitable
        donation.
      </li>
    </ol>
    <h3 className="more-questions">More questions?</h3>
    <div className="flex-column">
      <a className="chat-link" href="mailto:hello@cauze.com">
        Chat with a member of our team.
      </a>
      <a className="chat-link" href="https://www.cauze.com/investment-faq">
        Check out this FAQ for more information about Cauze.
      </a>
    </div>
  </div>
);

const CauzeFundraiserEventView = ({
  eventStore,
  profileStore,
  authStore,
  userContext,
  eventId,
  uiStore,
  matchStore,
}) => {
  useEffect(() => {
    eventStore.getFundraiserFeed({ eventId: +eventId });

    return eventStore.onUnmount;
  }, [eventId]);

  const [showShareModal, setShowShareModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const [contentBodyTab, setContentBodyTab] = useState(OVERVIEW_TAB);

  let parsedEventId =
    decodeURIComponent(eventId).split('?').length > 1 &&
    decodeURIComponent(eventId).split('?')[0];
  const sanitizedEventId = parsedEventId || eventId;

  const event = eventStore.eventData;
  const renderFundaiserFeed =
    !eventStore.loadingFundraiserFeed && eventStore.fundraiserFeed?.length > 0;

  const onUnauthedLike = () => {
    uiStore.openModal('CONFIRM', {
      message: `To interact with our investors, please login or create an account!`,
      action: () => {
        setShowLoginModal(true);
      },
      confirmText: 'Login / Signup',
      cancelText: 'Not Now',
    });
  };

  const onNavToCheckout = () => {
    const _config = toJS(event.config); // mobx and location API don't play nice together
    navigate(`/checkout/event/${event.id}`, { state: { ..._config } });
  };

  return (
    <div className="cauze-fundraiser-event-view">
      <div className="event-view-container flex-column">
        <LoginModal
          isOpen={showLoginModal}
          onToggleClose={() => setShowLoginModal(false)}
        />
        <ShareModal
          isOpen={showShareModal}
          onToggleClose={() => setShowShareModal(false)}
          shareUrl={event.shareLink}
          shareTitle={event.name}
        />
        <FundraiserHero />
        <div className="event-content flex-column flex-justify-center flex-align-center">
          <div className="content-header">
            Help us build the future of philanthropy by donating to a pooled
            equity investment in Cauze. Make a donation today and your donor
            account will become a part owner of Cauze!
          </div>
          <div className="content-body-container">
            <div className="content-body" id="content-body">
              <ContentNavbar
                contentBodyTab={contentBodyTab}
                setContentBodyTab={setContentBodyTab}
              />
              {contentBodyTab === OVERVIEW_TAB && <FundraiserContentOverView />}
              {contentBodyTab === DONATIONS_TAB && renderFundaiserFeed && (
                <FundraiserFeed
                  items={eventStore.fundraiserFeed}
                  onLike={eventStore.onFundraiserFeedPurchaseLike}
                  onUnauthedLike={onUnauthedLike}
                  isAuthenticated={authStore.isAuthenticated}
                  userContext={userContext}
                />
              )}
              {contentBodyTab === NEWS_TAB && <FundraiserNews />}
            </div>
            <div className="content-static-info">
              <FundraiserStatusCard
                onNavToCheckout={onNavToCheckout}
                event={event}
              />
              <ImpactAssetsCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserContext(
  inject(
    'eventStore',
    'profileStore',
    'uiStore',
    'authStore',
  )(observer(CauzeFundraiserEventView)),
);
