import React from 'react';
import useClipboard from 'react-use-clipboard';
import Button from 'components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';

const Share = ({ shareUrl }) => {
  const [isCopied, setCopied] = useClipboard(shareUrl, {
    successDuration: 2000,
  });

  return (
    <div className="dashboard-item flex-row">
      <div className="dashboard-icon">
        <FontAwesomeIcon icon={faShareAlt} />
      </div>
      <div className="dashboard-content">
        <div className="dashboard-details flex-column">
          <div className="dashboard-item-title">Share Your Profile</div>
          <div className="dashboard-item-body"></div>
        </div>
        <div className="dashboard-item-actions flex-row flex-align-center ">
          <input readOnly className="dashboard-link" value={shareUrl} />
        </div>
        <div className="dashboard-item-actions flex-row flex-align-center ">
          <Button disabled={isCopied} onClick={setCopied}>
            {isCopied ? 'copied' : 'Copy to Clipboard'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Share;
