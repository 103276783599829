/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';

import PieChart from 'react-minimal-pie-chart';

// const FULL_WIDTH = 27;

const CauzePieChart = ({
  data,
  renderLabel,
  labelStyle,
  onClick,
  onMouseOver,
  onMouseOut,
}) => {
  const [chartData, setChartData] = useState(data);

  const _onMouseOut = (event, propsData, index) => {
    const newData = propsData.map((entry, i) => {
      if (i === index) {
        onMouseOut && onMouseOut();
        // return {
        //   ...entry,
        //   color: data[index].color,
        // };
      }
      return entry;
    });

    setChartData(newData);
  };

  const _onMouseOver = (event, propsData, index) => {
    const newData = propsData.map((entry, i) => {
      if (i === index) {
        onMouseOver && onMouseOver(entry.title, entry.value, entry.color);

        // return {
        //   ...entry,
        //   color: '#F16C21',
        // };
      }
      return entry;
    });

    setChartData(newData);
  };

  const _onClick = (event, propsData, index) => {
    const newData = propsData.map((entry, i) => {
      if (i === index) {
        onClick && onClick(entry.title, entry.value, entry.color);
      }

      return entry;
    });

    setChartData(newData);
  };

  return (
    <PieChart
      data={chartData}
      lineWidth={40}
      label={renderLabel || false}
      labelStyle={{
        fontSize: '1rem',
        fontFamily: 'Walshiem-Bold',
        ...labelStyle,
      }}
      labelPosition={0}
      animate
      onMouseOver={_onMouseOver}
      onMouseOut={_onMouseOut}
      onClick={_onClick}
    />
  );
};

export default CauzePieChart;
