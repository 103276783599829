import React from 'react';
import Button from '../../Button/Button';
import useKeyboardEvent from '../../../hooks/useKeyboardEvent.js';
import { observer } from 'mobx-react';

import classnames from 'classnames';

const ThanksReminderModal = ({
  isOpen,
  onToggleClose,
  outstandingThanksCount,
  action,
  charityName,
}) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  return (
    <div
      className={classnames('modal', {
        'is-active': isOpen,
      })}
    >
      <div className="modal-background"></div>
      <footer className="modal-card thanks-modal">
        <header className="modal-card-head">
          <p className="modal-card-title">Welcome to your nonprofit portal!</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onToggleClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <p
            className="option-group-info is-size-6"
            style={{ paddingLeft: '0.4rem' }}
          >
            Hi there! It looks like you have {outstandingThanksCount} donors who
            have donated to the {charityName} who are waiting to hear from you.
            <br />
            <br />
            Before you explore your portal, we invite you to start by letting
            those donors know you care!
          </p>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-small" onClick={onToggleClose}>
            Explore dashboard
          </button>
          <Button
            className="button is-primary is-small"
            onClick={() => {
              onToggleClose();
              action();
            }}
          >
            Go thank your donors!
          </Button>
        </footer>
      </footer>
    </div>
  );
};

export default observer(ThanksReminderModal);
