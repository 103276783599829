import React, { useEffect } from 'react';

import withAuthRequired from 'behaviors/withAuthRequired';
import withUserContext from 'behaviors/withUserContext';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import useDashboardStore from 'stores/DashboardStore';

const Item = ({ buttonText, children }) => (
  <AccordionItem>
    <AccordionItemHeading>
      <AccordionItemButton>{buttonText}</AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>{children}</AccordionItemPanel>
  </AccordionItem>
);

const CharityFaqView = () => {
  const { setProps } = useDashboardStore();

  useEffect(() => {
    setProps({
      title: 'Frequently Asked Questions',
    });
  }, []);

  return (
    <div className="charity-admin charity-faq flex-column flex-expand">
      <Accordion className="accordion" allowMultipleExpanded>
        <Item buttonText="How does it work?">
          <p>
            A Cauze user donates $10 to your organization through the Cauze app.
            Their $10 donation goes into the Cauze Charitable Fund, a registered
            501c3 Donor Advised Fund.
          </p>
          <p>
            At the end of every month, Cauze consolidates all donation
            recommendations for your nonprofit and makes a single grant on
            behalf of all our users. This includes individual donations, matched
            donations and gifted donations.
          </p>
        </Item>
        <Item buttonText="How will my organization receive the funds?">
          <p>
            All grants come from our partner, the Cauze Charitable Fund. Cauze
            processes donations monthly. Your organization will receive a check
            from Cauze within the first two weeks of the following month. We
            encourage nonprofits to sign up for EFT with us.
          </p>
        </Item>
        <Item buttonText="How do we sign up to administer our profile in Cauze?">
          <p>
            If you are the admin for your nonprofit organization and would like
            to claim your Cauze account as such, please reach out to
            hello@cauze.com and our team will help get you set up as admin for
            your organization.
          </p>
        </Item>
        <Item buttonText="How do we engage and thank the donors?">
          <p>
            Once you become an admin of your profile in Cauze, you will be
            notified via email anytime someone donates to your organization. You
            can pull up the Cauze app on your phone and thank the individual
            donor, which sends a notification back to that donor.
          </p>
        </Item>
        <Item buttonText="Do I need to send tax receipts for donations received through Cauze?">
          <p>
            No. The Cauze Charitable Fund is the donor of record and we kindly
            ask that you not send a receipt or add us to your direct mailing
            list.
          </p>
        </Item>
        <Item buttonText="What are the fees associated with giving through Cauze?">
          <p>
            When a giver donates using a credit card, they cover a small credit
            card processing fee of 2.2% + .30. This is paid to our credit card
            vendor and is unfortunately unavoidable. The result is that 100% of
            their intended donation goes to the nonprofit.
          </p>
          <p>
            If a Cauze user makes a donation from a gifted balance, there is a
            gift processing fee of between 4.5% and 7.5%, depending on the
            corporate client making the gift.
          </p>
          <p>
            For example, if a donor makes a $50 donation via her credit card and
            it’s matched $50 by Company A, Cauze will send the nonprofit $96.25.
            That’s $50 from the donor and $46.25 from the company.
          </p>
        </Item>
        <Item buttonText="How does Cauze make money?">
          <p>
            We make money from employers and corporations who want to engage
            their employees and customers in giving back. We charge employers a
            monthly software fee for using Cauze to administer a matching and
            rewards program. We also earn a small percentage on donations made
            from gifted balances.
          </p>
        </Item>
        <Item buttonText="How does a gift work in Cauze?">
          <p>
            Donors in Cauze can fund (donate to) their Cauze account, and then
            “gift” the choice of nonprofit recipient to others. The receiver of
            the gift has a gifted balance and can use that to make donations
            (grants) to any nonprofit.
          </p>
          <p>
            Currently, gifts only exist between and employer and employee and
            are being used as a rewards program for employees.
          </p>
        </Item>
        <Item buttonText="How does a match work?">
          <p>
            A match is very similar to a gift from one user to another. The
            difference is that the trigger for “earning” the gift is to make a
            donation. For example, if an employer offers to match donations of
            their employees, when the employee gives to a nonprofit, Cauze
            automatically pulls the matching funds into that donation and it’s
            added to the monthly grant.
          </p>
          <p>Matches have the following elements:</p>
          <ul className="faq-list-item">
            <li>
              The Matcher – This is the company offering to match. It might be
              an employer, a foundation, or a company matching their customers.
              Matches can have one or more Matchers.
            </li>
            <li>
              Eligible Nonprofits – This is one or more nonprofits who are
              eligible for a match. In the case of employers, it’s typically any
              501c3.
            </li>
            <li>
              Eligible Givers – the Matcher can limit which givers are eligible
              to use the match. For an employer, this would be their employees.
              For a private foundation, it might be anyone who gives to the
              nonprofit.
            </li>
            <li>
              Maximum Total Match Amount – this is the total amount a matcher
              would match across all donations. Maximum Individual Match Amount
              – matches can be set up with an individual maximum match amount.
              For example, up to $500 per giver.
            </li>
            <li>
              Time Frame – this is the time that a match is eligible. It could
              be a day, it could be a calendar year, or it could be indefinite
              (or until the max total amount is reached).
            </li>
          </ul>
        </Item>
      </Accordion>
    </div>
  );
};

export default withAuthRequired(withUserContext(CharityFaqView), {
  entityType: 'CHARITY',
});
