import gql from 'graphql-tag';

const userByIdQuery = gql`
  query UserByIdQuery($id: ID!, $user_context: UserContext) {
    data: userById(id: $id, userContext: $user_context) {
      id
      isSelf
      firstName
      lastName
      username
      email
      bio
      isSelfFollowing
      followerCount
      followingCount
      shareLink
      isVerified
      isInfluencer
      hasRecurringDeposit
      sysAdmin
      balance {
        total
      }
      avatar {
        md
        lg
      }
      hero {
        full
      }
      featuredCharities {
        id
        city
        state
        name
        description
        ein
        avatar {
          sm
          md
        }
      }
      featuredEvents {
        id
        isSelfFollowing
        image {
          md
        }
        name
      }
      adminCharities {
        id
        displayName
        avatar {
          sm
        }
      }
      companies {
        id
        name
        avatar {
          sm
          md
        }
      }
    }
  }
`;

const userByUsernameQuery = gql`
  query userByUsername($username: String!) {
    data: userByUsername(username: $username) {
      id
      isSelf
      firstName
      lastName
      username
      email
      isSelfFollowing
      followerCount
      followingCount
      balance {
        total
      }
      avatar {
        md
      }
      hero {
        full
      }
      companies {
        id
        name
        avatar {
          md
        }
      }
    }
  }
`;

const userByIdMinQuery = gql`
  query UserByIdQuery($id: ID!, $user_context: UserContext) {
    data: userById(id: $id, userContext: $user_context) {
      id
      isSelf
      name
      username
      email
      bio
      isSelfFollowing
      shareLink
      isVerified
      sysAdmin
      avatar {
        md
        lg
        xl
      }
    }
  }
`;

const userProfileByIdQuery = gql`
  query UserByIdQuery($id: ID!, $user_context: UserContext) {
    data: userById(id: $id, userContext: $user_context) {
      id
      firstName
      lastName
      username
      email
      backupEmail
      bio
      zip
      avatar {
        md
      }
      hero {
        md
      }
    }
  }
`;

const userProfileByIdQueryV2 = gql`
  query UserByIdQuery($userId: Int!) {
    data: user(userId: $userId) {
      id
      firstName
      lastName
      username
      email
      backupEmail
      bio
      zip
      webUrl
      linkUrl
      shareLink
      foundationName
      isCurrentEntity
      isSelfFollowing: currentEntityIsFollowing
      heroUrls {
        full
        md
      }
      avatarUrls {
        md
      }
      userSocials {
        socialType
        url
      }
      featuredProjects {
        id
        name
        state
        city
        ein
        description
        insertedAt
        followerCount: totalFollowers
        avatarUrls {
          md
        }
        heroUrls {
          md
        }
      }
    }

    profileData: userProfile(userId: $userId) {
      followerCount
      followingCount
      isInfluencer
      userGood {
        alltimeGoodSum
        alltimeInfluencedCount
      }
      companyMatch {
        matchAdmin {
          name
          avatarUrls {
            sm
            md
          }
        }
        active
        currentEntityRemaining
        matchLimit
        totalRemaining
      }
    }
  }
`;

const userActivityById = gql`
  query userActivityById(
    $userId: Int!
    $offset: Int
    $limit: Int
    $date_from: String
    $date_to: String
    $balance_type: String
    $amount_from: Int
    $amount_to: Int
    $item_type: String
    $search: String
  ) {
    userProfile(userId: $userId) {
      userBalanceCreditsAndDebits(
        offset: $offset
        limit: $limit
        dateFrom: $date_from
        dateTo: $date_to
        balanceType: $balance_type
        amountFrom: $amount_from
        amountTo: $amount_to
        itemType: $item_type
        search: $search
      ) {
        id
        amount
        description
        endingBalance
        type
        createdAt
        eventId
        subscriptionChargeId
        giftType
        balanceType
      }
    }
  }
`;

const pendingInvitesQuery = gql`
  query pendingInvites($email: String!) {
    pendingInvites(email: $email) {
      matches {
        id
        active
      }
      tokens {
        id
        type
        redeemed
      }
      userIsUnclaimed
    }
  }
`;

const userJoinedCauzesQuery = gql`
  query userJoinedCauzesQuery {
    joinedCauzes {
      id
      config {
        donationAmountPresets
        minimumDonation
        template
      }
      name
      joinPurchaseId
      description
      purchaserCount
      current
      giverCount
      startDate
      endDate
      eventType
      goal
      currentEntityIsHost
      currentEntityIsFollowing
      currentEntityHasPinned
      previewUrl
      eventState
      externalImageUrl
      image {
        sm
        md
        lg
        xl
        full
      }
      shareLink
      charities {
        ein
        id
        name
        description
        street
        state
        city
        heroUrls {
          full
          md
        }
        avatarUrls {
          sm
          md
          lg
        }
      }
      matches {
        id
        active
        company {
          name
          subtitle
          avatarUrls {
            sm
            md
          }
        }
        matchAdmin {
          id
          isCurrentEntity
          currentEntityIsFollowing
          type
          name
          avatarUrls {
            sm
            md
          }
        }
        description
        startDate
        endDate
        eventId
        matchLimit
        matchTotal
        totalRaised
        totalRemaining
        currentEntityRemaining
        multiplier
        matchType
        shareLink
        matchSponsors {
          name
          id
          currentEntityIsFollowing
          isCurrentEntity
          subtitle
          matchTotal
          remaining
          type
          avatarUrls {
            sm
            md
          }
        }
      }
      hosts {
        id
        name
        user {
          bio
        }
        company {
          description
        }
        project {
          description
        }
        avatarUrls {
          sm
          md
        }
        type
      }
      topPurchasers {
        insertedAt
        id
        actor {
          avatarUrls {
            md
          }
        }
        donationAmount
        user {
          avatarUrls {
            sm
            md
          }
          firstName
          lastName
          name
          id
          currentEntityIsFollowing
          isActive
          unclaimed
        }
        company {
          avatarUrls {
            sm
            md
          }
          name
          id
          currentEntityIsFollowing
        }
        donorProject {
          avatarUrls {
            sm
            md
          }
          name
          id
          currentEntityIsFollowing
        }
      }
    }
  }
`;

export {
  userByIdQuery,
  userByUsernameQuery,
  userByIdMinQuery,
  userProfileByIdQuery,
  pendingInvitesQuery,
  userProfileByIdQueryV2,
  userActivityById,
  userJoinedCauzesQuery,
};
