import React, { useEffect } from 'react';

import CauzesView from './CauzesView';
import withAuthRequired from 'behaviors/withAuthRequired';
import useCauzes from 'hooks/useCauzes';
import useDashboardStore from 'stores/DashboardStore';

const CharityCauzesView = ({ charityId }) => {
  useCauzes({ userContext: { charityId } });
  const { setProps } = useDashboardStore();

  useEffect(() => {
    setProps({
      size: 'screen',
      bodyClassName: 'bg-lightgray-e0e',
    });
  }, []);

  return <CauzesView userContext={{ charityId }} />;
};

export default withAuthRequired(CharityCauzesView, {
  entityType: 'CHARITY',
});
