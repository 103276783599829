import React from 'react';
import classnames from 'classnames';
import starOutline from '../../assets/images/icons/star/star-outline-gray.svg';
import starOrange from '../../assets/images/icons/star/star-orange.svg';

const FollowButton = ({ following = false, style, className, onFollow }) => (
  <div
    onClick={onFollow}
    className={`follow-button flex-column flex-align-center ${className}`}
    style={style}
  >
    {following ? <img src={starOrange} /> : <img src={starOutline} />}
    <p
      className={classnames({
        'following-text': following,
        'follow-text': !following,
      })}
    >
      {following ? 'Following' : 'Follow'}
    </p>
  </div>
);

export default FollowButton;
