import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';

import config from '../../config';

import useCharityAdmin from 'hooks/useCharityAdmin';
import withUserContext from 'behaviors/withUserContext';
import withAuthRequired from 'behaviors/withAuthRequired';

import ThanksModal from 'components/modals/ThanksModal';
import ThanksReminderModal from 'components/modals/ThanksReminderModal';
import Currency from 'components/Currency/Currency';

import { ReactComponent as DownloadIcon } from 'assets/images/icons/download/download.svg';
import CharityAdminLedgerTable from 'components/LedgerTable/CharityAdminLedgerTable';
import Avatar from 'components/Avatar/Avatar';
import useDashboardStore from 'stores/DashboardStore';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const CharityDonationsView = ({ charityAdminStore, charityId }) => {
  const [showThanksModal, setShowThanksModal] = useState(false);
  const [thanksModalAction, setThanksModalAction] = useState(() => {});
  const { setProps } = useDashboardStore();

  useCharityAdmin(charityId);

  const onDashboardSayThanks = () => {
    charityAdminStore.setIsClearingOutstanding(true);
    setThanksModalAction(() => (body) => {
      charityAdminStore.sendThankYouComments({
        body,
        userContext: { charityId },
      });
    });
    setShowThanksModal(true);
  };

  const showReminderModal =
    !charityAdminStore.loading && !charityAdminStore.hasSeenThanksReminder;

  const showThanksDashboard =
    charityAdminStore.outstandingThanksPurchaseIds.length > 0 &&
    charityAdminStore.hasSeenThanksReminder &&
    !charityAdminStore.ledgerLoading;

  useEffect(() => {
    setProps({
      size: 'screen',
      title: (
        <div className="capitalize flex gap-x-2">
          <Avatar
            className="my-auto"
            noBorder
            avatar={charityAdminStore.data?.avatar}
          />
          <div className="my-auto leading-none">
            {charityAdminStore.data?.name}
          </div>
        </div>
      ),
    });
  }, [charityAdminStore.data]);

  if (charityAdminStore.loading) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div className="charity-admin flex-column">
      <div>
        {showThanksModal && (
          <ThanksModal
            isOpen
            action={thanksModalAction}
            thanksCount={
              charityAdminStore.purchaseIdsToThankCount ||
              charityAdminStore.outstandingThanksPurchaseIds.length
            }
            onToggleClose={() => {
              charityAdminStore.setPurchaseIdsToThankCount(0);
              charityAdminStore.setIsClearingOutstanding(false);
              setShowThanksModal(false);
            }}
          />
        )}
        {showReminderModal && (
          <ThanksReminderModal
            isOpen
            charityName={charityAdminStore.data?.name}
            action={onDashboardSayThanks}
            outstandingThanksCount={
              charityAdminStore.outstandingThanksPurchaseIds.length
            }
            onToggleClose={charityAdminStore.setNotFirstView}
          />
        )}
        <div className="charity-admin-body">
          <div className="charity-balance-container">
            <div className="charity-pending-balance-container">
              <div className="charity-pending-balance-amount">
                <Currency
                  showCents
                  showDollarSign
                  amount={charityAdminStore.ledgerNetAmount}
                />
              </div>
              <div className="charity-pending-balance-text">
                Pending Balance
              </div>
            </div>
            <div className="charity-button-container">
              {showThanksDashboard && (
                <button
                  className="charity-admin-thank-all"
                  onClick={onDashboardSayThanks}
                >
                  THANK ALL
                </button>
              )}
              <button
                className="charity-admin-download"
                onClick={() => {
                  const charityId = charityAdminStore.data.id;
                  charityAdminStore
                    .getDownloadToken(charityId, 'grants/all/project')
                    .then((token) =>
                      navigate(
                        `${config.API_ROOT}/grants/all/project/${charityId}/${token}`,
                      ),
                    );
                }}
              >
                <span>Download</span>
                <DownloadIcon height={15} width={15} />
              </button>
            </div>
          </div>
          <CharityAdminLedgerTable
            rowData={charityAdminStore.ledgerData}
            onThank={(purchaseIds) => {
              charityAdminStore.setPurchaseIdsToThankCount(purchaseIds.length);
              setThanksModalAction(() => (body) => {
                charityAdminStore.sendThankYouComments({
                  purchaseIds,
                  body,
                  userContext: { charityId },
                });
              });
              setShowThanksModal(true);
            }}
            canLoadMore={charityAdminStore.ledgerCanLoadMore}
            loadMore={charityAdminStore.loadMoreLedger}
            loading={charityAdminStore.ledgerLoading}
            loadingMore={charityAdminStore.ledgerLoadingMore}
            sort={charityAdminStore.ledgerSort}
            setSort={(newSort) => {
              charityAdminStore.setLedgerSort(newSort);
              charityAdminStore.getLedger();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withAuthRequired(
  withUserContext(
    inject('profileStore', 'charityAdminStore')(observer(CharityDonationsView)),
  ),
  { entityType: 'CHARITY' },
);
