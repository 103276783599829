import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

const Tooltip = ({ text, children, className }) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ placement: 'bottom', trigger: 'hover' });

  return (
    <>
      <div
        className={className}
        ref={setTriggerRef}
        style={{ display: 'inline-block' }}
      >
        {children}
      </div>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container tooltip-container-checkout-summary',
          })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          <div className="tooltip-body">
            <div className="tooltip-title !mb-0">{text}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Tooltip;
