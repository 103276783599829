import React, { useEffect, useState } from 'react';

const DIVISORS = {
  /** thousand */
  K: Math.pow(10, 3),

  /** million */
  M: Math.pow(10, 6),

  /** billion */
  B: Math.pow(10, 9),
};

const LargeNumber = ({ amount = 0, isCurrency = false }) => {
  const [display, setDisplay] = useState(amount);

  useEffect(() => {
    if (amount && typeof amount == 'number') {
      let ext = '';

      Object.entries(DIVISORS).forEach(([key, value]) => {
        if (amount >= value && value > (DIVISORS[ext] || 1)) {
          ext = key;
        }
      });

      setDisplay(
        `${isCurrency ? '$' : ''}${(amount / (DIVISORS[ext] || 1))
          .toFixed(isCurrency && ext ? 2 : 0)
          .replace('.00', '')}${ext}`.trim(),
      );
    }
  }, [amount]);

  return <>{display}</>;
};

export default LargeNumber;
