import React, { useEffect, useState } from 'react';
import Currency from '../Currency/Currency';
import classnames from 'classnames';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import useKeyboardEvent from '../../hooks/useKeyboardEvent.js';

const CauzeAmountSelector = ({
  onAmountUpdate,
  amounts = [1000, 2500, 5000, 10000],
  defaultAmount = null,
  sendDefaultUpdate = true,
  minimumAmount,
}) => {
  const [amount, setAmount] = useState(
    defaultAmount !== 0 ? defaultAmount : null,
  );
  const [customAmount, setCustomAmount] = useState(
    amount !== null && !amounts.includes(amount) ? amount / 100 : null,
  );
  const [customAmountFocused, setCustomAmountFocused] = useState(
    customAmount !== null,
  );
  const [customAmountBlurred, setCustomAmountBlurred] = useState(false);
  const [showZeroAmountError, setShowZeroAmountError] = useState(false);
  const [showMinimumAmountError, setShowMinimumAmountError] = useState(false);

  useEffect(() => {
    if (defaultAmount && sendDefaultUpdate) {
      onAmountUpdate(defaultAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultAmountSelection = (amt) => {
    setAmount(amt);
    onAmountUpdate(amt);
    setCustomAmount('');
    setCustomAmountFocused(false);
    setCustomAmountBlurred(false);
    setShowZeroAmountError(false);
    setShowMinimumAmountError(false);
  };

  const isNumber = (input) => {
    if (isNaN(parseInt(input))) return 0;
    else return parseInt(input) * 100;
  };

  useKeyboardEvent('Enter', () => {
    document.activeElement.blur();
  });

  return (
    <div className="cauze-amount-selector-container">
      <div className="amount-selector-buttons-row">
        {amounts.slice(0, 2).map((amt) => (
          <CauzeButton
            blackBorderThin
            large
            key={`${amt}`}
            className={classnames('amount-option font-bold text-20', {
              '!bg-black !text-white': amount === amt,
            })}
            onClick={(ev) => {
              ev.preventDefault();
              defaultAmountSelection(amt);
            }}
            baseClass="cauze-button-donate"
          >
            <Currency amount={amt} />
          </CauzeButton>
        ))}
      </div>
      <div className="amount-selector-buttons-row">
        {amounts.slice(2, 4).map((amt) => (
          <CauzeButton
            blackBorderThin
            large
            key={`${amt}`}
            className={classnames('amount-option text-20', {
              'bg-black': amount === amt,
            })}
            onClick={(ev) => {
              ev.preventDefault();
              defaultAmountSelection(amt);
            }}
          >
            <Currency amount={amt} className="font-bold" />
          </CauzeButton>
        ))}
      </div>
      <CauzeButton
        blackBorderThin
        large
        onClick={(ev) => {
          ev.preventDefault();
          setCustomAmountFocused(true);
          setCustomAmountBlurred(false);
          setAmount(0);
          document.getElementById('customInput').focus();
        }}
        className={classnames('amount-custom', {
          'is-active': customAmountFocused,
          'is-blurred': customAmountBlurred,
        })}
        baseClass="py-2"
      >
        {!customAmountFocused && Boolean(customAmount) && (
          <div
            style={{
              marginTop: '8px',
            }}
            className="font-bold text-16"
          >
            $
          </div>
        )}
        <input
          id="customInput"
          autoFocus={customAmountFocused}
          autoComplete="off"
          onFocus={(ev) => {
            ev.preventDefault();
            onAmountUpdate(customAmount * 100);
            setShowZeroAmountError(false);
            setShowMinimumAmountError(false);
            setCustomAmountFocused(true);
            setAmount(0);
          }}
          onBlur={(ev) => {
            ev.preventDefault();
            if (customAmount && customAmount !== '') {
              setCustomAmountBlurred(true);
            }

            if (customAmount === 0 || !customAmount || customAmount === null) {
              setShowZeroAmountError(true);
            } else if (minimumAmount && minimumAmount > customAmount * 100) {
              // don't show both
              setShowMinimumAmountError(true);
            }

            setCustomAmountFocused(false);
          }}
          inputMode="numeric"
          className="pt-2"
          placeholder="$ custom amount"
          type="text"
          value={customAmount === null ? '' : customAmount}
          onChange={(ev) => {
            ev.preventDefault();
            if (ev.target.value.toString().length < 10) {
              setCustomAmount(
                isNaN(parseInt(ev.target.value))
                  ? ''
                  : parseInt(ev.target.value),
              );
            }

            setAmount(0);
            onAmountUpdate(isNumber(ev.target.value));
          }}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
            }
          }}
        />
      </CauzeButton>
      {showZeroAmountError && (
        <ErrorCard message="Please enter a positive donation amount" />
      )}

      {minimumAmount && showMinimumAmountError && (
        <ErrorCard
          message={`Please enter an amount of $${minimumAmount / 100} or more`}
        />
      )}
    </div>
  );
};

export default CauzeAmountSelector;
