import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';

import { getAppLink } from 'util/userAgent';

import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import Button from 'components/Button/Button';
import Currency from 'components/Currency/Currency';

import logo from 'assets/images/brand/cauze-logotype-accent.svg';
import desktop from 'assets/images/illustrations/find-nonprofit.png';
import mobile from 'assets/images/illustrations/add-to-account.png';
import useDashboardStore from 'stores/DashboardStore';

const GiftLandingView = ({ donationLandingStore, uiStore, location }) => {
  const { setProps } = useDashboardStore();
  const [noToken, setNoToken] = useState(false);
  useEffect(() => {
    var urlParams = new URLSearchParams(location.search);
    if (!urlParams.has('token')) {
      setNoToken(true);
    }
  }, [location]);

  let content = <></>;

  if (noToken) {
    content = (
      <div className="gift-landing flex-column">
        <img src={logo} className="app-logo header-margin" alt="Cauze" />
        <div className="flex-column flex-align-center">
          bad or illegal token
        </div>
      </div>
    );
  }

  if (!noToken) {
    content = (
      <div className="gift-landing flex-column">
        <img src={logo} className="app-logo header-margin" alt="Cauze" />
        {donationLandingStore.loadingGiftData ? (
          <CauzeSpinner fullscreen />
        ) : (
          <div className="flex-column flex-align-center">
            <div className="title">Welcome to Cauze 👋</div>
            {donationLandingStore.giftRedeemed ? (
              <p className="body-text text-align-center">
                This gift has already been redeemed.
              </p>
            ) : (
              <p className="body-text text-align-center">
                Please select how would you like to proceed <br /> with your{' '}
                <Currency
                  amount={uiStore.savedUiState.userToken?.gift.amount ?? 0}
                />{' '}
                Gift Card.
              </p>
            )}
            {!donationLandingStore.giftRedeemed && (
              <div className="columns gift-select-columns">
                <a href="/donate" className="column donate-now">
                  <div className="flex-column flex-expand">
                    <img className="gift-select-image" src={desktop} />
                    <div className="flex-row flex-align-center">
                      <div className="flex-column flex-expand">
                        <div className="title">Donate Now</div>
                        <p>Select from list of 1M+ nonprofits</p>
                      </div>
                      <Button className="landing-button">Select Charity</Button>
                    </div>
                  </div>
                </a>
                <a href={getAppLink()} className="column add-to-account">
                  <div className="flex-column flex-expand">
                    <img className="gift-select-image" src={mobile} />
                    <div className="flex-row flex-align-center">
                      <div className="flex-column flex-expand">
                        <div className="title">Add to Cauze Account</div>
                        <p>
                          Don’t have one? <br />
                          Create one now
                        </p>
                      </div>
                      <Button className="landing-button">Add to Account</Button>
                    </div>
                  </div>
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  useEffect(() => {
    setProps({
      noSidebar: false,
      navbarProps: {
        hideLoginPrompt: true,
      },
    });
  }, []);

  return content;
};

export default inject(
  'donationLandingStore',
  'uiStore',
)(observer(GiftLandingView));
